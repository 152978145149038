import moment from "moment";

function GetMoonPhaseByDay(day) {
    const hit = MoonPhases.filter(m => m.time.isSame(day, 'day'));
    if (hit.length > 0) {
        return hit[0].phase;
    } else {
        return undefined;
    }
}

function createMomentFromDateTimeData(y, m, d, h, mi, s) {
    return moment([y, m - 1, d, h, mi, s]); // moment month is zero based
}

const MoonPhases = [
    { time: createMomentFromDateTimeData(2015, 6, 09, 17, 43, 00), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2015, 6, 16, 14, 5, 19), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2015, 6, 24, 11, 2, 31), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2015, 7, 2, 2, 19, 34), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2015, 7, 8, 20, 23, 56), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2015, 7, 16, 1, 24, 19), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2015, 7, 24, 4, 3, 59), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2015, 7, 31, 10, 42, 53), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2015, 8, 7, 2, 2, 41), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2015, 8, 14, 14, 53, 22), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2015, 8, 22, 19, 31, 0), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2015, 8, 29, 18, 35, 11), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2015, 9, 5, 9, 54, 2), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2015, 9, 13, 6, 41, 15), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2015, 9, 21, 8, 59, 4), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2015, 9, 28, 2, 50, 30), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2015, 10, 4, 21, 6, 3), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2015, 10, 13, 0, 5, 43), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2015, 10, 20, 20, 31, 20), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2015, 10, 27, 12, 5, 7), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2015, 11, 3, 12, 23, 51), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2015, 11, 11, 17, 47, 9), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2015, 11, 19, 6, 27, 21), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2015, 11, 25, 22, 44, 15), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2015, 12, 3, 7, 40, 21), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2015, 12, 11, 10, 29, 25), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2015, 12, 18, 15, 14, 19), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2015, 12, 25, 11, 11, 28), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2016, 1, 2, 5, 30, 27), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2016, 1, 10, 1, 30, 32), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2016, 1, 16, 23, 26, 22), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2016, 1, 24, 1, 45, 44), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2016, 2, 1, 3, 27, 50), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2016, 2, 8, 14, 38, 55), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2016, 2, 15, 7, 46, 29), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2016, 2, 22, 18, 19, 51), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2016, 3, 1, 23, 10, 41), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2016, 3, 9, 1, 54, 29), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2016, 3, 15, 17, 2, 54), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2016, 3, 23, 12, 0, 50), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2016, 3, 31, 15, 16, 50), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2016, 4, 7, 11, 23, 40), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2016, 4, 14, 3, 59, 19), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2016, 4, 22, 5, 23, 36), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2016, 4, 30, 3, 28, 42), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2016, 5, 6, 19, 29, 30), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2016, 5, 13, 17, 2, 6), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2016, 5, 21, 21, 14, 25), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2016, 5, 29, 12, 11, 58), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2016, 6, 5, 2, 59, 34), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2016, 6, 12, 8, 9, 47), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2016, 6, 20, 11, 2, 18), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2016, 6, 27, 18, 18, 38), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2016, 7, 4, 11, 0, 59), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2016, 7, 12, 0, 51, 48), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2016, 7, 19, 22, 56, 33), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2016, 7, 26, 22, 59, 40), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2016, 8, 2, 20, 44, 31), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2016, 8, 10, 18, 20, 50), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2016, 8, 18, 9, 26, 33), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2016, 8, 25, 3, 40, 49), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2016, 9, 1, 9, 3, 5), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2016, 9, 9, 11, 48, 52), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2016, 9, 16, 19, 5, 5), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2016, 9, 23, 9, 56, 2), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2016, 10, 1, 0, 11, 22), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2016, 10, 9, 4, 32, 59), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2016, 10, 16, 4, 23, 6), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2016, 10, 22, 19, 13, 45), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2016, 10, 30, 17, 38, 11), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2016, 11, 7, 19, 51, 6), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2016, 11, 14, 13, 52, 4), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2016, 11, 21, 8, 33, 11), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2016, 11, 29, 12, 18, 14), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2016, 12, 7, 9, 3, 1), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2016, 12, 14, 0, 5, 33), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2016, 12, 21, 1, 55, 44), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2016, 12, 29, 6, 53, 11), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2017, 1, 5, 19, 47, 0), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2017, 1, 12, 11, 33, 58), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2017, 1, 19, 22, 13, 27), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2017, 1, 28, 0, 7, 1), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2017, 2, 4, 4, 18, 53), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2017, 2, 11, 0, 32, 53), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2017, 2, 18, 19, 33, 8), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2017, 2, 26, 14, 58, 22), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2017, 3, 5, 11, 32, 22), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2017, 3, 12, 14, 53, 47), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2017, 3, 20, 15, 58, 12), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2017, 3, 28, 2, 57, 13), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2017, 4, 3, 18, 39, 25), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2017, 4, 11, 6, 8, 6), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2017, 4, 19, 9, 56, 44), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2017, 4, 26, 12, 16, 8), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2017, 5, 3, 2, 46, 50), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2017, 5, 10, 21, 42, 29), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2017, 5, 19, 0, 32, 47), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2017, 5, 25, 19, 44, 27), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2017, 6, 1, 12, 42, 5), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2017, 6, 9, 13, 9, 34), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2017, 6, 17, 11, 32, 42), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2017, 6, 24, 2, 30, 41), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2017, 7, 1, 0, 51, 5), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2017, 7, 9, 4, 6, 33), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2017, 7, 16, 19, 25, 38), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2017, 7, 23, 9, 45, 33), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2017, 7, 30, 15, 23, 5), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2017, 8, 7, 18, 10, 36), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2017, 8, 15, 1, 15, 3), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2017, 8, 21, 18, 30, 9), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2017, 8, 29, 8, 12, 59), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2017, 9, 6, 7, 2, 47), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2017, 9, 13, 6, 25, 0), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2017, 9, 20, 5, 29, 51), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2017, 9, 28, 2, 53, 30), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2017, 10, 5, 18, 40, 7), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2017, 10, 12, 12, 25, 25), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2017, 10, 19, 19, 12, 3), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2017, 10, 27, 22, 22, 4), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2017, 11, 4, 5, 22, 55), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2017, 11, 10, 20, 36, 23), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2017, 11, 18, 11, 42, 8), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2017, 11, 26, 17, 2, 56), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2017, 12, 3, 15, 46, 59), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2017, 12, 10, 7, 51, 21), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2017, 12, 18, 6, 30, 25), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2017, 12, 26, 9, 20, 8), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2018, 1, 2, 2, 24, 5), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2018, 1, 8, 22, 25, 16), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2018, 1, 17, 2, 17, 14), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2018, 1, 24, 22, 20, 23), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2018, 1, 31, 13, 26, 44), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2018, 2, 7, 15, 53, 56), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2018, 2, 15, 21, 5, 12), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2018, 2, 23, 8, 9, 9), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2018, 3, 2, 0, 51, 20), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2018, 3, 9, 11, 19, 47), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2018, 3, 17, 13, 11, 34), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2018, 3, 24, 15, 35, 10), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2018, 3, 31, 12, 36, 51), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2018, 4, 8, 7, 17, 31), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2018, 4, 16, 1, 57, 7), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2018, 4, 22, 21, 45, 34), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2018, 4, 30, 0, 58, 11), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2018, 5, 8, 2, 8, 35), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2018, 5, 15, 11, 47, 46), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2018, 5, 22, 3, 49, 9), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2018, 5, 29, 14, 19, 32), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2018, 6, 6, 18, 31, 41), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2018, 6, 13, 19, 43, 13), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2018, 6, 20, 10, 50, 51), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2018, 6, 28, 4, 52, 57), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2018, 7, 6, 7, 50, 41), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2018, 7, 13, 2, 47, 51), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2018, 7, 19, 19, 52, 13), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2018, 7, 27, 20, 20, 19), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2018, 8, 4, 18, 17, 58), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2018, 8, 11, 9, 57, 43), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2018, 8, 18, 7, 48, 30), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2018, 8, 26, 11, 56, 9), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2018, 9, 3, 2, 37, 23), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2018, 9, 9, 18, 1, 27), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2018, 9, 16, 23, 14, 57), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2018, 9, 25, 2, 52, 24), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2018, 10, 2, 9, 45, 24), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2018, 10, 9, 3, 46, 50), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2018, 10, 16, 18, 1, 39), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2018, 10, 24, 16, 45, 11), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2018, 10, 31, 16, 40, 18), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2018, 11, 7, 16, 2, 3), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2018, 11, 15, 14, 54, 11), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2018, 11, 23, 5, 39, 12), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2018, 11, 30, 0, 18, 49), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2018, 12, 7, 7, 20, 21), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2018, 12, 15, 11, 49, 15), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2018, 12, 22, 17, 48, 35), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2018, 12, 29, 9, 34, 18), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2019, 1, 6, 1, 28, 11), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2019, 1, 14, 6, 45, 31), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2019, 1, 21, 5, 16, 4), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2019, 1, 27, 21, 10, 20), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2019, 2, 4, 21, 3, 34), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2019, 2, 12, 22, 26, 14), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2019, 2, 19, 15, 53, 34), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2019, 2, 26, 11, 27, 42), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2019, 3, 6, 16, 3, 58), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2019, 3, 14, 10, 27, 4), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2019, 3, 21, 1, 42, 51), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2019, 3, 28, 4, 9, 40), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2019, 4, 5, 8, 50, 28), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2019, 4, 12, 19, 5, 51), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2019, 4, 19, 11, 12, 9), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2019, 4, 26, 22, 18, 16), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2019, 5, 4, 22, 45, 28), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2019, 5, 12, 1, 12, 12), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2019, 5, 18, 21, 11, 19), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2019, 5, 26, 16, 33, 33), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2019, 6, 3, 10, 1, 55), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2019, 6, 10, 5, 59, 16), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2019, 6, 17, 8, 30, 38), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2019, 6, 25, 9, 46, 22), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2019, 7, 2, 19, 16, 10), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2019, 7, 9, 10, 54, 49), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2019, 7, 16, 21, 38, 11), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2019, 7, 25, 1, 17, 59), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2019, 8, 1, 3, 11, 53), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2019, 8, 7, 17, 30, 55), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2019, 8, 15, 12, 29, 13), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2019, 8, 23, 14, 56, 4), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2019, 8, 30, 10, 37, 7), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2019, 9, 6, 3, 10, 23), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2019, 9, 14, 4, 32, 44), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2019, 9, 22, 2, 40, 52), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2019, 9, 28, 18, 26, 20), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2019, 10, 5, 16, 47, 4), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2019, 10, 13, 21, 7, 51), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2019, 10, 21, 12, 39, 17), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2019, 10, 28, 3, 38, 26), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2019, 11, 4, 10, 23, 4), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2019, 11, 12, 13, 34, 23), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2019, 11, 19, 21, 10, 55), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2019, 11, 26, 15, 5, 34), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2019, 12, 4, 6, 58, 12), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2019, 12, 12, 5, 12, 15), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2019, 12, 19, 4, 57, 4), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2019, 12, 26, 5, 13, 6), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2020, 1, 3, 4, 45, 24), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2020, 1, 10, 19, 21, 17), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2020, 1, 17, 12, 58, 23), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2020, 1, 24, 21, 41, 58), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2020, 2, 2, 1, 41, 39), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2020, 2, 9, 7, 33, 15), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2020, 2, 15, 22, 17, 11), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2020, 2, 23, 15, 31, 59), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2020, 3, 2, 19, 57, 22), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2020, 3, 9, 17, 47, 42), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2020, 3, 16, 9, 34, 10), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2020, 3, 24, 9, 28, 11), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2020, 4, 1, 10, 21, 13), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2020, 4, 8, 2, 35, 3), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2020, 4, 14, 22, 56, 6), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2020, 4, 23, 2, 25, 49), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2020, 4, 30, 20, 38, 19), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2020, 5, 7, 10, 45, 11), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2020, 5, 14, 14, 2, 40), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2020, 5, 22, 17, 38, 49), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2020, 5, 30, 3, 29, 52), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2020, 6, 5, 19, 12, 20), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2020, 6, 13, 6, 23, 38), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2020, 6, 21, 6, 41, 24), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2020, 6, 28, 8, 15, 38), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2020, 7, 5, 4, 44, 22), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2020, 7, 12, 23, 28, 57), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2020, 7, 20, 17, 32, 54), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2020, 7, 27, 12, 32, 31), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2020, 8, 3, 15, 58, 43), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2020, 8, 11, 16, 44, 44), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2020, 8, 19, 2, 41, 37), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2020, 8, 25, 17, 57, 35), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2020, 9, 2, 5, 22, 1), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2020, 9, 10, 9, 25, 41), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2020, 9, 17, 11, 0, 10), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2020, 9, 24, 1, 54, 49), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2020, 10, 1, 21, 5, 13), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2020, 10, 10, 0, 39, 29), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2020, 10, 16, 19, 31, 0), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2020, 10, 23, 13, 22, 53), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2020, 10, 31, 14, 49, 7), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2020, 11, 8, 13, 46, 3), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2020, 11, 15, 5, 7, 9), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2020, 11, 22, 4, 44, 59), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2020, 11, 30, 9, 29, 39), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2020, 12, 8, 0, 36, 34), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2020, 12, 14, 16, 16, 33), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2020, 12, 21, 23, 41, 11), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2020, 12, 30, 3, 28, 11), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2021, 1, 6, 9, 37, 11), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2021, 1, 13, 5, 0, 8), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2021, 1, 20, 21, 1, 34), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2021, 1, 28, 19, 16, 12), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2021, 2, 4, 17, 37, 3), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2021, 2, 11, 19, 5, 38), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2021, 2, 19, 18, 47, 18), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2021, 2, 27, 8, 17, 17), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2021, 3, 6, 1, 30, 10), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2021, 3, 13, 10, 21, 6), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2021, 3, 21, 14, 40, 22), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2021, 3, 28, 18, 48, 7), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2021, 4, 4, 10, 2, 24), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2021, 4, 12, 2, 30, 47), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2021, 4, 20, 6, 58, 53), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2021, 4, 27, 3, 31, 29), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2021, 5, 3, 19, 50, 2), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2021, 5, 11, 18, 59, 44), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2021, 5, 19, 19, 12, 34), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2021, 5, 26, 11, 13, 49), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2021, 6, 2, 7, 24, 20), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2021, 6, 10, 10, 52, 35), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2021, 6, 18, 3, 54, 13), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2021, 6, 24, 18, 39, 38), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2021, 7, 1, 21, 10, 34), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2021, 7, 10, 1, 16, 33), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2021, 7, 17, 10, 10, 35), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2021, 7, 24, 2, 36, 50), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2021, 7, 31, 13, 15, 56), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2021, 8, 8, 13, 50, 4), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2021, 8, 15, 15, 19, 32), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2021, 8, 22, 12, 1, 54), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2021, 8, 30, 7, 13, 10), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2021, 9, 7, 0, 51, 42), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2021, 9, 13, 20, 39, 18), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2021, 9, 20, 23, 54, 38), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2021, 9, 29, 1, 57, 5), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2021, 10, 6, 11, 5, 20), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2021, 10, 13, 3, 25, 5), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2021, 10, 20, 14, 56, 38), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2021, 10, 28, 20, 5, 7), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2021, 11, 4, 21, 14, 33), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2021, 11, 11, 12, 45, 59), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2021, 11, 19, 8, 57, 25), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2021, 11, 27, 12, 27, 37), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2021, 12, 4, 7, 42, 59), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2021, 12, 11, 1, 35, 30), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2021, 12, 19, 4, 35, 28), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2021, 12, 27, 2, 23, 42), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2022, 1, 2, 18, 33, 27), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2022, 1, 9, 18, 11, 13), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2022, 1, 17, 23, 48, 23), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2022, 1, 25, 13, 40, 52), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2022, 2, 1, 5, 45, 58), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2022, 2, 8, 13, 50, 4), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2022, 2, 16, 16, 56, 27), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2022, 2, 23, 22, 32, 24), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2022, 3, 2, 17, 34, 43), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2022, 3, 10, 10, 45, 21), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2022, 3, 18, 7, 17, 30), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2022, 3, 25, 5, 37, 13), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2022, 4, 1, 6, 24, 21), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2022, 4, 9, 6, 47, 34), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2022, 4, 16, 18, 54, 59), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2022, 4, 23, 11, 56, 17), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2022, 4, 30, 20, 28, 2), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2022, 5, 9, 0, 21, 19), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2022, 5, 16, 4, 14, 5), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2022, 5, 22, 18, 43, 1), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2022, 5, 30, 11, 30, 13), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2022, 6, 7, 14, 48, 25), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2022, 6, 14, 11, 51, 41), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2022, 6, 21, 3, 10, 45), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2022, 6, 29, 2, 52, 12), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2022, 7, 7, 2, 14, 4), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2022, 7, 13, 18, 37, 33), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2022, 7, 20, 14, 18, 33), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2022, 7, 28, 17, 54, 57), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2022, 8, 5, 11, 6, 29), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2022, 8, 12, 1, 35, 39), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2022, 8, 19, 4, 36, 2), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2022, 8, 27, 8, 17, 2), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2022, 9, 3, 18, 7, 40), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2022, 9, 10, 9, 58, 58), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2022, 9, 17, 21, 51, 55), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2022, 9, 25, 21, 54, 29), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2022, 10, 3, 0, 13, 59), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2022, 10, 9, 20, 54, 54), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2022, 10, 17, 17, 15, 2), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2022, 10, 25, 10, 48, 38), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2022, 11, 1, 6, 37, 2), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2022, 11, 8, 11, 2, 5), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2022, 11, 16, 13, 26, 59), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2022, 11, 23, 22, 57, 10), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2022, 11, 30, 14, 36, 28), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2022, 12, 8, 4, 8, 7), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2022, 12, 16, 8, 56, 1), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2022, 12, 23, 10, 16, 49), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2022, 12, 30, 1, 20, 28), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2023, 1, 6, 23, 7, 50), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2023, 1, 15, 2, 10, 15), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2023, 1, 21, 20, 53, 11), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2023, 1, 28, 15, 18, 42), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2023, 2, 5, 18, 28, 29), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2023, 2, 13, 16, 0, 40), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2023, 2, 20, 7, 5, 45), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2023, 2, 27, 8, 5, 34), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2023, 3, 7, 12, 40, 16), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2023, 3, 15, 2, 8, 12), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2023, 3, 21, 17, 23, 3), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2023, 3, 29, 2, 32, 18), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2023, 4, 6, 4, 34, 26), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2023, 4, 13, 9, 11, 19), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2023, 4, 20, 4, 12, 27), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2023, 4, 27, 21, 19, 50), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2023, 5, 5, 17, 33, 58), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2023, 5, 12, 14, 28, 11), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2023, 5, 19, 15, 53, 12), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2023, 5, 27, 15, 22, 11), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2023, 6, 4, 3, 41, 39), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2023, 6, 10, 19, 31, 16), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2023, 6, 18, 4, 37, 3), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2023, 6, 26, 7, 49, 37), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2023, 7, 3, 11, 38, 36), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2023, 7, 10, 1, 47, 48), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2023, 7, 17, 18, 31, 44), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2023, 7, 25, 22, 6, 42), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2023, 8, 1, 18, 31, 34), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2023, 8, 8, 10, 28, 21), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2023, 8, 16, 9, 38, 4), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2023, 8, 24, 9, 57, 10), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2023, 8, 31, 1, 35, 31), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2023, 9, 6, 22, 21, 0), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2023, 9, 15, 1, 39, 42), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2023, 9, 22, 19, 31, 43), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2023, 9, 29, 9, 57, 27), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2023, 10, 6, 13, 47, 38), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2023, 10, 14, 17, 55, 4), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2023, 10, 22, 3, 29, 21), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2023, 10, 28, 20, 23, 58), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2023, 11, 5, 8, 36, 43), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2023, 11, 13, 9, 27, 20), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2023, 11, 20, 10, 49, 48), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2023, 11, 27, 9, 16, 14), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2023, 12, 5, 5, 49, 10), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2023, 12, 12, 23, 31, 57), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2023, 12, 19, 18, 39, 9), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2023, 12, 27, 0, 33, 8), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2024, 1, 4, 3, 30, 22), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2024, 1, 11, 11, 57, 19), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2024, 1, 18, 3, 52, 32), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2024, 1, 25, 17, 53, 54), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2024, 2, 2, 23, 17, 54), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2024, 2, 9, 22, 59, 5), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2024, 2, 16, 15, 0, 52), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2024, 2, 24, 12, 30, 20), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2024, 3, 3, 15, 23, 25), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2024, 3, 10, 9, 0, 20), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2024, 3, 17, 4, 10, 38), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2024, 3, 25, 7, 0, 14), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2024, 4, 2, 3, 14, 38), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2024, 4, 8, 18, 20, 46), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2024, 4, 15, 19, 13, 0), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2024, 4, 23, 23, 48, 53), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2024, 5, 1, 11, 27, 9), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2024, 5, 8, 3, 21, 50), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2024, 5, 15, 11, 47, 53), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2024, 5, 23, 13, 53, 3), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2024, 5, 30, 17, 12, 33), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2024, 6, 6, 12, 37, 38), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2024, 6, 14, 5, 18, 20), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2024, 6, 22, 1, 7, 46), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2024, 6, 28, 21, 53, 18), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2024, 7, 5, 22, 57, 17), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2024, 7, 13, 22, 48, 43), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2024, 7, 21, 10, 17, 2), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2024, 7, 28, 2, 51, 28), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2024, 8, 4, 11, 12, 56), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2024, 8, 12, 15, 18, 42), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2024, 8, 19, 18, 25, 41), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2024, 8, 26, 9, 25, 45), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2024, 9, 3, 1, 55, 28), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2024, 9, 11, 6, 5, 34), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2024, 9, 18, 2, 34, 21), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2024, 9, 24, 18, 49, 46), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2024, 10, 2, 18, 49, 11), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2024, 10, 10, 18, 55, 2), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2024, 10, 17, 11, 26, 18), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2024, 10, 24, 8, 2, 58), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2024, 11, 1, 12, 47, 3), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2024, 11, 9, 5, 55, 22), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2024, 11, 15, 21, 28, 26), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2024, 11, 23, 1, 27, 49), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2024, 12, 1, 6, 21, 20), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2024, 12, 8, 15, 26, 31), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2024, 12, 15, 9, 1, 36), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2024, 12, 22, 22, 18, 6), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2024, 12, 30, 22, 26, 42), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2025, 1, 6, 23, 56, 12), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2025, 1, 13, 22, 26, 48), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2025, 1, 21, 20, 30, 42), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2025, 1, 29, 12, 35, 52), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2025, 2, 5, 8, 2, 4), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2025, 2, 12, 13, 53, 17), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2025, 2, 20, 17, 32, 27), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2025, 2, 28, 0, 44, 43), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2025, 3, 6, 16, 31, 32), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2025, 3, 14, 6, 54, 32), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2025, 3, 22, 11, 29, 20), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2025, 3, 29, 10, 57, 44), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2025, 4, 5, 2, 14, 34), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2025, 4, 13, 0, 22, 10), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2025, 4, 21, 1, 35, 26), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2025, 4, 27, 19, 31, 3), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2025, 5, 4, 13, 51, 38), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2025, 5, 12, 16, 55, 50), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2025, 5, 20, 11, 58, 38), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2025, 5, 27, 3, 2, 14), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2025, 6, 3, 3, 40, 50), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2025, 6, 11, 7, 43, 43), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2025, 6, 18, 19, 18, 59), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2025, 6, 25, 10, 31, 29), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2025, 7, 2, 19, 30, 4), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2025, 7, 10, 20, 36, 39), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2025, 7, 18, 0, 37, 33), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2025, 7, 24, 19, 11, 4), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2025, 8, 1, 12, 41, 12), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2025, 8, 9, 7, 54, 56), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2025, 8, 16, 5, 12, 7), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2025, 8, 23, 6, 6, 25), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2025, 8, 31, 6, 25, 5), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2025, 9, 7, 18, 8, 46), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2025, 9, 14, 10, 32, 50), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2025, 9, 21, 19, 54, 1), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2025, 9, 29, 23, 53, 42), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2025, 10, 7, 3, 47, 30), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2025, 10, 13, 18, 12, 34), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2025, 10, 21, 12, 25, 5), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2025, 10, 29, 16, 20, 42), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2025, 11, 5, 13, 19, 12), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2025, 11, 12, 5, 28, 1), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2025, 11, 20, 6, 47, 10), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2025, 11, 28, 6, 58, 41), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2025, 12, 4, 23, 13, 58), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2025, 12, 11, 20, 51, 35), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2025, 12, 20, 1, 43, 14), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2025, 12, 27, 19, 9, 45), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2026, 1, 3, 10, 2, 47), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2026, 1, 10, 15, 48, 18), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2026, 1, 18, 19, 51, 51), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2026, 1, 26, 4, 47, 17), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2026, 2, 1, 22, 9, 7), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2026, 2, 9, 12, 43, 0), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2026, 2, 17, 12, 1, 1), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2026, 2, 24, 12, 27, 30), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2026, 3, 3, 11, 37, 46), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2026, 3, 11, 9, 38, 23), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2026, 3, 19, 1, 23, 21), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2026, 3, 25, 19, 17, 35), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2026, 4, 2, 2, 11, 51), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2026, 4, 10, 4, 51, 30), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2026, 4, 17, 11, 51, 41), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2026, 4, 24, 2, 31, 37), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2026, 5, 1, 17, 23, 3), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2026, 5, 9, 21, 10, 19), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2026, 5, 16, 20, 0, 55), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2026, 5, 23, 11, 10, 49), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2026, 5, 31, 8, 45, 4), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2026, 6, 8, 10, 0, 23), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2026, 6, 15, 2, 54, 2), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2026, 6, 21, 21, 55, 17), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2026, 6, 29, 23, 56, 32), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2026, 7, 7, 19, 28, 52), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2026, 7, 14, 9, 43, 28), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2026, 7, 21, 11, 5, 29), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2026, 7, 29, 14, 35, 34), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2026, 8, 6, 2, 21, 21), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2026, 8, 12, 17, 36, 36), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2026, 8, 20, 2, 46, 13), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2026, 8, 28, 4, 18, 23), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2026, 9, 4, 7, 51, 6), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2026, 9, 11, 3, 26, 52), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2026, 9, 18, 20, 43, 39), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2026, 9, 26, 16, 48, 55), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2026, 10, 3, 13, 24, 55), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2026, 10, 10, 15, 49, 58), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2026, 10, 18, 16, 12, 33), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2026, 10, 26, 4, 11, 41), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2026, 11, 1, 20, 28, 18), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2026, 11, 9, 7, 2, 0), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2026, 11, 17, 11, 47, 42), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2026, 11, 24, 14, 53, 26), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2026, 12, 1, 6, 8, 32), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2026, 12, 9, 0, 51, 43), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2026, 12, 17, 5, 42, 33), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2026, 12, 24, 1, 28, 6), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2026, 12, 30, 18, 59, 23), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2027, 1, 7, 20, 24, 15), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2027, 1, 15, 20, 34, 24), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2027, 1, 22, 12, 17, 15), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2027, 1, 29, 10, 55, 21), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2027, 2, 6, 15, 55, 59), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2027, 2, 14, 7, 58, 22), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2027, 2, 20, 23, 23, 31), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2027, 2, 28, 5, 16, 22), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2027, 3, 8, 9, 29, 21), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2027, 3, 15, 16, 25, 4), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2027, 3, 22, 10, 43, 40), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2027, 3, 30, 0, 53, 48), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2027, 4, 6, 23, 51, 2), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2027, 4, 13, 22, 56, 30), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2027, 4, 20, 22, 27, 1), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2027, 4, 28, 20, 17, 43), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2027, 5, 6, 10, 58, 29), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2027, 5, 13, 4, 43, 44), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2027, 5, 20, 10, 58, 52), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2027, 5, 28, 13, 57, 47), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2027, 6, 4, 19, 40, 12), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2027, 6, 11, 10, 55, 59), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2027, 6, 19, 0, 44, 11), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2027, 6, 27, 4, 54, 12), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2027, 7, 4, 3, 1, 56), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2027, 7, 10, 18, 38, 52), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2027, 7, 18, 15, 44, 45), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2027, 7, 26, 16, 54, 46), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2027, 8, 2, 10, 5, 5), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2027, 8, 9, 4, 54, 2), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2027, 8, 17, 7, 28, 32), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2027, 8, 25, 2, 27, 12), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2027, 8, 31, 17, 41, 2), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2027, 9, 7, 18, 31, 10), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2027, 9, 15, 23, 3, 23), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2027, 9, 23, 10, 20, 19), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2027, 9, 30, 2, 35, 56), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2027, 10, 7, 11, 47, 15), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2027, 10, 15, 13, 46, 52), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2027, 10, 22, 17, 29, 0), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2027, 10, 29, 13, 36, 25), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2027, 11, 6, 7, 59, 52), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2027, 11, 14, 3, 25, 47), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2027, 11, 21, 0, 48, 4), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2027, 11, 28, 3, 24, 17), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2027, 12, 6, 5, 22, 0), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2027, 12, 13, 16, 8, 39), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2027, 12, 20, 9, 10, 46), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2027, 12, 27, 20, 12, 11), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2028, 1, 5, 1, 40, 20), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2028, 1, 12, 4, 2, 56), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2028, 1, 18, 19, 25, 51), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2028, 1, 26, 15, 12, 22), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2028, 2, 3, 19, 10, 23), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2028, 2, 10, 15, 3, 37), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2028, 2, 17, 8, 7, 51), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2028, 2, 25, 10, 37, 16), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2028, 3, 4, 9, 2, 16), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2028, 3, 11, 1, 5, 56), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2028, 3, 17, 23, 22, 42), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2028, 3, 26, 4, 31, 10), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2028, 4, 2, 19, 15, 20), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2028, 4, 9, 10, 26, 28), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2028, 4, 16, 16, 36, 46), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2028, 4, 24, 19, 46, 47), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2028, 5, 2, 2, 25, 35), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2028, 5, 8, 19, 48, 46), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2028, 5, 16, 10, 42, 56), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2028, 5, 24, 8, 16, 8), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2028, 5, 31, 7, 36, 27), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2028, 6, 7, 6, 8, 38), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2028, 6, 15, 4, 27, 15), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2028, 6, 22, 18, 27, 22), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2028, 6, 29, 12, 10, 27), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2028, 7, 6, 18, 10, 38), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2028, 7, 14, 20, 56, 25), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2028, 7, 22, 3, 1, 32), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2028, 7, 28, 17, 39, 59), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2028, 8, 5, 8, 9, 39), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2028, 8, 13, 11, 45, 9), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2028, 8, 20, 10, 43, 41), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2028, 8, 27, 1, 35, 43), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2028, 9, 3, 23, 47, 25), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2028, 9, 12, 0, 45, 36), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2028, 9, 18, 18, 23, 35), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2028, 9, 25, 13, 9, 52), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2028, 10, 3, 16, 24, 50), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2028, 10, 11, 11, 56, 42), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2028, 10, 18, 2, 56, 39), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2028, 10, 25, 4, 53, 1), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2028, 11, 2, 9, 17, 12), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2028, 11, 9, 21, 25, 43), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2028, 11, 16, 13, 17, 52), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2028, 11, 24, 0, 14, 30), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2028, 12, 2, 1, 40, 4), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2028, 12, 9, 5, 38, 46), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2028, 12, 16, 2, 6, 9), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2028, 12, 23, 21, 44, 48), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2028, 12, 31, 16, 48, 21), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2029, 1, 7, 13, 26, 13), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2029, 1, 14, 17, 24, 21), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2029, 1, 22, 19, 23, 5), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2029, 1, 30, 6, 3, 27), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2029, 2, 5, 21, 52, 3), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2029, 2, 13, 10, 31, 21), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2029, 2, 21, 15, 9, 45), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2029, 2, 28, 17, 10, 5), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2029, 3, 7, 7, 51, 26), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2029, 3, 15, 4, 19, 5), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2029, 3, 23, 7, 32, 58), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2029, 3, 30, 2, 26, 14), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2029, 4, 5, 19, 51, 25), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2029, 4, 13, 21, 40, 0), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2029, 4, 21, 19, 49, 58), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2029, 4, 28, 10, 36, 37), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2029, 5, 5, 9, 47, 55), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2029, 5, 13, 13, 41, 57), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2029, 5, 21, 4, 15, 58), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2029, 5, 27, 18, 37, 19), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2029, 6, 4, 1, 18, 40), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2029, 6, 12, 3, 50, 21), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2029, 6, 19, 9, 53, 56), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2029, 6, 26, 3, 22, 9), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2029, 7, 3, 17, 57, 19), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2029, 7, 11, 15, 50, 52), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2029, 7, 18, 14, 14, 8), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2029, 7, 25, 13, 35, 34), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2029, 8, 2, 11, 15, 12), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2029, 8, 10, 1, 55, 36), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2029, 8, 16, 18, 55, 8), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2029, 8, 24, 1, 51, 2), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2029, 9, 1, 4, 32, 53), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2029, 9, 8, 10, 44, 12), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2029, 9, 15, 1, 29, 6), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2029, 9, 22, 16, 29, 7), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2029, 9, 30, 20, 56, 40), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2029, 10, 7, 19, 14, 22), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2029, 10, 14, 11, 8, 42), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2029, 10, 22, 9, 27, 23), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2029, 10, 30, 11, 32, 6), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2029, 11, 6, 4, 23, 56), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2029, 11, 13, 0, 35, 6), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2029, 11, 21, 4, 2, 47), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2029, 11, 28, 23, 47, 38), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2029, 12, 5, 14, 51, 57), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2029, 12, 12, 17, 49, 19), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2029, 12, 20, 22, 46, 20), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2029, 12, 28, 9, 48, 55), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2030, 1, 4, 2, 49, 22), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2030, 1, 11, 14, 5, 53), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2030, 1, 19, 15, 54, 11), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2030, 1, 26, 18, 14, 15), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2030, 2, 2, 16, 7, 20), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2030, 2, 10, 11, 49, 15), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2030, 2, 18, 6, 19, 37), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2030, 2, 25, 1, 57, 32), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2030, 3, 4, 6, 34, 29), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2030, 3, 12, 8, 47, 25), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2030, 3, 19, 17, 56, 18), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2030, 3, 26, 9, 51, 12), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2030, 4, 2, 22, 2, 21), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2030, 4, 11, 2, 56, 42), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2030, 4, 18, 3, 19, 50), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2030, 4, 24, 18, 38, 47), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2030, 5, 2, 14, 11, 57), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2030, 5, 10, 17, 11, 17), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2030, 5, 17, 11, 18, 57), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2030, 5, 24, 4, 57, 15), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2030, 6, 1, 6, 21, 8), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2030, 6, 9, 3, 35, 32), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2030, 6, 15, 18, 40, 48), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2030, 6, 22, 17, 19, 29), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2030, 6, 30, 21, 34, 16), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2030, 7, 8, 11, 1, 40), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2030, 7, 15, 2, 11, 46), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2030, 7, 22, 8, 7, 22), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2030, 7, 30, 11, 10, 47), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2030, 8, 6, 16, 42, 30), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2030, 8, 13, 10, 44, 11), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2030, 8, 21, 1, 15, 14), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2030, 8, 28, 23, 7, 9), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2030, 9, 4, 21, 55, 23), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2030, 9, 11, 21, 17, 43), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2030, 9, 19, 19, 56, 19), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2030, 9, 27, 9, 54, 28), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2030, 10, 4, 3, 55, 58), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2030, 10, 11, 10, 46, 38), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2030, 10, 19, 14, 50, 10), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2030, 10, 26, 20, 16, 47), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2030, 11, 2, 11, 55, 48), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2030, 11, 10, 3, 30, 6), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2030, 11, 18, 8, 32, 8), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2030, 11, 25, 6, 46, 17), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2030, 12, 1, 22, 56, 40), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2030, 12, 9, 22, 40, 16), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2030, 12, 18, 0, 1, 0), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2030, 12, 24, 17, 32, 1), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2030, 12, 31, 13, 35, 57), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2031, 1, 8, 18, 25, 38), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2031, 1, 16, 12, 47, 3), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2031, 1, 23, 4, 30, 48), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2031, 1, 30, 7, 42, 54), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2031, 2, 7, 12, 46, 0), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2031, 2, 14, 22, 49, 37), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2031, 2, 21, 15, 48, 39), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2031, 3, 1, 4, 2, 2), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2031, 3, 9, 4, 29, 26), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2031, 3, 16, 6, 35, 36), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2031, 3, 23, 3, 48, 52), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2031, 3, 31, 0, 32, 3), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2031, 4, 7, 17, 21, 6), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2031, 4, 14, 12, 57, 39), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2031, 4, 21, 16, 56, 52), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2031, 4, 29, 19, 19, 14), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2031, 5, 7, 3, 39, 39), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2031, 5, 13, 19, 6, 43), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2031, 5, 21, 7, 16, 59), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2031, 5, 29, 11, 19, 21), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2031, 6, 5, 11, 58, 19), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2031, 6, 12, 2, 20, 24), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2031, 6, 19, 22, 24, 28), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2031, 6, 28, 0, 18, 47), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2031, 7, 4, 19, 1, 8), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2031, 7, 11, 11, 49, 36), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2031, 7, 19, 13, 40, 1), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2031, 7, 27, 10, 34, 33), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2031, 8, 3, 1, 45, 23), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2031, 8, 10, 0, 23, 26), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2031, 8, 18, 4, 32, 4), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2031, 8, 25, 18, 39, 30), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2031, 9, 1, 9, 20, 17), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2031, 9, 8, 16, 14, 11), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2031, 9, 16, 18, 46, 39), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2031, 9, 24, 1, 19, 33), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2031, 9, 30, 18, 57, 38), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2031, 10, 8, 10, 50, 1), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2031, 10, 16, 8, 20, 30), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2031, 10, 23, 7, 36, 14), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2031, 10, 30, 7, 32, 28), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2031, 11, 7, 7, 2, 2), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2031, 11, 14, 21, 9, 24), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2031, 11, 21, 14, 44, 41), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2031, 11, 28, 23, 18, 15), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2031, 12, 7, 3, 19, 28), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2031, 12, 14, 9, 5, 35), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2031, 12, 21, 0, 0, 18), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2031, 12, 28, 17, 32, 48), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2032, 1, 5, 22, 3, 59), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2032, 1, 12, 20, 6, 29), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2032, 1, 19, 12, 14, 6), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2032, 1, 27, 12, 52, 16), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2032, 2, 4, 13, 48, 44), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2032, 2, 11, 6, 24, 2), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2032, 2, 18, 3, 28, 46), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2032, 2, 26, 7, 43, 0), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2032, 3, 5, 1, 46, 46), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2032, 3, 11, 16, 24, 26), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2032, 3, 18, 20, 56, 27), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2032, 3, 27, 0, 46, 4), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2032, 4, 3, 10, 10, 1), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2032, 4, 10, 2, 39, 16), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2032, 4, 17, 15, 24, 9), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2032, 4, 25, 15, 9, 27), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2032, 5, 2, 16, 1, 30), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2032, 5, 9, 13, 35, 30), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2032, 5, 17, 9, 43, 9), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2032, 5, 25, 2, 36, 59), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2032, 5, 31, 20, 50, 57), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2032, 6, 8, 1, 31, 52), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2032, 6, 16, 2, 59, 38), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2032, 6, 23, 11, 32, 18), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2032, 6, 30, 2, 11, 34), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2032, 7, 7, 14, 41, 16), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2032, 7, 15, 18, 31, 48), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2032, 7, 22, 18, 51, 19), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2032, 7, 29, 9, 25, 7), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2032, 8, 6, 5, 11, 17), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2032, 8, 14, 7, 50, 45), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2032, 8, 21, 1, 46, 36), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2032, 8, 27, 19, 33, 10), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2032, 9, 4, 20, 56, 22), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2032, 9, 12, 18, 48, 58), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2032, 9, 19, 9, 30, 1), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2032, 9, 26, 9, 12, 13), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2032, 10, 4, 13, 26, 7), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2032, 10, 12, 3, 47, 21), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2032, 10, 18, 18, 57, 54), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2032, 10, 26, 2, 28, 37), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2032, 11, 3, 5, 44, 43), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2032, 11, 10, 11, 33, 1), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2032, 11, 17, 6, 41, 49), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2032, 11, 24, 22, 47, 33), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2032, 12, 2, 20, 52, 38), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2032, 12, 9, 19, 8, 17), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2032, 12, 16, 20, 48, 46), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2032, 12, 24, 20, 38, 59), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2033, 1, 1, 10, 16, 47), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2033, 1, 8, 3, 34, 9), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2033, 1, 15, 13, 6, 52), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2033, 1, 23, 17, 45, 31), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2033, 1, 30, 21, 59, 37), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2033, 2, 6, 13, 33, 59), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2033, 2, 14, 7, 3, 57), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2033, 2, 22, 11, 52, 52), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2033, 3, 1, 8, 23, 17), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2033, 3, 8, 1, 26, 51), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2033, 3, 16, 1, 37, 10), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2033, 3, 24, 1, 49, 33), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2033, 3, 30, 17, 51, 23), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2033, 4, 6, 15, 13, 40), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2033, 4, 14, 19, 17, 8), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2033, 4, 22, 11, 42, 1), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2033, 4, 29, 2, 45, 56), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2033, 5, 6, 6, 45, 1), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2033, 5, 14, 10, 42, 28), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2033, 5, 21, 18, 28, 42), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2033, 5, 28, 11, 36, 17), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2033, 6, 4, 23, 38, 38), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2033, 6, 12, 23, 18, 57), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2033, 6, 19, 23, 29, 16), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2033, 6, 26, 21, 6, 47), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2033, 7, 4, 17, 11, 51), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2033, 7, 12, 9, 28, 18), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2033, 7, 19, 4, 6, 58), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2033, 7, 26, 8, 12, 17), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2033, 8, 3, 10, 25, 28), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2033, 8, 10, 18, 7, 26), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2033, 8, 17, 9, 42, 41), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2033, 8, 24, 21, 39, 31), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2033, 9, 2, 2, 23, 25), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2033, 9, 9, 2, 20, 18), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2033, 9, 15, 17, 33, 18), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2033, 9, 23, 13, 39, 31), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2033, 10, 1, 16, 32, 30), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2033, 10, 8, 10, 57, 53), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2033, 10, 15, 4, 47, 13), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2033, 10, 23, 7, 28, 10), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2033, 10, 31, 4, 46, 5), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2033, 11, 6, 20, 31, 51), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2033, 11, 13, 20, 8, 32), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2033, 11, 22, 1, 38, 52), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2033, 11, 29, 15, 14, 57), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2033, 12, 6, 7, 21, 50), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2033, 12, 13, 15, 27, 43), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2033, 12, 21, 18, 46, 13), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2033, 12, 29, 0, 19, 51), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2034, 1, 4, 19, 46, 51), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2034, 1, 12, 13, 16, 49), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2034, 1, 20, 10, 1, 17), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2034, 1, 27, 8, 31, 44), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2034, 2, 3, 10, 4, 16), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2034, 2, 11, 11, 8, 45), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2034, 2, 18, 23, 9, 59), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2034, 2, 25, 16, 33, 56), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2034, 3, 5, 2, 9, 53), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2034, 3, 13, 6, 44, 12), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2034, 3, 20, 10, 14, 18), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2034, 3, 27, 1, 18, 13), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2034, 4, 3, 19, 18, 37), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2034, 4, 11, 22, 44, 52), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2034, 4, 18, 19, 25, 38), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2034, 4, 25, 11, 34, 25), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2034, 5, 3, 12, 15, 26), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2034, 5, 11, 10, 55, 56), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2034, 5, 18, 3, 12, 19), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2034, 5, 24, 23, 57, 17), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2034, 6, 2, 3, 53, 44), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2034, 6, 9, 19, 43, 40), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2034, 6, 16, 10, 25, 39), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2034, 6, 23, 14, 34, 53), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2034, 7, 1, 17, 44, 15), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2034, 7, 9, 1, 58, 47), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2034, 7, 15, 18, 14, 58), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2034, 7, 23, 7, 4, 46), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2034, 7, 31, 5, 54, 12), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2034, 8, 7, 6, 50, 2), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2034, 8, 14, 3, 52, 45), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2034, 8, 22, 0, 43, 5), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2034, 8, 29, 16, 49, 0), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2034, 9, 5, 11, 41, 7), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2034, 9, 12, 16, 13, 31), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2034, 9, 20, 18, 39, 13), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2034, 9, 28, 2, 56, 33), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2034, 10, 4, 18, 4, 25), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2034, 10, 12, 7, 32, 23), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2034, 10, 20, 12, 2, 42), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2034, 10, 27, 12, 42, 14), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2034, 11, 3, 3, 27, 1), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2034, 11, 11, 1, 15, 58), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2034, 11, 19, 4, 1, 11), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2034, 11, 25, 22, 31, 54), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2034, 12, 2, 16, 46, 8), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2034, 12, 10, 20, 14, 9), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2034, 12, 18, 17, 44, 41), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2034, 12, 25, 8, 54, 13), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2035, 1, 1, 10, 0, 45), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2035, 1, 9, 15, 2, 49), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2035, 1, 17, 4, 45, 9), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2035, 1, 23, 20, 16, 19), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2035, 1, 31, 6, 2, 15), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2035, 2, 8, 8, 21, 51), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2035, 2, 15, 13, 16, 43), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2035, 2, 22, 8, 53, 38), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2035, 3, 2, 3, 0, 46), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2035, 3, 9, 23, 9, 11), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2035, 3, 16, 20, 14, 43), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2035, 3, 23, 22, 41, 51), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2035, 3, 31, 23, 6, 21), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2035, 4, 8, 10, 57, 31), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2035, 4, 15, 2, 54, 33), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2035, 4, 22, 13, 20, 28), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2035, 4, 30, 16, 53, 39), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2035, 5, 7, 20, 3, 37), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2035, 5, 14, 10, 28, 9), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2035, 5, 22, 4, 25, 30), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2035, 5, 30, 7, 30, 25), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2035, 6, 6, 3, 20, 25), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2035, 6, 12, 19, 49, 44), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2035, 6, 20, 19, 37, 10), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2035, 6, 28, 18, 42, 28), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2035, 7, 5, 9, 59, 1), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2035, 7, 12, 7, 32, 36), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2035, 7, 20, 10, 36, 33), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2035, 7, 28, 2, 55, 19), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2035, 8, 3, 17, 11, 30), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2035, 8, 10, 21, 52, 12), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2035, 8, 19, 1, 0, 0), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2035, 8, 26, 9, 7, 42), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2035, 9, 2, 1, 59, 14), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2035, 9, 9, 14, 46, 56), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2035, 9, 17, 14, 23, 14), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2035, 9, 24, 14, 39, 19), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2035, 10, 1, 13, 6, 31), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2035, 10, 9, 9, 49, 0), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2035, 10, 17, 2, 35, 17), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2035, 10, 23, 20, 56, 31), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2035, 10, 31, 2, 58, 25), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2035, 11, 8, 5, 50, 10), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2035, 11, 15, 13, 48, 36), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2035, 11, 22, 5, 16, 4), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2035, 11, 29, 19, 37, 18), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2035, 12, 8, 1, 4, 56), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2035, 12, 15, 0, 32, 54), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2035, 12, 21, 16, 28, 15), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2035, 12, 29, 14, 30, 38), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2036, 1, 6, 17, 47, 47), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2036, 1, 13, 11, 15, 49), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2036, 1, 20, 6, 46, 5), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2036, 1, 28, 10, 16, 54), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2036, 2, 5, 7, 0, 34), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2036, 2, 11, 22, 8, 24), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2036, 2, 18, 23, 46, 41), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2036, 2, 27, 4, 58, 59), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2036, 3, 5, 16, 48, 40), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2036, 3, 12, 9, 9, 11), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2036, 3, 19, 18, 38, 23), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2036, 3, 27, 20, 56, 26), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2036, 4, 4, 0, 3, 5), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2036, 4, 10, 20, 22, 16), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2036, 4, 18, 14, 5, 32), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2036, 4, 26, 9, 32, 55), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2036, 5, 3, 5, 54, 2), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2036, 5, 10, 8, 9, 15), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2036, 5, 18, 8, 39, 11), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2036, 5, 25, 19, 16, 39), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2036, 6, 1, 11, 34, 8), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2036, 6, 8, 21, 1, 40), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2036, 6, 17, 1, 2, 55), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2036, 6, 24, 3, 9, 19), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2036, 6, 30, 18, 12, 46), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2036, 7, 8, 11, 18, 59), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2036, 7, 16, 14, 39, 21), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2036, 7, 23, 10, 16, 41), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2036, 7, 30, 2, 56, 0), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2036, 8, 7, 2, 48, 37), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2036, 8, 15, 1, 35, 35), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2036, 8, 21, 17, 35, 2), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2036, 8, 28, 14, 43, 8), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2036, 9, 5, 18, 45, 16), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2036, 9, 13, 10, 28, 52), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2036, 9, 20, 1, 51, 16), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2036, 9, 27, 6, 12, 14), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2036, 10, 5, 10, 14, 51), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2036, 10, 12, 18, 9, 5), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2036, 10, 19, 11, 49, 42), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2036, 10, 27, 1, 13, 26), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2036, 11, 4, 0, 43, 58), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2036, 11, 11, 1, 28, 20), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2036, 11, 18, 0, 14, 12), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2036, 11, 25, 22, 27, 51), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2036, 12, 3, 14, 8, 12), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2036, 12, 10, 9, 18, 12), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2036, 12, 17, 15, 34, 9), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2036, 12, 25, 19, 44, 17), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2037, 1, 2, 2, 34, 58), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2037, 1, 8, 18, 28, 50), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2037, 1, 16, 9, 34, 5), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2037, 1, 24, 14, 54, 49), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2037, 1, 31, 14, 3, 50), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2037, 2, 7, 5, 43, 10), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2037, 2, 15, 4, 53, 46), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2037, 2, 23, 6, 40, 41), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2037, 3, 2, 0, 27, 51), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2037, 3, 8, 19, 24, 54), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2037, 3, 16, 23, 35, 55), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2037, 3, 24, 18, 39, 17), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2037, 3, 31, 9, 53, 14), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2037, 4, 7, 11, 24, 43), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2037, 4, 15, 16, 7, 23), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2037, 4, 23, 3, 11, 12), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2037, 4, 29, 18, 53, 34), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2037, 5, 7, 4, 55, 45), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2037, 5, 15, 5, 54, 1), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2037, 5, 22, 9, 8, 13), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2037, 5, 29, 4, 23, 48), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2037, 6, 5, 22, 48, 45), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2037, 6, 13, 17, 9, 55), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2037, 6, 20, 13, 45, 0), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2037, 6, 27, 15, 19, 35), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2037, 7, 5, 16, 0, 11), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2037, 7, 13, 2, 31, 27), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2037, 7, 19, 18, 30, 44), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2037, 7, 27, 4, 14, 48), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2037, 8, 4, 7, 50, 47), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2037, 8, 11, 10, 41, 15), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2037, 8, 18, 0, 59, 21), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2037, 8, 25, 19, 9, 0), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2037, 9, 2, 22, 2, 57), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2037, 9, 9, 18, 25, 9), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2037, 9, 16, 10, 35, 48), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2037, 9, 24, 11, 31, 22), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2037, 10, 2, 10, 28, 54), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2037, 10, 9, 2, 34, 12), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2037, 10, 16, 0, 15, 10), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2037, 10, 24, 4, 36, 12), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2037, 10, 31, 21, 6, 14), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2037, 11, 7, 12, 2, 48), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2037, 11, 14, 17, 58, 27), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2037, 11, 22, 21, 34, 55), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2037, 11, 30, 6, 6, 13), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2037, 12, 6, 23, 38, 1), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2037, 12, 14, 14, 41, 43), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2037, 12, 22, 13, 38, 18), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2037, 12, 29, 14, 4, 42), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2038, 1, 5, 13, 41, 1), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2038, 1, 13, 12, 33, 35), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2038, 1, 21, 3, 59, 37), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2038, 1, 27, 22, 0, 11), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2038, 2, 4, 5, 51, 50), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2038, 2, 12, 9, 29, 23), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2038, 2, 19, 16, 9, 4), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2038, 2, 26, 6, 55, 37), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2038, 3, 5, 23, 14, 39), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2038, 3, 14, 3, 41, 19), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2038, 3, 21, 2, 9, 15), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2038, 3, 27, 17, 35, 42), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2038, 4, 4, 16, 42, 39), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2038, 4, 12, 18, 1, 35), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2038, 4, 19, 10, 35, 40), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2038, 4, 26, 6, 14, 58), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2038, 5, 4, 9, 19, 17), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2038, 5, 12, 4, 17, 41), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2038, 5, 18, 18, 23, 11), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2038, 5, 25, 20, 43, 6), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2038, 6, 3, 0, 23, 52), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2038, 6, 10, 11, 11, 2), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2038, 6, 17, 2, 30, 10), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2038, 6, 24, 12, 39, 16), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2038, 7, 2, 13, 31, 47), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2038, 7, 9, 16, 0, 15), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2038, 7, 16, 11, 47, 53), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2038, 7, 24, 5, 39, 33), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2038, 8, 1, 0, 39, 58), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2038, 8, 7, 20, 21, 8), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2038, 8, 14, 22, 56, 27), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2038, 8, 22, 23, 11, 44), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2038, 8, 30, 10, 12, 24), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2038, 9, 6, 1, 50, 24), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2038, 9, 13, 12, 24, 3), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2038, 9, 21, 16, 26, 41), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2038, 9, 28, 18, 57, 13), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2038, 10, 5, 9, 51, 55), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2038, 10, 13, 4, 21, 29), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2038, 10, 21, 8, 23, 4), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2038, 10, 28, 3, 52, 30), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2038, 11, 3, 21, 23, 25), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2038, 11, 11, 22, 26, 50), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2038, 11, 19, 22, 9, 41), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2038, 11, 26, 13, 46, 21), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2038, 12, 3, 12, 45, 50), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2038, 12, 11, 17, 30, 3), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2038, 12, 19, 9, 28, 40), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2038, 12, 26, 1, 1, 36), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2039, 1, 2, 7, 36, 33), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2039, 1, 10, 11, 45, 10), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2039, 1, 17, 18, 41, 20), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2039, 1, 24, 13, 35, 45), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2039, 2, 1, 4, 44, 46), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2039, 2, 9, 3, 38, 52), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2039, 2, 16, 2, 35, 39), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2039, 2, 23, 3, 17, 9), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2039, 3, 3, 2, 14, 41), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2039, 3, 10, 16, 34, 37), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2039, 3, 17, 10, 7, 17), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2039, 3, 24, 17, 59, 8), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2039, 4, 1, 21, 54, 18), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2039, 4, 9, 2, 52, 23), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2039, 4, 15, 18, 6, 38), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2039, 4, 23, 9, 34, 22), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2039, 5, 1, 14, 6, 59), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2039, 5, 8, 11, 19, 37), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2039, 5, 15, 3, 16, 36), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2039, 5, 23, 1, 37, 38), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2039, 5, 31, 2, 24, 5), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2039, 6, 6, 18, 47, 18), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2039, 6, 13, 14, 16, 5), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2039, 6, 21, 17, 21, 3), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2039, 6, 29, 11, 16, 54), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2039, 7, 6, 2, 3, 6), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2039, 7, 13, 3, 37, 52), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2039, 7, 21, 7, 53, 41), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2039, 7, 28, 17, 49, 19), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2039, 8, 4, 9, 56, 15), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2039, 8, 11, 19, 35, 34), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2039, 8, 19, 20, 50, 8), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2039, 8, 26, 23, 16, 0), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2039, 9, 2, 19, 23, 9), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2039, 9, 10, 13, 45, 14), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2039, 9, 18, 8, 22, 37), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2039, 9, 25, 4, 52, 10), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2039, 10, 2, 7, 22, 54), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2039, 10, 10, 8, 59, 5), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2039, 10, 17, 19, 8, 37), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2039, 10, 24, 11, 50, 9), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2039, 10, 31, 22, 35, 52), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2039, 11, 9, 3, 45, 40), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2039, 11, 16, 5, 45, 42), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2039, 11, 22, 21, 16, 16), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2039, 11, 30, 16, 49, 11), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2039, 12, 8, 20, 44, 11), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2039, 12, 15, 16, 31, 39), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2039, 12, 22, 10, 1, 15), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2039, 12, 30, 12, 37, 21), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2040, 1, 7, 11, 5, 9), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2040, 1, 14, 3, 24, 52), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2040, 1, 21, 2, 20, 47), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2040, 1, 29, 7, 54, 18), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2040, 2, 5, 22, 32, 4), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2040, 2, 12, 14, 24, 2), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2040, 2, 19, 21, 33, 16), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2040, 2, 28, 0, 59, 8), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2040, 3, 6, 7, 18, 23), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2040, 3, 13, 1, 45, 42), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2040, 3, 20, 17, 58, 41), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2040, 3, 28, 15, 11, 17), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2040, 4, 4, 14, 5, 49), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2040, 4, 11, 13, 59, 47), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2040, 4, 19, 13, 37, 4), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2040, 4, 27, 2, 37, 27), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2040, 5, 3, 19, 59, 19), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2040, 5, 11, 3, 27, 28), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2040, 5, 19, 7, 0, 8), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2040, 5, 26, 11, 46, 40), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2040, 6, 2, 2, 16, 57), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2040, 6, 9, 18, 2, 39), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2040, 6, 17, 21, 31, 56), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2040, 6, 24, 19, 18, 53), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2040, 7, 1, 10, 17, 18), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2040, 7, 9, 9, 14, 19), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2040, 7, 17, 9, 15, 50), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2040, 7, 24, 2, 5, 12), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2040, 7, 30, 21, 5, 21), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2040, 8, 8, 0, 25, 58), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2040, 8, 15, 18, 35, 34), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2040, 8, 22, 9, 9, 15), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2040, 8, 29, 11, 16, 7), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2040, 9, 6, 15, 13, 15), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2040, 9, 14, 2, 7, 9), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2040, 9, 20, 17, 42, 21), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2040, 9, 28, 4, 41, 0), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2040, 10, 6, 5, 25, 29), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2040, 10, 13, 8, 40, 49), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2040, 10, 20, 4, 49, 26), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2040, 10, 28, 0, 26, 28), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2040, 11, 4, 18, 55, 35), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2040, 11, 11, 15, 22, 58), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2040, 11, 18, 19, 5, 45), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2040, 11, 26, 21, 7, 2), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2040, 12, 4, 7, 32, 44), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2040, 12, 10, 23, 29, 21), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2040, 12, 18, 12, 15, 19), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2040, 12, 26, 17, 2, 0), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2041, 1, 2, 19, 7, 21), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2041, 1, 9, 10, 5, 21), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2041, 1, 17, 7, 10, 57), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2041, 1, 25, 10, 32, 46), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2041, 2, 1, 5, 42, 29), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2041, 2, 7, 23, 39, 50), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2041, 2, 16, 2, 20, 49), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2041, 2, 24, 0, 28, 19), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2041, 3, 2, 15, 38, 53), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2041, 3, 9, 15, 50, 45), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2041, 3, 17, 20, 18, 41), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2041, 3, 25, 10, 31, 30), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2041, 4, 1, 1, 29, 0), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2041, 4, 8, 9, 38, 7), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2041, 4, 16, 12, 0, 13), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2041, 4, 23, 17, 23, 40), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2041, 4, 30, 11, 45, 54), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2041, 5, 8, 3, 53, 41), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2041, 5, 16, 0, 51, 56), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2041, 5, 22, 22, 25, 28), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2041, 5, 29, 22, 55, 32), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2041, 6, 6, 21, 40, 12), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2041, 6, 14, 10, 58, 16), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2041, 6, 21, 3, 11, 44), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2041, 6, 28, 11, 16, 27), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2041, 7, 6, 14, 12, 1), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2041, 7, 13, 19, 0, 22), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2041, 7, 20, 9, 12, 44), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2041, 7, 28, 1, 1, 51), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2041, 8, 5, 4, 52, 17), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2041, 8, 12, 2, 4, 13), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2041, 8, 18, 17, 42, 40), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2041, 8, 26, 16, 15, 42), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2041, 9, 3, 17, 18, 22), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2041, 9, 10, 9, 23, 23), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2041, 9, 17, 5, 32, 28), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2041, 9, 25, 8, 40, 49), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2041, 10, 3, 3, 32, 14), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2041, 10, 9, 18, 2, 14), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2041, 10, 16, 21, 4, 45), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2041, 10, 25, 1, 29, 52), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2041, 11, 1, 12, 4, 30), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2041, 11, 8, 4, 43, 3), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2041, 11, 15, 16, 5, 55), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2041, 11, 23, 17, 36, 17), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2041, 11, 30, 19, 48, 18), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2041, 12, 7, 17, 41, 47), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2041, 12, 15, 13, 32, 17), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2041, 12, 23, 8, 5, 56), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2041, 12, 30, 3, 45, 13), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2042, 1, 6, 8, 53, 29), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2042, 1, 14, 11, 24, 0), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2042, 1, 21, 20, 41, 39), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2042, 1, 28, 12, 48, 9), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2042, 2, 5, 1, 57, 20), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2042, 2, 13, 7, 15, 55), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2042, 2, 20, 7, 38, 27), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2042, 2, 26, 23, 28, 59), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2042, 3, 6, 20, 9, 29), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2042, 3, 14, 23, 20, 40), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2042, 3, 21, 17, 22, 33), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2042, 3, 28, 11, 59, 19), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2042, 4, 5, 14, 15, 28), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2042, 4, 13, 11, 8, 41), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2042, 4, 20, 2, 18, 51), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2042, 4, 27, 2, 18, 50), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2042, 5, 5, 6, 48, 8), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2042, 5, 12, 19, 17, 36), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2042, 5, 19, 10, 54, 23), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2042, 5, 26, 18, 17, 37), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2042, 6, 3, 20, 47, 49), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2042, 6, 11, 0, 59, 43), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2042, 6, 17, 19, 47, 42), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2042, 6, 25, 11, 28, 21), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2042, 7, 3, 8, 8, 55), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2042, 7, 10, 5, 37, 47), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2042, 7, 17, 5, 51, 23), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2042, 7, 25, 5, 1, 10), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2042, 8, 1, 17, 32, 50), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2042, 8, 8, 10, 34, 15), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2042, 8, 15, 18, 0, 49), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2042, 8, 23, 21, 55, 1), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2042, 8, 31, 2, 1, 52), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2042, 9, 6, 17, 8, 29), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2042, 9, 14, 8, 49, 42), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2042, 9, 22, 13, 20, 14), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2042, 9, 29, 10, 33, 50), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2042, 10, 6, 2, 34, 23), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2042, 10, 14, 2, 2, 40), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2042, 10, 22, 2, 52, 39), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2042, 10, 28, 19, 47, 56), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2042, 11, 4, 15, 50, 53), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2042, 11, 12, 20, 27, 59), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2042, 11, 20, 14, 30, 52), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2042, 11, 27, 6, 5, 33), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2042, 12, 4, 9, 18, 21), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2042, 12, 12, 14, 29, 13), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2042, 12, 20, 0, 27, 6), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2042, 12, 26, 17, 42, 16), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2043, 1, 3, 6, 7, 41), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2043, 1, 11, 6, 52, 48), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2043, 1, 18, 9, 4, 28), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2043, 1, 25, 6, 56, 9), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2043, 2, 2, 4, 14, 18), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2043, 2, 9, 21, 7, 14), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2043, 2, 16, 16, 59, 43), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2043, 2, 23, 21, 57, 28), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2043, 3, 4, 1, 6, 57), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2043, 3, 11, 9, 8, 50), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2043, 3, 18, 1, 2, 41), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2043, 3, 25, 14, 25, 49), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2043, 4, 2, 18, 55, 46), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2043, 4, 9, 19, 6, 9), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2043, 4, 16, 10, 8, 25), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2043, 4, 24, 7, 22, 33), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2043, 5, 2, 8, 58, 24), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2043, 5, 9, 3, 20, 45), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2043, 5, 15, 21, 4, 35), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2043, 5, 23, 23, 36, 29), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2043, 5, 31, 19, 24, 13), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2043, 6, 7, 10, 34, 34), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2043, 6, 14, 10, 18, 15), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2043, 6, 22, 14, 20, 8), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2043, 6, 30, 2, 52, 33), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2043, 7, 6, 17, 50, 27), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2043, 7, 14, 1, 46, 20), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2043, 7, 22, 3, 23, 42), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2043, 7, 29, 8, 22, 17), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2043, 8, 5, 2, 22, 14), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2043, 8, 12, 18, 56, 43), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2043, 8, 20, 15, 4, 1), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2043, 8, 27, 13, 8, 42), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2043, 9, 3, 13, 16, 50), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2043, 9, 11, 13, 0, 33), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2043, 9, 19, 1, 46, 35), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2043, 9, 25, 18, 39, 51), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2043, 10, 3, 3, 11, 40), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2043, 10, 11, 7, 4, 27), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2043, 10, 18, 11, 55, 12), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2043, 10, 25, 2, 27, 3), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2043, 11, 1, 19, 56, 59), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2043, 11, 10, 0, 12, 36), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2043, 11, 16, 21, 52, 0), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2043, 11, 23, 13, 45, 10), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2043, 12, 1, 14, 36, 30), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2043, 12, 9, 15, 27, 1), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2043, 12, 16, 8, 1, 31), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2043, 12, 23, 5, 3, 57), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2043, 12, 31, 9, 47, 35), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2044, 1, 8, 4, 1, 26), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2044, 1, 14, 18, 50, 39), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2044, 1, 21, 23, 46, 35), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2044, 1, 30, 4, 4, 1), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2044, 2, 6, 13, 45, 43), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2044, 2, 13, 6, 41, 17), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2044, 2, 20, 20, 19, 41), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2044, 2, 28, 20, 11, 53), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2044, 3, 6, 21, 16, 42), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2044, 3, 13, 19, 40, 41), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2044, 3, 21, 16, 51, 56), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2044, 3, 29, 9, 25, 31), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2044, 4, 5, 3, 44, 31), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2044, 4, 12, 9, 38, 40), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2044, 4, 20, 11, 48, 0), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2044, 4, 27, 19, 41, 38), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2044, 5, 4, 10, 27, 33), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2044, 5, 12, 0, 16, 10), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2044, 5, 20, 4, 1, 28), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2044, 5, 27, 3, 39, 6), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2044, 6, 2, 18, 32, 59), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2044, 6, 10, 15, 15, 37), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2044, 6, 18, 16, 59, 22), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2044, 6, 25, 10, 23, 49), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2044, 7, 2, 4, 48, 0), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2044, 7, 10, 6, 21, 30), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2044, 7, 18, 2, 46, 14), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2044, 7, 24, 17, 9, 58), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2044, 7, 31, 17, 39, 54), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2044, 8, 8, 21, 13, 22), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2044, 8, 16, 10, 2, 49), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2044, 8, 23, 1, 5, 29), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2044, 8, 30, 9, 18, 9), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2044, 9, 7, 11, 23, 54), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2044, 9, 14, 15, 57, 14), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2044, 9, 21, 11, 2, 59), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2044, 9, 29, 3, 30, 6), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2044, 10, 7, 0, 29, 34), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2044, 10, 13, 21, 52, 1), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2044, 10, 20, 23, 35, 56), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2044, 10, 28, 23, 27, 10), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2044, 11, 5, 12, 26, 15), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2044, 11, 12, 5, 8, 56), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2044, 11, 19, 14, 57, 18), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2044, 11, 27, 19, 35, 53), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2044, 12, 4, 23, 33, 24), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2044, 12, 11, 14, 51, 47), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2044, 12, 19, 8, 52, 40), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2044, 12, 27, 13, 59, 33), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2045, 1, 3, 10, 19, 58), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2045, 1, 10, 3, 31, 37), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2045, 1, 18, 4, 24, 59), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2045, 1, 26, 5, 8, 33), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2045, 2, 1, 21, 5, 1), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2045, 2, 8, 19, 2, 47), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2045, 2, 16, 23, 50, 37), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2045, 2, 24, 16, 36, 30), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2045, 3, 3, 7, 52, 9), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2045, 3, 10, 12, 49, 26), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2045, 3, 18, 17, 14, 22), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2045, 3, 26, 0, 55, 51), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2045, 4, 1, 18, 42, 29), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2045, 4, 9, 7, 51, 44), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2045, 4, 17, 7, 26, 11), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2045, 4, 24, 7, 11, 30), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2045, 5, 1, 5, 51, 40), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2045, 5, 9, 2, 50, 22), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2045, 5, 16, 18, 26, 6), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2045, 5, 23, 12, 37, 53), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2045, 5, 30, 17, 51, 53), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2045, 6, 7, 20, 22, 39), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2045, 6, 15, 3, 4, 17), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2045, 6, 21, 18, 28, 2), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2045, 6, 29, 7, 15, 16), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2045, 7, 7, 11, 30, 9), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2045, 7, 14, 10, 27, 51), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2045, 7, 21, 1, 51, 47), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2045, 7, 28, 22, 10, 3), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2045, 8, 5, 23, 56, 33), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2045, 8, 12, 17, 38, 43), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2045, 8, 19, 11, 54, 43), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2045, 8, 27, 14, 7, 8), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2045, 9, 4, 10, 2, 59), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2045, 9, 11, 1, 27, 12), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2045, 9, 18, 1, 29, 41), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2045, 9, 26, 6, 11, 2), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2045, 10, 3, 18, 31, 8), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2045, 10, 10, 10, 36, 21), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2045, 10, 17, 18, 54, 51), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2045, 10, 25, 21, 30, 41), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2045, 11, 2, 2, 9, 8), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2045, 11, 8, 21, 48, 25), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2045, 11, 16, 15, 25, 23), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2045, 11, 24, 11, 43, 2), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2045, 12, 1, 9, 45, 56), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2045, 12, 8, 11, 40, 51), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2045, 12, 16, 13, 7, 52), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2045, 12, 24, 0, 48, 44), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2045, 12, 30, 18, 10, 48), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2046, 1, 7, 4, 23, 31), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2046, 1, 15, 9, 42, 4), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2046, 1, 22, 12, 50, 47), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2046, 1, 29, 4, 10, 56), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2046, 2, 5, 23, 9, 11), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2046, 2, 14, 3, 19, 57), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2046, 2, 20, 23, 43, 46), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2046, 2, 27, 16, 22, 25), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2046, 3, 7, 18, 14, 50), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2046, 3, 15, 17, 12, 18), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2046, 3, 22, 9, 26, 25), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2046, 3, 29, 6, 56, 43), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2046, 4, 6, 11, 51, 9), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2046, 4, 14, 3, 20, 59), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2046, 4, 20, 18, 20, 33), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2046, 4, 27, 23, 29, 52), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2046, 5, 6, 2, 55, 33), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2046, 5, 13, 10, 24, 20), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2046, 5, 20, 3, 14, 44), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2046, 5, 27, 17, 6, 3), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2046, 6, 4, 15, 21, 54), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2046, 6, 11, 15, 27, 8), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2046, 6, 18, 13, 9, 28), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2046, 6, 26, 10, 39, 26), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2046, 7, 4, 1, 38, 20), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2046, 7, 10, 19, 52, 51), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2046, 7, 18, 0, 54, 28), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2046, 7, 26, 3, 18, 53), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2046, 8, 2, 10, 24, 56), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2046, 8, 9, 1, 15, 9), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2046, 8, 16, 14, 49, 33), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2046, 8, 24, 18, 35, 43), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2046, 8, 31, 18, 24, 53), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2046, 9, 7, 9, 6, 26), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2046, 9, 15, 6, 38, 49), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2046, 9, 23, 8, 15, 10), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2046, 9, 30, 2, 24, 58), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2046, 10, 6, 20, 40, 32), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2046, 10, 14, 23, 40, 45), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2046, 10, 22, 20, 7, 10), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2046, 10, 29, 11, 16, 29), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2046, 11, 5, 12, 28, 5), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2046, 11, 13, 17, 3, 54), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2046, 11, 21, 6, 9, 52), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2046, 11, 27, 21, 49, 33), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2046, 12, 5, 7, 55, 51), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2046, 12, 13, 9, 54, 54), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2046, 12, 20, 14, 42, 31), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2046, 12, 27, 10, 38, 20), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2047, 1, 4, 5, 30, 26), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2047, 1, 12, 1, 20, 47), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2047, 1, 18, 22, 32, 1), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2047, 1, 26, 1, 43, 13), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2047, 2, 3, 3, 8, 29), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2047, 2, 10, 14, 39, 16), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2047, 2, 17, 6, 42, 1), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2047, 2, 24, 18, 25, 20), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2047, 3, 4, 22, 51, 19), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2047, 3, 12, 1, 36, 25), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2047, 3, 18, 16, 10, 12), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2047, 3, 26, 11, 43, 37), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2047, 4, 3, 15, 10, 20), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2047, 4, 10, 10, 34, 43), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2047, 4, 17, 3, 29, 37), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2047, 4, 25, 4, 39, 15), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2047, 5, 3, 3, 25, 48), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2047, 5, 9, 18, 23, 53), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2047, 5, 16, 16, 45, 15), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2047, 5, 24, 20, 26, 58), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2047, 6, 1, 11, 53, 49), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2047, 6, 8, 2, 4, 17), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2047, 6, 15, 7, 44, 36), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2047, 6, 23, 10, 35, 14), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2047, 6, 30, 17, 36, 24), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2047, 7, 7, 10, 33, 14), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2047, 7, 15, 0, 8, 57), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2047, 7, 22, 22, 48, 44), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2047, 7, 29, 22, 2, 16), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2047, 8, 5, 20, 37, 53), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2047, 8, 13, 17, 33, 30), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2047, 8, 21, 9, 15, 39), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2047, 8, 28, 2, 48, 59), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2047, 9, 4, 8, 53, 36), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2047, 9, 12, 11, 17, 44), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2047, 9, 19, 18, 30, 50), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2047, 9, 26, 9, 28, 19), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2047, 10, 3, 23, 41, 27), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2047, 10, 12, 4, 21, 14), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2047, 10, 19, 3, 27, 22), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2047, 10, 25, 19, 12, 16), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2047, 11, 2, 16, 57, 34), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2047, 11, 10, 19, 38, 49), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2047, 11, 17, 12, 58, 21), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2047, 11, 24, 8, 40, 13), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2047, 12, 2, 11, 54, 30), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2047, 12, 10, 8, 28, 25), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2047, 12, 16, 23, 37, 39), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2047, 12, 24, 1, 50, 24), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2048, 1, 1, 6, 56, 30), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2048, 1, 8, 18, 48, 56), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2048, 1, 15, 11, 31, 51), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2048, 1, 22, 21, 55, 34), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2048, 1, 31, 0, 13, 42), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2048, 2, 7, 3, 16, 6), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2048, 2, 14, 0, 30, 55), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2048, 2, 21, 19, 21, 51), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2048, 2, 29, 14, 37, 31), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2048, 3, 7, 10, 44, 31), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2048, 3, 14, 14, 27, 3), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2048, 3, 22, 16, 2, 53), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2048, 3, 30, 2, 3, 47), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2048, 4, 5, 18, 9, 59), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2048, 4, 13, 5, 19, 6), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2048, 4, 21, 10, 1, 34), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2048, 4, 28, 11, 12, 21), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2048, 5, 5, 2, 21, 48), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2048, 5, 12, 20, 57, 33), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2048, 5, 21, 0, 15, 37), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2048, 5, 27, 18, 56, 37), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2048, 6, 3, 12, 4, 6), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2048, 6, 11, 12, 49, 18), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2048, 6, 19, 10, 48, 57), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2048, 6, 26, 2, 7, 28), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2048, 7, 2, 23, 57, 13), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2048, 7, 11, 4, 3, 34), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2048, 7, 18, 18, 31, 3), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2048, 7, 25, 9, 33, 17), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2048, 8, 1, 14, 29, 53), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2048, 8, 9, 17, 58, 17), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2048, 8, 17, 0, 31, 15), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2048, 8, 23, 18, 6, 31), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2048, 8, 31, 7, 41, 17), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2048, 9, 8, 6, 23, 57), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2048, 9, 15, 6, 3, 17), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2048, 9, 22, 4, 45, 55), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2048, 9, 30, 2, 44, 52), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2048, 10, 7, 17, 44, 35), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2048, 10, 14, 12, 19, 28), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2048, 10, 21, 18, 24, 19), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2048, 10, 29, 22, 13, 52), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2048, 11, 6, 4, 37, 44), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2048, 11, 12, 20, 28, 13), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2048, 11, 20, 11, 18, 53), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2048, 11, 28, 16, 32, 56), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2048, 12, 5, 15, 29, 30), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2048, 12, 12, 7, 28, 27), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2048, 12, 20, 6, 38, 35), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2048, 12, 28, 8, 30, 56), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2049, 1, 4, 2, 23, 52), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2049, 1, 10, 21, 55, 10), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2049, 1, 19, 2, 28, 28), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2049, 1, 26, 21, 32, 26), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2049, 2, 2, 13, 15, 13), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2049, 2, 9, 15, 37, 41), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2049, 2, 17, 20, 46, 46), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2049, 2, 25, 7, 35, 40), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2049, 3, 4, 0, 10, 53), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2049, 3, 11, 11, 25, 31), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2049, 3, 19, 12, 22, 39), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2049, 3, 26, 15, 9, 38), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2049, 4, 2, 11, 38, 33), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2049, 4, 10, 7, 26, 48), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2049, 4, 18, 1, 4, 3), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2049, 4, 24, 21, 10, 32), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2049, 5, 2, 0, 10, 22), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2049, 5, 10, 1, 56, 52), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2049, 5, 17, 11, 13, 6), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2049, 5, 24, 2, 53, 26), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2049, 5, 31, 13, 59, 28), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2049, 6, 8, 17, 55, 49), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2049, 6, 15, 19, 26, 8), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2049, 6, 22, 9, 40, 32), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2049, 6, 30, 4, 49, 39), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2049, 7, 8, 7, 9, 29), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2049, 7, 15, 2, 28, 58), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2049, 7, 21, 18, 47, 56), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2049, 7, 29, 20, 6, 35), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2049, 8, 6, 17, 51, 4), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2049, 8, 13, 9, 18, 54), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2049, 8, 20, 7, 10, 6), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2049, 8, 28, 11, 17, 59), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2049, 9, 5, 2, 27, 33), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2049, 9, 11, 17, 3, 43), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2049, 9, 18, 23, 3, 7), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2049, 9, 27, 2, 4, 25), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2049, 10, 4, 9, 38, 12), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2049, 10, 11, 2, 52, 37), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2049, 10, 18, 17, 54, 54), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2049, 10, 26, 16, 14, 27), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2049, 11, 2, 16, 18, 31), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2049, 11, 9, 15, 37, 15), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2049, 11, 17, 14, 31, 29), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2049, 11, 25, 5, 35, 3), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2049, 12, 1, 23, 39, 4), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2049, 12, 9, 7, 27, 23), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2049, 12, 17, 11, 14, 1), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2049, 12, 24, 17, 51, 7), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2049, 12, 31, 8, 52, 7), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2050, 1, 8, 1, 38, 22), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2050, 1, 16, 6, 16, 56), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2050, 1, 23, 4, 56, 17), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2050, 1, 29, 20, 47, 13), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2050, 2, 6, 20, 46, 48), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2050, 2, 14, 22, 10, 4), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2050, 2, 21, 15, 2, 49), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2050, 2, 28, 11, 29, 1), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2050, 3, 8, 15, 22, 33), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2050, 3, 16, 10, 7, 18), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2050, 3, 23, 0, 40, 14), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2050, 3, 30, 4, 16, 43), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2050, 4, 7, 8, 11, 25), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2050, 4, 14, 18, 23, 12), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2050, 4, 21, 10, 25, 4), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2050, 4, 28, 22, 7, 49), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2050, 5, 6, 22, 25, 24), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2050, 5, 14, 0, 3, 8), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2050, 5, 20, 20, 50, 20), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2050, 5, 28, 16, 3, 32), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2050, 6, 5, 9, 50, 27), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2050, 6, 12, 4, 38, 40), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2050, 6, 19, 8, 21, 8), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2050, 6, 27, 9, 16, 8), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2050, 7, 4, 18, 50, 15), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2050, 7, 11, 9, 45, 16), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2050, 7, 18, 21, 16, 6), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2050, 7, 27, 1, 4, 38), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2050, 8, 3, 2, 19, 45), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2050, 8, 9, 16, 47, 39), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2050, 8, 17, 11, 46, 44), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2050, 8, 25, 14, 55, 39), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2050, 9, 1, 9, 30, 8), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2050, 9, 8, 2, 50, 20), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2050, 9, 16, 3, 48, 36), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2050, 9, 24, 2, 33, 27), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2050, 9, 30, 17, 31, 4), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2050, 10, 7, 16, 31, 25), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2050, 10, 15, 20, 47, 54), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2050, 10, 23, 12, 9, 56), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2050, 10, 30, 3, 15, 13), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2050, 11, 6, 9, 56, 21), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2050, 11, 14, 13, 40, 42), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2050, 11, 21, 20, 24, 39), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2050, 11, 28, 15, 9, 4), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2050, 12, 6, 6, 26, 48), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2050, 12, 14, 5, 17, 24), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2050, 12, 21, 4, 14, 28), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2050, 12, 28, 5, 14, 52), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2051, 1, 5, 4, 28, 2), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2051, 1, 12, 18, 57, 26), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2051, 1, 19, 12, 37, 14), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2051, 1, 26, 21, 19, 8), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2051, 2, 4, 1, 39, 17), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2051, 2, 11, 6, 40, 53), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2051, 2, 17, 22, 16, 4), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2051, 2, 25, 14, 52, 36), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2051, 3, 5, 19, 46, 24), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2051, 3, 12, 16, 51, 51), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2051, 3, 19, 9, 33, 32), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2051, 3, 27, 8, 59, 23), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2051, 4, 4, 9, 40, 24), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2051, 4, 11, 1, 58, 33), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2051, 4, 17, 22, 37, 48), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2051, 4, 26, 2, 18, 31), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2051, 5, 3, 19, 30, 5), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2051, 5, 10, 10, 28, 29), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2051, 5, 17, 13, 28, 59), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2051, 5, 25, 17, 34, 22), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2051, 6, 2, 2, 14, 49), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2051, 6, 8, 18, 55, 52), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2051, 6, 16, 5, 54, 43), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2051, 6, 24, 6, 13, 38), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2051, 7, 1, 7, 14, 20), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2051, 7, 8, 4, 8, 33), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2051, 7, 15, 23, 20, 13), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2051, 7, 23, 16, 35, 43), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2051, 7, 30, 11, 51, 52), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2051, 8, 6, 15, 4, 18), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2051, 8, 14, 16, 48, 46), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2051, 8, 22, 1, 34, 26), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2051, 8, 28, 17, 28, 26), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2051, 9, 5, 4, 32, 36), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2051, 9, 13, 9, 19, 55), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2051, 9, 20, 10, 10, 54), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2051, 9, 27, 1, 21, 11), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2051, 10, 4, 20, 46, 9), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2051, 10, 13, 0, 11, 23), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2051, 10, 19, 19, 12, 27), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2051, 10, 26, 12, 38, 11), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2051, 11, 3, 14, 58, 36), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2051, 11, 11, 13, 6, 13), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2051, 11, 18, 5, 5, 39), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2051, 11, 25, 4, 2, 0), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2051, 12, 3, 9, 36, 14), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2051, 12, 11, 0, 6, 20), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2051, 12, 17, 16, 4, 26), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2051, 12, 24, 23, 20, 17), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2052, 1, 2, 3, 4, 50), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2052, 1, 9, 9, 26, 23), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2052, 1, 16, 4, 23, 44), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2052, 1, 23, 21, 2, 57), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2052, 1, 31, 18, 29, 35), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2052, 2, 7, 17, 35, 2), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2052, 2, 14, 18, 20, 18), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2052, 2, 22, 18, 44, 3), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2052, 3, 1, 7, 35, 12), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2052, 3, 8, 1, 16, 55), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2052, 3, 15, 9, 54, 1), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2052, 3, 23, 14, 8, 35), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2052, 3, 30, 18, 26, 32), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2052, 4, 6, 9, 27, 56), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2052, 4, 14, 2, 28, 34), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2052, 4, 22, 6, 2, 47), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2052, 4, 29, 3, 20, 13), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2052, 5, 5, 19, 4, 13), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2052, 5, 13, 18, 59, 15), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2052, 5, 21, 18, 14, 18), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2052, 5, 28, 10, 49, 37), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2052, 6, 4, 6, 48, 45), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2052, 6, 12, 10, 26, 29), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2052, 6, 20, 3, 9, 28), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2052, 6, 26, 17, 49, 18), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2052, 7, 3, 20, 58, 41), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2052, 7, 12, 0, 22, 29), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2052, 7, 19, 9, 37, 0), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2052, 7, 26, 1, 30, 18), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2052, 8, 2, 13, 19, 14), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2052, 8, 10, 12, 51, 43), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2052, 8, 17, 14, 42, 42), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2052, 8, 24, 11, 6, 10), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2052, 9, 1, 7, 9, 27), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2052, 9, 9, 0, 14, 52), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2052, 9, 15, 19, 47, 25), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2052, 9, 22, 23, 32, 5), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2052, 10, 1, 1, 35, 28), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2052, 10, 8, 10, 54, 1), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2052, 10, 15, 2, 21, 20), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2052, 10, 22, 15, 2, 44), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2052, 10, 30, 19, 38, 35), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2052, 11, 6, 21, 8, 14), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2052, 11, 13, 11, 49, 30), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2052, 11, 21, 9, 1, 50), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2052, 11, 29, 12, 15, 51), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2052, 12, 6, 7, 17, 21), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2052, 12, 13, 1, 6, 30), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2052, 12, 21, 4, 14, 24), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2052, 12, 29, 2, 27, 41), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2053, 1, 4, 17, 45, 21), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2053, 1, 11, 18, 8, 53), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2053, 1, 19, 23, 11, 36), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2053, 1, 27, 13, 40, 45), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2053, 2, 3, 4, 57, 2), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2053, 2, 10, 13, 48, 28), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2053, 2, 18, 16, 30, 47), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2053, 2, 25, 22, 8, 46), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2053, 3, 4, 17, 8, 56), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2053, 3, 12, 10, 20, 38), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2053, 3, 20, 7, 10, 47), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2053, 3, 27, 4, 49, 30), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2053, 4, 3, 6, 22, 2), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2053, 4, 11, 6, 3, 37), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2053, 4, 18, 18, 47, 44), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2053, 4, 25, 11, 2, 7), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2053, 5, 2, 20, 24, 26), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2053, 5, 10, 23, 39, 20), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2053, 5, 18, 3, 42, 22), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2053, 5, 24, 18, 3, 37), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2053, 6, 1, 11, 1, 23), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2053, 6, 9, 14, 18, 58), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2053, 6, 16, 10, 50, 44), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2053, 6, 23, 2, 54, 36), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2053, 7, 1, 2, 0, 15), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2053, 7, 9, 1, 47, 1), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2053, 7, 15, 17, 25, 47), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2053, 7, 22, 14, 15, 10), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2053, 7, 30, 17, 5, 32), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2053, 8, 7, 10, 24, 23), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2053, 8, 14, 0, 40, 25), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2053, 8, 21, 4, 26, 14), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2053, 8, 29, 7, 52, 10), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2053, 9, 5, 17, 4, 9), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2053, 9, 12, 9, 35, 26), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2053, 9, 19, 21, 28, 46), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2053, 9, 27, 21, 49, 36), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2053, 10, 4, 23, 0, 51), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2053, 10, 11, 20, 53, 1), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2053, 10, 19, 16, 53, 26), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2053, 10, 27, 10, 37, 49), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2053, 11, 3, 5, 36, 24), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2053, 11, 10, 10, 54, 52), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2053, 11, 18, 13, 25, 41), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2053, 11, 25, 22, 20, 53), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2053, 12, 2, 14, 3, 49), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2053, 12, 10, 3, 39, 53), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2053, 12, 18, 9, 10, 29), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2053, 12, 25, 9, 22, 32), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2054, 1, 1, 1, 10, 15), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2054, 1, 8, 22, 33, 15), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2054, 1, 17, 2, 14, 7), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2054, 1, 23, 20, 7, 16), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2054, 1, 30, 15, 7, 32), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2054, 2, 7, 18, 13, 15), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2054, 2, 15, 15, 35, 17), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2054, 2, 22, 6, 46, 4), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2054, 3, 1, 7, 35, 31), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2054, 3, 9, 12, 45, 4), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2054, 3, 17, 1, 20, 28), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2054, 3, 23, 17, 20, 49), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2054, 3, 31, 1, 49, 55), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2054, 4, 8, 4, 31, 50), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2054, 4, 15, 8, 23, 12), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2054, 4, 22, 4, 1, 21), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2054, 4, 29, 20, 45, 30), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2054, 5, 7, 17, 0, 7), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2054, 5, 14, 13, 56, 38), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2054, 5, 21, 15, 15, 44), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2054, 5, 29, 15, 2, 52), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2054, 6, 6, 2, 39, 44), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2054, 6, 12, 19, 16, 37), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2054, 6, 20, 3, 42, 6), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2054, 6, 28, 7, 29, 55), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2054, 7, 5, 10, 33, 22), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2054, 7, 12, 1, 36, 11), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2054, 7, 19, 17, 46, 28), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2054, 7, 27, 21, 27, 9), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2054, 8, 3, 17, 47, 25), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2054, 8, 10, 10, 5, 5), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2054, 8, 18, 9, 21, 3), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2054, 8, 26, 8, 55, 36), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2054, 9, 2, 1, 17, 41), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2054, 9, 8, 21, 46, 0), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2054, 9, 17, 1, 40, 24), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2054, 9, 24, 18, 25, 19), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2054, 10, 1, 9, 48, 47), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2054, 10, 8, 13, 19, 2), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2054, 10, 16, 17, 43, 57), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2054, 10, 24, 2, 39, 0), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2054, 10, 30, 20, 0, 53), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2054, 11, 7, 8, 33, 40), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2054, 11, 15, 8, 48, 25), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2054, 11, 22, 10, 22, 8), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2054, 11, 29, 8, 33, 9), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2054, 12, 7, 6, 6, 21), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2054, 12, 14, 22, 40, 16), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2054, 12, 21, 18, 21, 17), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2054, 12, 28, 23, 51, 13), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2055, 1, 6, 3, 39, 4), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2055, 1, 13, 11, 20, 52), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2055, 1, 20, 3, 24, 1), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2055, 1, 27, 17, 38, 39), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2055, 2, 4, 22, 59, 9), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2055, 2, 11, 22, 47, 50), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2055, 2, 18, 14, 13, 38), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2055, 2, 26, 12, 38, 29), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2055, 3, 6, 14, 47, 18), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2055, 3, 13, 8, 56, 29), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2055, 3, 20, 3, 17, 43), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2055, 3, 28, 7, 0, 5), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2055, 4, 5, 2, 43, 9), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2055, 4, 11, 17, 57, 59), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2055, 4, 18, 18, 34, 55), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2055, 4, 26, 23, 16, 29), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2055, 5, 4, 11, 9, 47), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2055, 5, 11, 2, 31, 7), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2055, 5, 18, 11, 29, 23), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2055, 5, 26, 12, 56, 37), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2055, 6, 2, 17, 0, 32), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2055, 6, 9, 11, 35, 22), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2055, 6, 17, 5, 1, 58), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2055, 6, 25, 0, 15, 3), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2055, 7, 1, 21, 30, 48), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2055, 7, 8, 22, 10, 58), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2055, 7, 16, 22, 14, 14), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2055, 7, 24, 9, 47, 18), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2055, 7, 31, 2, 10, 30), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2055, 8, 7, 10, 56, 42), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2055, 8, 15, 14, 25, 56), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2055, 8, 22, 18, 14, 15), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2055, 8, 29, 8, 34, 49), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2055, 9, 6, 1, 55, 50), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2055, 9, 14, 5, 13, 52), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2055, 9, 21, 2, 19, 4), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2055, 9, 27, 18, 10, 15), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2055, 10, 5, 18, 37, 30), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2055, 10, 13, 18, 21, 51), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2055, 10, 20, 10, 48, 57), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2055, 10, 27, 7, 52, 36), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2055, 11, 4, 12, 11, 3), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2055, 11, 12, 5, 37, 49), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2055, 11, 18, 20, 33, 34), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2055, 11, 26, 1, 41, 18), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2055, 12, 4, 5, 39, 35), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2055, 12, 11, 15, 4, 41), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2055, 12, 18, 8, 14, 50), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2055, 12, 25, 22, 28, 44), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2056, 1, 2, 22, 5, 10), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2056, 1, 9, 23, 13, 12), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2056, 1, 16, 22, 10, 0), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2056, 1, 24, 20, 20, 15), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2056, 2, 1, 12, 35, 18), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2056, 2, 8, 7, 0, 19), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2056, 2, 15, 13, 59, 7), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2056, 2, 23, 17, 11, 7), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2056, 3, 2, 0, 39, 35), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2056, 3, 8, 15, 30, 26), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2056, 3, 16, 6, 51, 44), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2056, 3, 24, 11, 16, 39), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2056, 3, 31, 10, 24, 23), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2056, 4, 7, 1, 32, 46), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2056, 4, 14, 23, 49, 56), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2056, 4, 23, 1, 33, 3), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2056, 4, 29, 18, 30, 27), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2056, 5, 6, 13, 29, 46), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2056, 5, 14, 16, 5, 38), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2056, 5, 22, 11, 49, 51), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2056, 5, 29, 1, 57, 20), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2056, 6, 5, 3, 20, 56), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2056, 6, 13, 7, 3, 20), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2056, 6, 20, 18, 48, 1), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2056, 6, 27, 9, 47, 7), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2056, 7, 4, 18, 54, 18), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2056, 7, 12, 20, 19, 21), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2056, 7, 19, 23, 44, 24), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2056, 7, 26, 18, 53, 57), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2056, 8, 3, 11, 51, 45), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2056, 8, 11, 7, 47, 32), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2056, 8, 18, 4, 13, 8), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2056, 8, 25, 5, 59, 53), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2056, 9, 2, 5, 42, 17), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2056, 9, 9, 17, 46, 46), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2056, 9, 16, 9, 49, 52), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2056, 9, 23, 19, 33, 52), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2056, 10, 1, 23, 32, 28), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2056, 10, 9, 2, 59, 55), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2056, 10, 15, 17, 57, 35), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2056, 10, 23, 11, 45, 52), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2056, 10, 31, 16, 11, 25), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2056, 11, 7, 12, 20, 7), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2056, 11, 14, 5, 32, 31), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2056, 11, 22, 6, 10, 54), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2056, 11, 30, 6, 36, 36), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2056, 12, 6, 22, 30, 25), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2056, 12, 13, 20, 52, 58), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2056, 12, 22, 1, 33, 14), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2056, 12, 29, 18, 21, 28), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2057, 1, 5, 9, 48, 41), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2057, 1, 12, 15, 33, 52), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2057, 1, 20, 20, 0, 32), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2057, 1, 28, 3, 43, 15), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2057, 2, 3, 22, 9, 51), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2057, 2, 11, 12, 24, 45), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2057, 2, 19, 11, 56, 5), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2057, 2, 26, 11, 29, 36), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2057, 3, 5, 11, 24, 5), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2057, 3, 13, 9, 34, 33), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2057, 3, 21, 0, 44, 18), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2057, 3, 27, 18, 38, 41), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2057, 4, 4, 1, 30, 46), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2057, 4, 12, 4, 58, 43), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2057, 4, 19, 10, 48, 52), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2057, 4, 26, 2, 5, 38), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2057, 5, 3, 16, 31, 26), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2057, 5, 11, 21, 6, 8), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2057, 5, 18, 19, 1, 46), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2057, 5, 25, 10, 39, 58), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2057, 6, 2, 8, 10, 22), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2057, 6, 10, 9, 29, 13), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2057, 6, 17, 2, 17, 55), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2057, 6, 23, 21, 7, 39), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2057, 7, 1, 23, 46, 51), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2057, 7, 9, 18, 37, 0), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2057, 7, 16, 9, 27, 37), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2057, 7, 23, 10, 8, 20), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2057, 7, 31, 14, 31, 23), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2057, 8, 8, 1, 29, 40), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2057, 8, 14, 17, 20, 46), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2057, 8, 22, 2, 1, 19), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2057, 8, 30, 3, 54, 3), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2057, 9, 6, 7, 17, 54), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2057, 9, 13, 2, 52, 45), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2057, 9, 20, 20, 25, 9), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2057, 9, 28, 15, 59, 42), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2057, 10, 5, 13, 13, 0), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2057, 10, 12, 15, 0, 58), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2057, 10, 20, 16, 8, 36), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2057, 10, 28, 3, 18, 35), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2057, 11, 3, 20, 23, 43), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2057, 11, 11, 6, 23, 46), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2057, 11, 19, 11, 31, 5), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2057, 11, 26, 14, 21, 51), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2057, 12, 3, 5, 53, 13), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2057, 12, 11, 0, 45, 38), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2057, 12, 19, 5, 1, 19), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2057, 12, 26, 1, 21, 50), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2058, 1, 1, 18, 29, 40), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2058, 1, 9, 20, 38, 10), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2058, 1, 17, 19, 42, 35), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2058, 1, 24, 12, 13, 37), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2058, 1, 31, 10, 27, 55), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2058, 2, 8, 15, 53, 40), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2058, 2, 16, 7, 15, 49), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2058, 2, 22, 22, 56, 5), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2058, 3, 2, 5, 9, 29), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2058, 3, 10, 8, 51, 57), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2058, 3, 17, 15, 55, 36), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2058, 3, 24, 9, 49, 10), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2058, 4, 1, 1, 2, 39), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2058, 4, 8, 22, 54, 43), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2058, 4, 15, 22, 26, 36), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2058, 4, 22, 21, 28, 26), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2058, 4, 30, 20, 17, 28), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2058, 5, 8, 10, 11, 40), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2058, 5, 15, 3, 57, 24), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2058, 5, 22, 10, 22, 39), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2058, 5, 30, 13, 32, 21), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2058, 6, 6, 19, 14, 48), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2058, 6, 13, 9, 50, 1), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2058, 6, 21, 0, 34, 21), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2058, 6, 29, 4, 12, 36), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2058, 7, 6, 2, 46, 6), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2058, 7, 12, 17, 27, 33), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2058, 7, 20, 15, 39, 14), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2058, 7, 28, 16, 18, 48), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2058, 8, 4, 9, 37, 12), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2058, 8, 11, 3, 59, 55), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2058, 8, 19, 7, 2, 44), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2058, 8, 27, 2, 9, 24), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2058, 9, 2, 16, 50, 41), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2058, 9, 9, 18, 6, 37), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2058, 9, 17, 22, 17, 2), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2058, 9, 25, 10, 13, 49), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2058, 10, 2, 1, 35, 39), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2058, 10, 9, 11, 40, 54), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2058, 10, 17, 13, 4, 20), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2058, 10, 24, 17, 15, 34), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2058, 10, 31, 12, 53, 48), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2058, 11, 8, 7, 46, 26), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2058, 11, 16, 3, 8, 46), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2058, 11, 23, 0, 15, 41), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2058, 11, 30, 3, 16, 35), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2058, 12, 8, 4, 50, 55), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2058, 12, 15, 16, 11, 11), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2058, 12, 22, 8, 26, 8), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2058, 12, 29, 20, 24, 49), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2059, 1, 7, 1, 6, 3), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2059, 1, 14, 3, 56, 31), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2059, 1, 20, 18, 50, 5), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2059, 1, 28, 15, 10, 40), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2059, 2, 5, 18, 48, 27), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2059, 2, 12, 14, 26, 42), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2059, 2, 19, 7, 56, 50), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2059, 2, 27, 10, 5, 12), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2059, 3, 7, 8, 46, 35), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2059, 3, 14, 0, 4, 57), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2059, 3, 20, 23, 29, 19), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2059, 3, 29, 3, 47, 5), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2059, 4, 5, 18, 45, 29), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2059, 4, 12, 9, 28, 6), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2059, 4, 19, 16, 36, 53), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2059, 4, 27, 19, 16, 37), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2059, 5, 5, 1, 28, 52), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2059, 5, 11, 19, 14, 37), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2059, 5, 19, 10, 21, 50), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2059, 5, 27, 8, 3, 14), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2059, 6, 3, 6, 19, 8), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2059, 6, 10, 5, 56, 27), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2059, 6, 18, 3, 54, 57), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2059, 6, 25, 18, 12, 0), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2059, 7, 2, 10, 52, 45), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2059, 7, 9, 17, 58, 0), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2059, 7, 17, 20, 34, 2), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2059, 7, 25, 2, 23, 12), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2059, 7, 31, 16, 42, 21), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2059, 8, 8, 7, 37, 6), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2059, 8, 16, 11, 40, 11), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2059, 8, 23, 9, 41, 19), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2059, 8, 30, 1, 4, 54), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2059, 9, 6, 23, 0, 13), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2059, 9, 15, 0, 43, 56), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2059, 9, 21, 17, 18, 0), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2059, 9, 28, 12, 53, 32), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2059, 10, 6, 15, 49, 11), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2059, 10, 14, 11, 38, 4), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2059, 10, 21, 2, 14, 36), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2059, 10, 28, 4, 31, 5), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2059, 11, 5, 9, 10, 46), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2059, 11, 12, 20, 44, 33), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2059, 11, 19, 13, 8, 44), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2059, 11, 26, 23, 41, 32), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2059, 12, 5, 1, 48, 39), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2059, 12, 12, 4, 49, 55), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2059, 12, 19, 2, 11, 2), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2059, 12, 26, 21, 16, 54), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2060, 1, 3, 16, 39, 36), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2060, 1, 10, 12, 51, 34), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2060, 1, 17, 17, 13, 18), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2060, 1, 25, 19, 13, 32), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2060, 2, 2, 5, 21, 54), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2060, 2, 8, 21, 40, 54), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2060, 2, 16, 9, 55, 48), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2060, 2, 24, 15, 5, 26), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2060, 3, 2, 16, 11, 0), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2060, 3, 9, 7, 51, 50), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2060, 3, 17, 3, 40, 22), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2060, 3, 25, 7, 7, 29), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2060, 4, 1, 1, 37, 0), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2060, 4, 7, 19, 41, 44), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2060, 4, 15, 21, 21, 8), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2060, 4, 23, 18, 52, 54), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2060, 4, 30, 10, 10, 11), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2060, 5, 7, 9, 19, 1), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2060, 5, 15, 13, 38, 40), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2060, 5, 23, 3, 0, 42), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2060, 5, 29, 18, 22, 48), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2060, 6, 6, 0, 43, 37), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2060, 6, 14, 3, 36, 35), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2060, 6, 21, 8, 43, 19), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2060, 6, 28, 2, 57, 18), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2060, 7, 5, 17, 37, 16), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2060, 7, 13, 15, 7, 50), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2060, 7, 20, 13, 23, 7), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2060, 7, 27, 12, 49, 0), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2060, 8, 4, 11, 15, 25), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2060, 8, 12, 0, 50, 26), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2060, 8, 18, 18, 22, 11), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2060, 8, 26, 0, 55, 38), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2060, 9, 3, 4, 35, 39), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2060, 9, 10, 9, 43, 16), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2060, 9, 17, 0, 59, 41), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2060, 9, 24, 15, 52, 56), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2060, 10, 2, 20, 40, 41), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2060, 10, 9, 18, 40, 34), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2060, 10, 16, 10, 29, 8), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2060, 10, 24, 9, 25, 2), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2060, 11, 1, 10, 55, 54), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2060, 11, 8, 4, 16, 49), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2060, 11, 14, 23, 48, 9), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2060, 11, 23, 4, 15, 17), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2060, 11, 30, 23, 10, 1), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2060, 12, 7, 14, 47, 44), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2060, 12, 14, 17, 14, 39), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2060, 12, 22, 22, 38, 59), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2060, 12, 30, 9, 28, 0), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2061, 1, 6, 2, 24, 1), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2061, 1, 13, 13, 57, 4), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2061, 1, 21, 15, 15, 35), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2061, 1, 28, 18, 9, 15), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2061, 2, 4, 15, 21, 48), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2061, 2, 12, 11, 51, 14), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2061, 2, 20, 5, 30, 48), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2061, 2, 27, 1, 50, 44), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2061, 3, 6, 5, 53, 47), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2061, 3, 14, 8, 30, 2), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2061, 3, 21, 17, 22, 34), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2061, 3, 28, 9, 25, 32), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2061, 4, 4, 21, 46, 42), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2061, 4, 13, 2, 9, 4), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2061, 4, 20, 3, 4, 6), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2061, 4, 26, 17, 54, 9), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2061, 5, 4, 14, 12, 22), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2061, 5, 12, 16, 9, 33), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2061, 5, 19, 11, 2, 10), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2061, 5, 26, 4, 11, 30), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2061, 6, 3, 6, 8, 36), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2061, 6, 11, 2, 41, 32), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2061, 6, 17, 18, 2, 29), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2061, 6, 24, 16, 53, 8), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2061, 7, 2, 20, 51, 40), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2061, 7, 10, 10, 22, 44), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2061, 7, 17, 1, 9, 47), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2061, 7, 24, 8, 4, 13), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2061, 8, 1, 10, 10, 23), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2061, 8, 8, 16, 8, 51), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2061, 8, 15, 9, 38, 46), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2061, 8, 23, 1, 17, 41), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2061, 8, 30, 22, 17, 13), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2061, 9, 6, 21, 11, 45), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2061, 9, 13, 20, 36, 45), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2061, 9, 21, 19, 44, 8), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2061, 9, 29, 9, 31, 49), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2061, 10, 6, 2, 56, 34), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2061, 10, 13, 10, 40, 52), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2061, 10, 21, 14, 23, 48), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2061, 10, 28, 20, 11, 24), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2061, 11, 4, 10, 52, 14), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2061, 11, 12, 3, 39, 18), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2061, 11, 20, 8, 10, 59), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2061, 11, 27, 6, 31, 44), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2061, 12, 3, 22, 11, 7), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2061, 12, 11, 22, 31, 56), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2061, 12, 19, 23, 57, 33), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2061, 12, 26, 16, 52, 12), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2062, 1, 2, 13, 20, 23), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2062, 1, 10, 17, 51, 49), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2062, 1, 18, 12, 50, 24), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2062, 1, 25, 3, 36, 45), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2062, 2, 1, 7, 42, 40), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2062, 2, 9, 12, 9, 58), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2062, 2, 16, 22, 37, 27), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2062, 2, 23, 15, 7, 21), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2062, 3, 3, 3, 48, 25), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2062, 3, 11, 4, 12, 31), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2062, 3, 18, 5, 57, 9), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2062, 3, 25, 3, 34, 57), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2062, 4, 1, 23, 54, 14), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2062, 4, 9, 17, 16, 25), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2062, 4, 16, 12, 2, 50), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2062, 4, 23, 16, 56, 41), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2062, 5, 1, 18, 33, 2), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2062, 5, 9, 3, 22, 3), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2062, 5, 15, 18, 17, 6), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2062, 5, 23, 7, 2, 28), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2062, 5, 31, 10, 43, 39), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2062, 6, 7, 11, 11, 24), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2062, 6, 14, 1, 52, 33), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2062, 6, 21, 21, 42, 41), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2062, 6, 29, 23, 53, 30), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2062, 7, 6, 17, 52, 17), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2062, 7, 13, 11, 42, 38), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2062, 7, 21, 12, 46, 44), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2062, 7, 29, 10, 3, 21), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2062, 8, 5, 0, 39, 49), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2062, 8, 12, 0, 20, 25), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2062, 8, 20, 3, 54, 59), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2062, 8, 27, 17, 48, 12), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2062, 9, 3, 8, 41, 45), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2062, 9, 10, 15, 59, 6), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2062, 9, 18, 18, 35, 56), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2062, 9, 26, 0, 10, 25), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2062, 10, 2, 18, 49, 4), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2062, 10, 10, 10, 26, 28), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2062, 10, 18, 8, 17, 49), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2062, 10, 25, 6, 27, 36), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2062, 11, 1, 7, 32, 10), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2062, 11, 9, 6, 49, 58), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2062, 11, 16, 20, 47, 24), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2062, 11, 23, 13, 57, 19), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2062, 11, 30, 23, 0, 18), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2062, 12, 9, 3, 28, 2), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2062, 12, 16, 8, 16, 56), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2062, 12, 22, 23, 39, 53), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2062, 12, 30, 16, 56, 18), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2063, 1, 7, 22, 15, 5), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2063, 1, 14, 19, 11, 9), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2063, 1, 21, 12, 4, 18), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2063, 1, 29, 12, 22, 27), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2063, 2, 6, 13, 36, 29), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2063, 2, 13, 5, 48, 5), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2063, 2, 20, 3, 6, 5), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2063, 2, 28, 7, 37, 6), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2063, 3, 8, 1, 5, 41), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2063, 3, 14, 16, 13, 37), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2063, 3, 21, 20, 15, 8), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2063, 3, 30, 0, 48, 55), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2063, 4, 6, 9, 17, 21), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2063, 4, 13, 2, 33, 53), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2063, 4, 20, 14, 41, 11), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2063, 4, 28, 14, 51, 23), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2063, 5, 5, 15, 19, 2), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2063, 5, 12, 13, 10, 39), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2063, 5, 20, 9, 15, 30), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2063, 5, 28, 1, 46, 24), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2063, 6, 3, 20, 27, 54), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2063, 6, 11, 0, 42, 18), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2063, 6, 19, 2, 42, 32), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2063, 6, 26, 10, 24, 56), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2063, 7, 3, 2, 0, 11), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2063, 7, 10, 13, 47, 15), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2063, 7, 18, 18, 4, 30), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2063, 7, 25, 17, 54, 31), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2063, 8, 1, 9, 9, 4), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2063, 8, 9, 4, 39, 26), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2063, 8, 17, 7, 0, 12), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2063, 8, 24, 1, 16, 57), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2063, 8, 30, 19, 3, 21), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2063, 9, 7, 20, 52, 8), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2063, 9, 15, 17, 43, 29), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2063, 9, 22, 9, 20, 52), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2063, 9, 29, 8, 38, 19), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2063, 10, 7, 13, 26, 12), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2063, 10, 15, 2, 48, 26), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2063, 10, 21, 18, 45, 42), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2063, 10, 29, 2, 12, 40), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2063, 11, 6, 5, 21, 39), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2063, 11, 13, 10, 56, 1), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2063, 11, 20, 6, 8, 50), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2063, 11, 27, 22, 58, 39), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2063, 12, 5, 20, 5, 25), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2063, 12, 12, 18, 49, 8), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2063, 12, 19, 20, 3, 13), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2063, 12, 27, 20, 56, 50), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2064, 1, 4, 9, 30, 34), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2064, 1, 11, 3, 13, 22), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2064, 1, 18, 12, 36, 15), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2064, 1, 26, 17, 41, 16), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2064, 2, 2, 21, 36, 22), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2064, 2, 9, 12, 55, 2), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2064, 2, 17, 7, 2, 17), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2064, 2, 25, 11, 22, 17), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2064, 3, 3, 8, 18, 18), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2064, 3, 10, 0, 32, 45), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2064, 3, 18, 1, 44, 26), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2064, 3, 26, 1, 12, 29), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2064, 4, 1, 17, 39, 33), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2064, 4, 8, 14, 24, 19), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2064, 4, 16, 19, 1, 0), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2064, 4, 24, 11, 16, 36), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2064, 5, 1, 2, 7, 27), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2064, 5, 8, 6, 15, 54), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2064, 5, 16, 9, 54, 24), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2064, 5, 23, 18, 14, 56), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2064, 5, 30, 10, 35, 21), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2064, 6, 6, 23, 22, 59), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2064, 6, 14, 22, 19, 58), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2064, 6, 21, 23, 13, 2), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2064, 6, 28, 20, 8, 1), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2064, 7, 6, 16, 47, 38), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2064, 7, 14, 8, 45, 13), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2064, 7, 21, 3, 34, 42), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2064, 7, 28, 7, 39, 43), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2064, 8, 5, 9, 40, 22), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2064, 8, 12, 17, 48, 29), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2064, 8, 19, 8, 54, 8), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2064, 8, 26, 21, 34, 25), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2064, 9, 4, 1, 28, 33), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2064, 9, 11, 2, 10, 10), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2064, 9, 17, 16, 44, 49), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2064, 9, 25, 13, 37, 40), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2064, 10, 3, 15, 49, 33), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2064, 10, 10, 10, 33, 14), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2064, 10, 17, 4, 21, 18), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2064, 10, 25, 7, 4, 55), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2064, 11, 2, 4, 23, 32), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2064, 11, 8, 19, 44, 15), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2064, 11, 15, 20, 13, 17), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2064, 11, 24, 0, 57, 36), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2064, 12, 1, 14, 59, 10), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2064, 12, 8, 6, 28, 5), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2064, 12, 15, 15, 44, 31), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2064, 12, 23, 18, 13, 33), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2064, 12, 30, 23, 49, 7), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2065, 1, 6, 19, 14, 17), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2065, 1, 14, 13, 18, 10), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2065, 1, 22, 9, 52, 48), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2065, 1, 29, 7, 37, 35), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2065, 2, 5, 10, 1, 31), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2065, 2, 13, 10, 50, 4), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2065, 2, 20, 23, 10, 36), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2065, 2, 27, 15, 28, 43), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2065, 3, 7, 2, 14, 27), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2065, 3, 15, 6, 24, 23), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2065, 3, 22, 9, 55, 36), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2065, 3, 29, 0, 23, 36), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2065, 4, 5, 19, 0, 16), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2065, 4, 13, 22, 37, 6), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2065, 4, 20, 18, 35, 22), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2065, 4, 27, 11, 1, 53), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2065, 5, 5, 11, 29, 33), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2065, 5, 13, 10, 51, 41), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2065, 5, 20, 2, 4, 37), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2065, 5, 26, 23, 37, 42), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2065, 6, 4, 3, 4, 2), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2065, 6, 11, 19, 24, 32), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2065, 6, 18, 9, 27, 40), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2065, 6, 25, 14, 7, 57), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2065, 7, 3, 17, 15, 0), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2065, 7, 11, 1, 15, 49), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2065, 7, 17, 17, 44, 42), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2065, 7, 25, 6, 21, 2), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2065, 8, 2, 5, 45, 27), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2065, 8, 9, 5, 51, 58), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2065, 8, 16, 3, 44, 54), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2065, 8, 23, 23, 55, 23), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2065, 8, 31, 16, 38, 40), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2065, 9, 7, 10, 48, 45), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2065, 9, 14, 16, 4, 24), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2065, 9, 22, 18, 8, 33), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2065, 9, 30, 2, 23, 36), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2065, 10, 6, 17, 36, 37), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2065, 10, 14, 7, 3, 43), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2065, 10, 22, 11, 52, 30), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2065, 10, 29, 11, 47, 47), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2065, 11, 5, 3, 25, 58), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2065, 11, 13, 0, 36, 41), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2065, 11, 21, 3, 50, 47), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2065, 11, 27, 21, 39, 12), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2065, 12, 4, 16, 53, 44), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2065, 12, 12, 19, 51, 30), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2065, 12, 20, 17, 11, 16), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2065, 12, 27, 8, 26, 44), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2066, 1, 3, 9, 55, 32), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2066, 1, 11, 15, 6, 40), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2066, 1, 19, 3, 47, 13), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2066, 1, 25, 20, 13, 33), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2066, 2, 2, 5, 43, 35), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2066, 2, 10, 8, 28, 13), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2066, 2, 17, 12, 13, 2), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2066, 2, 24, 8, 50, 1), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2066, 3, 4, 2, 47, 37), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2066, 3, 11, 22, 47, 16), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2066, 3, 18, 19, 24, 54), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2066, 3, 25, 22, 12, 57), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2066, 4, 2, 23, 8, 38), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2066, 4, 10, 10, 2, 25), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2066, 4, 17, 2, 22, 25), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2066, 4, 24, 12, 28, 54), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2066, 5, 2, 16, 56, 43), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2066, 5, 9, 18, 57, 36), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2066, 5, 16, 10, 0, 30), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2066, 5, 24, 3, 37, 39), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2066, 6, 1, 7, 12, 33), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2066, 6, 8, 2, 30, 12), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2066, 6, 14, 19, 9, 56), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2066, 6, 22, 19, 14, 34), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2066, 6, 30, 17, 58, 41), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2066, 7, 7, 9, 34, 5), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2066, 7, 14, 6, 37, 42), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2066, 7, 22, 10, 33, 8), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2066, 7, 30, 2, 0, 56), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2066, 8, 5, 16, 58, 56), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2066, 8, 12, 20, 58, 31), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2066, 8, 21, 0, 49, 16), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2066, 8, 28, 8, 24, 26), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2066, 9, 4, 1, 36, 39), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2066, 9, 11, 14, 15, 44), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2066, 9, 19, 13, 46, 47), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2066, 9, 26, 14, 18, 41), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2066, 10, 3, 12, 24, 13), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2066, 10, 11, 9, 42, 22), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2066, 10, 19, 1, 42, 1), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2066, 10, 25, 20, 51, 58), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2066, 11, 2, 2, 12, 20), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2066, 11, 10, 5, 44, 55), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2066, 11, 17, 13, 5, 25), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2066, 11, 24, 5, 9, 8), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2066, 12, 1, 19, 15, 37), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2066, 12, 10, 0, 37, 37), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2066, 12, 17, 0, 16, 59), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2066, 12, 23, 16, 6, 6), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2066, 12, 31, 14, 40, 11), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2067, 1, 8, 17, 0, 21), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2067, 1, 15, 11, 16, 20), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2067, 1, 22, 6, 16, 10), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2067, 1, 30, 10, 29, 12), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2067, 2, 7, 6, 13, 28), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2067, 2, 13, 21, 56, 49), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2067, 2, 20, 23, 29, 56), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2067, 3, 1, 4, 41, 18), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2067, 3, 8, 16, 15, 12), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2067, 3, 15, 8, 28, 17), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2067, 3, 22, 18, 43, 20), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2067, 3, 30, 20, 7, 48), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2067, 4, 6, 23, 37, 1), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2067, 4, 13, 19, 23, 8), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2067, 4, 21, 14, 14, 42), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2067, 4, 29, 8, 39, 28), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2067, 5, 6, 5, 18, 26), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2067, 5, 13, 7, 20, 5), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2067, 5, 21, 8, 28, 18), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2067, 5, 28, 18, 41, 14), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2067, 6, 4, 10, 38, 2), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2067, 6, 11, 20, 40, 20), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2067, 6, 20, 0, 28, 1), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2067, 6, 27, 2, 51, 47), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2067, 7, 3, 17, 1, 58), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2067, 7, 11, 11, 15, 36), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2067, 7, 19, 13, 58, 35), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2067, 7, 26, 9, 58, 0), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2067, 8, 2, 1, 50, 51), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2067, 8, 10, 2, 36, 1), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2067, 8, 18, 1, 8, 48), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2067, 8, 24, 16, 56, 39), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2067, 8, 31, 14, 3, 49), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2067, 9, 8, 18, 8, 19), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2067, 9, 16, 10, 19, 16), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2067, 9, 23, 0, 53, 37), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2067, 9, 30, 6, 0, 11), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2067, 10, 8, 9, 27, 24), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2067, 10, 15, 18, 2, 5), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2067, 10, 22, 10, 55, 9), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2067, 10, 30, 1, 7, 17), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2067, 11, 7, 0, 13, 26), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2067, 11, 14, 1, 6, 22), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2067, 11, 20, 23, 49, 1), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2067, 11, 28, 22, 5, 54), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2067, 12, 6, 14, 4, 13), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2067, 12, 13, 8, 37, 57), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2067, 12, 20, 15, 40, 58), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2067, 12, 28, 19, 9, 34), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2068, 1, 5, 2, 37, 29), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2068, 1, 11, 17, 46, 8), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2068, 1, 19, 9, 44, 20), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2068, 1, 27, 14, 26, 37), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2068, 2, 3, 13, 43, 56), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2068, 2, 10, 5, 19, 35), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2068, 2, 18, 4, 37, 30), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2068, 2, 26, 6, 24, 55), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2068, 3, 3, 23, 37, 9), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2068, 3, 10, 19, 25, 55), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2068, 3, 18, 22, 55, 11), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2068, 3, 26, 18, 20, 3), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2068, 4, 2, 8, 50, 42), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2068, 4, 9, 11, 32, 10), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2068, 4, 17, 15, 28, 44), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2068, 4, 25, 2, 29, 18), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2068, 5, 1, 18, 6, 18), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2068, 5, 9, 4, 46, 34), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2068, 5, 17, 5, 34, 14), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2068, 5, 24, 7, 59, 55), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2068, 5, 31, 4, 2, 40), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2068, 6, 7, 22, 20, 1), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2068, 6, 15, 16, 59, 14), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2068, 6, 22, 12, 24, 47), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2068, 6, 29, 15, 10, 44), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2068, 7, 7, 15, 30, 35), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2068, 7, 15, 2, 6, 50), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2068, 7, 21, 17, 21, 6), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2068, 7, 29, 3, 54, 8), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2068, 8, 6, 7, 37, 50), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2068, 8, 13, 9, 50, 17), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2068, 8, 20, 0, 15, 52), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2068, 8, 27, 18, 27, 44), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2068, 9, 4, 22, 3, 19), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2068, 9, 11, 17, 18, 40), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2068, 9, 18, 10, 15, 51), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2068, 9, 26, 10, 47, 39), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2068, 10, 4, 10, 22, 27), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2068, 10, 11, 1, 38, 58), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2068, 10, 17, 23, 59, 58), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2068, 10, 26, 4, 16, 22), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2068, 11, 2, 20, 37, 29), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2068, 11, 9, 11, 39, 34), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2068, 11, 16, 17, 32, 7), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2068, 11, 24, 21, 41, 33), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2068, 12, 2, 5, 20, 9), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2068, 12, 8, 23, 41, 28), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2068, 12, 16, 14, 10, 20), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2068, 12, 24, 13, 43, 41), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2068, 12, 31, 13, 22, 6), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2069, 1, 7, 13, 42, 31), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2069, 1, 15, 12, 15, 55), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2069, 1, 23, 3, 35, 38), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2069, 1, 29, 21, 38, 40), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2069, 2, 6, 5, 28, 32), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2069, 2, 14, 9, 26, 32), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2069, 2, 21, 15, 16, 16), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2069, 2, 28, 6, 53, 39), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2069, 3, 7, 22, 34, 25), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2069, 3, 16, 3, 30, 0), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2069, 3, 23, 1, 12, 35), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2069, 3, 29, 17, 34, 3), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2069, 4, 6, 16, 12, 24), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2069, 4, 14, 17, 20, 38), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2069, 4, 21, 9, 57, 58), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2069, 4, 28, 5, 55, 46), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2069, 5, 6, 9, 10, 33), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2069, 5, 14, 3, 9, 13), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2069, 5, 20, 18, 5, 22), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2069, 5, 27, 20, 8, 22), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2069, 6, 5, 0, 18, 58), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2069, 6, 12, 9, 55, 26), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2069, 6, 19, 2, 13, 16), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2069, 6, 26, 12, 8, 55), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2069, 7, 4, 13, 4, 36), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2069, 7, 11, 14, 58, 18), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2069, 7, 18, 11, 12, 21), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2069, 7, 26, 5, 29, 49), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2069, 8, 2, 23, 43, 34), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2069, 8, 9, 19, 40, 15), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2069, 8, 16, 22, 2, 24), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2069, 8, 24, 23, 16, 14), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2069, 9, 1, 9, 5, 48), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2069, 9, 8, 1, 21, 41), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2069, 9, 15, 11, 34, 45), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2069, 9, 23, 16, 22, 35), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2069, 9, 30, 18, 8, 33), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2069, 10, 7, 9, 19, 6), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2069, 10, 15, 4, 2, 43), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2069, 10, 23, 7, 56, 43), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2069, 10, 30, 3, 34, 46), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2069, 11, 5, 20, 39, 22), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2069, 11, 13, 22, 37, 7), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2069, 11, 21, 21, 31, 5), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2069, 11, 28, 13, 45, 30), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2069, 12, 5, 12, 2, 57), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2069, 12, 13, 17, 37, 22), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2069, 12, 21, 8, 59, 8), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2069, 12, 28, 0, 49, 28), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2070, 1, 4, 7, 15, 2), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2070, 1, 12, 11, 21, 56), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2070, 1, 19, 18, 30, 33), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2070, 1, 26, 12, 58, 37), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2070, 2, 3, 4, 45, 3), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2070, 2, 11, 2, 51, 44), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2070, 2, 18, 2, 32, 52), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2070, 2, 25, 2, 30, 28), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2070, 3, 5, 2, 10, 29), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2070, 3, 12, 15, 51, 32), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2070, 3, 19, 9, 52, 55), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2070, 3, 26, 17, 30, 7), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2070, 4, 3, 21, 22, 6), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2070, 4, 11, 2, 29, 42), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2070, 4, 17, 17, 31, 7), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2070, 4, 25, 9, 30, 29), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2070, 5, 3, 13, 10, 45), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2070, 5, 10, 11, 7, 52), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2070, 5, 17, 2, 29, 50), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2070, 5, 25, 1, 37, 2), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2070, 6, 2, 1, 25, 51), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2070, 6, 8, 18, 23, 40), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2070, 6, 15, 13, 39, 35), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2070, 6, 23, 16, 56, 24), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2070, 7, 1, 10, 32, 39), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2070, 7, 8, 1, 14, 3), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2070, 7, 15, 3, 25, 41), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2070, 7, 23, 7, 1, 26), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2070, 7, 30, 17, 16, 56), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2070, 8, 6, 8, 50, 54), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2070, 8, 13, 19, 39, 57), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2070, 8, 21, 19, 53, 12), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2070, 8, 28, 22, 40, 48), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2070, 9, 4, 18, 28, 5), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2070, 9, 12, 13, 43, 38), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2070, 9, 20, 7, 46, 56), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2070, 9, 27, 4, 1, 39), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2070, 10, 4, 7, 0, 42), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2070, 10, 12, 8, 39, 16), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2070, 10, 19, 18, 58, 17), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2070, 10, 26, 10, 46, 55), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2070, 11, 2, 22, 42, 6), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2070, 11, 11, 3, 19, 44), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2070, 11, 18, 5, 39, 37), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2070, 11, 24, 20, 19, 10), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2070, 12, 2, 16, 53, 2), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2070, 12, 10, 20, 31, 53), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2070, 12, 17, 16, 5, 0), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2070, 12, 24, 9, 30, 23), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2071, 1, 1, 12, 14, 37), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2071, 1, 9, 11, 7, 40), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2071, 1, 16, 2, 34, 39), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2071, 1, 23, 2, 15, 32), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2071, 1, 31, 7, 15, 0), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2071, 2, 7, 22, 29, 52), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2071, 2, 14, 13, 32, 25), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2071, 2, 21, 21, 28, 35), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2071, 3, 2, 0, 30, 46), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2071, 3, 9, 6, 52, 44), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2071, 3, 16, 1, 17, 4), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2071, 3, 23, 17, 31, 43), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2071, 3, 31, 15, 2, 31), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2071, 4, 7, 13, 16, 39), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2071, 4, 14, 13, 55, 21), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2071, 4, 22, 12, 51, 53), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2071, 4, 30, 2, 29, 41), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2071, 5, 6, 19, 4, 20), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2071, 5, 14, 3, 23, 37), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2071, 5, 22, 6, 17, 59), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2071, 5, 29, 11, 16, 9), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2071, 6, 5, 1, 37, 21), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2071, 6, 12, 17, 35, 30), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2071, 6, 20, 21, 3, 45), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2071, 6, 27, 18, 20, 6), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2071, 7, 4, 10, 1, 55), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2071, 7, 12, 8, 24, 46), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2071, 7, 20, 8, 51, 31), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2071, 7, 27, 0, 55, 40), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2071, 8, 2, 21, 4, 0), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2071, 8, 10, 23, 38, 44), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2071, 8, 18, 17, 56, 51), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2071, 8, 25, 8, 15, 55), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2071, 9, 1, 11, 9, 9), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2071, 9, 9, 14, 50, 32), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2071, 9, 17, 1, 6, 36), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2071, 9, 23, 17, 20, 31), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2071, 10, 1, 4, 20, 16), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2071, 10, 9, 5, 22, 56), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2071, 10, 16, 7, 29, 41), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2071, 10, 23, 4, 48, 54), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2071, 10, 31, 0, 6, 8), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2071, 11, 7, 18, 46, 34), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2071, 11, 14, 14, 23, 19), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2071, 11, 21, 18, 59, 0), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2071, 11, 29, 21, 5, 58), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2071, 12, 7, 6, 56, 59), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2071, 12, 13, 22, 56, 33), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2071, 12, 21, 11, 46, 17), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2071, 12, 29, 17, 15, 53), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2072, 1, 5, 18, 12, 22), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2072, 1, 12, 9, 53, 38), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2072, 1, 20, 6, 34, 22), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2072, 1, 28, 10, 35, 22), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2072, 2, 4, 4, 54, 55), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2072, 2, 10, 23, 26, 8), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2072, 2, 19, 2, 2, 52), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2072, 2, 27, 0, 1, 25), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2072, 3, 4, 15, 16, 51), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2072, 3, 11, 15, 17, 50), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2072, 3, 19, 20, 20, 55), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2072, 3, 27, 9, 42, 15), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2072, 4, 3, 1, 24, 21), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2072, 4, 10, 8, 52, 50), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2072, 4, 18, 11, 56, 9), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2072, 4, 25, 16, 34, 4), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2072, 5, 2, 11, 33, 8), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2072, 5, 10, 3, 16, 43), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2072, 5, 18, 0, 17, 47), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2072, 5, 24, 21, 52, 32), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2072, 5, 31, 22, 17, 17), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2072, 6, 8, 21, 19, 29), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2072, 6, 16, 9, 56, 31), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2072, 6, 23, 2, 56, 12), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2072, 6, 30, 10, 20, 55), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2072, 7, 8, 13, 52, 53), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2072, 7, 15, 17, 55, 16), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2072, 7, 22, 9, 1, 5), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2072, 7, 30, 0, 15, 57), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2072, 8, 7, 4, 14, 37), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2072, 8, 14, 1, 20, 20), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2072, 8, 20, 17, 19, 58), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2072, 8, 28, 15, 58, 40), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2072, 9, 5, 16, 18, 48), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2072, 9, 12, 9, 6, 14), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2072, 9, 19, 4, 57, 56), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2072, 9, 27, 8, 42, 43), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2072, 10, 5, 2, 27, 25), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2072, 10, 11, 17, 54, 29), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2072, 10, 18, 20, 36, 13), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2072, 10, 27, 1, 20, 29), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2072, 11, 3, 11, 15, 19), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2072, 11, 10, 4, 20, 28), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2072, 11, 17, 16, 2, 45), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2072, 11, 25, 16, 58, 34), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2072, 12, 2, 19, 21, 11), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2072, 12, 9, 16, 58, 20), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2072, 12, 17, 13, 49, 19), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2072, 12, 25, 7, 14, 33), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2073, 1, 1, 3, 26, 50), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2073, 1, 8, 8, 10, 16), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2073, 1, 16, 11, 32, 17), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2073, 1, 23, 20, 4, 35), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2073, 1, 30, 12, 17, 56), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2073, 2, 7, 1, 39, 48), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2073, 2, 15, 6, 56, 13), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2073, 2, 22, 7, 25, 36), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2073, 2, 28, 22, 39, 9), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2073, 3, 8, 20, 14, 54), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2073, 3, 16, 22, 43, 5), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2073, 3, 23, 17, 16, 29), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2073, 3, 30, 11, 3, 3), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2073, 4, 7, 14, 13, 10), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2073, 4, 15, 10, 35, 24), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2073, 4, 22, 1, 53, 59), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2073, 4, 29, 1, 37, 1), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2073, 5, 7, 6, 14, 30), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2073, 5, 14, 18, 58, 34), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2073, 5, 21, 10, 1, 57), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2073, 5, 28, 17, 56, 17), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2073, 6, 5, 19, 50, 33), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2073, 6, 13, 0, 46, 46), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2073, 6, 19, 18, 43, 50), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2073, 6, 27, 11, 11, 19), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2073, 7, 5, 7, 15, 36), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2073, 7, 12, 5, 15, 16), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2073, 7, 19, 5, 3, 46), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2073, 7, 27, 4, 28, 8), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2073, 8, 3, 17, 3, 21), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2073, 8, 10, 9, 53, 57), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2073, 8, 17, 17, 44, 30), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2073, 8, 25, 21, 4, 37), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2073, 9, 2, 1, 51, 56), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2073, 9, 8, 16, 18, 26), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2073, 9, 16, 8, 51, 50), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2073, 9, 24, 12, 31, 3), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2073, 10, 1, 10, 20, 43), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2073, 10, 8, 1, 55, 46), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2073, 10, 16, 1, 53, 45), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2073, 10, 24, 2, 21, 50), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2073, 10, 30, 19, 12, 20), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2073, 11, 6, 15, 41, 17), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2073, 11, 14, 19, 54, 11), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2073, 11, 22, 14, 14, 55), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2073, 11, 29, 5, 11, 17), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2073, 12, 6, 9, 32, 7), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2073, 12, 14, 13, 48, 18), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2073, 12, 22, 0, 5, 17), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2073, 12, 28, 16, 54, 36), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2074, 1, 5, 6, 17, 34), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2074, 1, 13, 6, 30, 33), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2074, 1, 20, 8, 19, 44), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2074, 1, 27, 6, 36, 46), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2074, 2, 4, 4, 1, 47), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2074, 2, 11, 21, 4, 26), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2074, 2, 18, 15, 54, 52), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2074, 2, 25, 21, 59, 26), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2074, 3, 6, 0, 42, 21), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2074, 3, 13, 9, 0, 29), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2074, 3, 19, 23, 57, 25), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2074, 3, 27, 14, 18, 57), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2074, 4, 4, 18, 39, 18), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2074, 4, 11, 18, 29, 26), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2074, 4, 18, 9, 21, 50), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2074, 4, 26, 6, 46, 53), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2074, 5, 4, 8, 52, 51), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2074, 5, 11, 2, 17, 3), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2074, 5, 17, 20, 38, 30), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2074, 5, 25, 22, 43, 28), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2074, 6, 2, 19, 13, 46), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2074, 6, 9, 9, 27, 0), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2074, 6, 16, 9, 56, 9), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2074, 6, 24, 13, 37, 49), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2074, 7, 2, 2, 21, 27), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2074, 7, 8, 17, 4, 19), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2074, 7, 16, 1, 10, 45), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2074, 7, 24, 3, 6, 16), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2074, 7, 31, 7, 30, 1), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2074, 8, 7, 2, 5, 7), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2074, 8, 14, 18, 9, 2), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2074, 8, 22, 14, 58, 42), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2074, 8, 29, 12, 11, 10), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2074, 9, 5, 13, 12, 26), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2074, 9, 13, 12, 20, 29), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2074, 9, 21, 1, 28, 0), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2074, 9, 27, 17, 58, 47), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2074, 10, 5, 2, 54, 57), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2074, 10, 13, 6, 46, 42), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2074, 10, 20, 11, 10, 58), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2074, 10, 27, 2, 14, 14), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2074, 11, 3, 19, 21, 5), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2074, 11, 12, 0, 6, 50), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2074, 11, 18, 20, 55, 22), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2074, 11, 25, 13, 51, 29), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2074, 12, 3, 14, 2, 30), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2074, 12, 11, 15, 7, 12), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2074, 12, 18, 7, 19, 10), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2074, 12, 25, 5, 6, 5), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2075, 1, 2, 9, 38, 33), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2075, 1, 10, 3, 13, 40), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2075, 1, 16, 18, 36, 9), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2075, 1, 23, 23, 31, 28), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2075, 2, 1, 4, 12, 16), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2075, 2, 8, 12, 40, 42), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2075, 2, 15, 6, 40, 6), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2075, 2, 22, 19, 59, 19), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2075, 3, 2, 20, 5, 30), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2075, 3, 9, 20, 16, 41), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2075, 3, 16, 19, 24, 21), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2075, 3, 24, 16, 45, 8), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2075, 4, 1, 8, 44, 32), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2075, 4, 8, 3, 2, 41), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2075, 4, 15, 8, 54, 52), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2075, 4, 23, 11, 52, 40), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2075, 4, 30, 18, 36, 37), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2075, 5, 7, 9, 58, 37), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2075, 5, 14, 23, 21, 42), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2075, 5, 23, 3, 56, 6), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2075, 5, 30, 2, 37, 35), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2075, 6, 5, 18, 0, 1), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2075, 6, 13, 14, 38, 20), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2075, 6, 21, 16, 28, 10), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2075, 6, 28, 9, 45, 46), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2075, 7, 5, 3, 59, 20), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2075, 7, 13, 6, 10, 38), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2075, 7, 21, 1, 54, 44), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2075, 7, 27, 16, 53, 20), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2075, 8, 3, 16, 42, 19), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2075, 8, 11, 21, 10, 4), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2075, 8, 19, 9, 11, 33), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2075, 8, 26, 0, 50, 11), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2075, 9, 2, 8, 33, 16), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2075, 9, 10, 11, 1, 40), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2075, 9, 17, 15, 24, 42), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2075, 9, 24, 10, 30, 7), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2075, 10, 2, 3, 12, 36), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2075, 10, 9, 23, 42, 23), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2075, 10, 16, 21, 40, 59), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2075, 10, 23, 22, 48, 8), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2075, 10, 31, 23, 25, 14), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2075, 11, 8, 11, 34, 40), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2075, 11, 15, 5, 5, 4), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2075, 11, 22, 14, 19, 55), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2075, 11, 30, 19, 21, 21), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2075, 12, 7, 23, 2, 52), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2075, 12, 14, 14, 36, 39), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2075, 12, 22, 8, 47, 7), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2075, 12, 30, 13, 19, 35), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2076, 1, 6, 10, 13, 59), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2076, 1, 13, 3, 1, 28), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2076, 1, 21, 4, 39, 7), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2076, 1, 29, 4, 17, 3), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2076, 2, 4, 21, 0, 46), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2076, 2, 11, 18, 34, 13), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2076, 2, 19, 23, 48, 16), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2076, 2, 27, 15, 53, 25), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2076, 3, 5, 7, 23, 33), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2076, 3, 12, 12, 40, 51), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2076, 3, 20, 16, 36, 38), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2076, 3, 28, 0, 25, 10), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2076, 4, 3, 17, 46, 32), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2076, 4, 11, 7, 59, 3), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2076, 4, 19, 6, 28, 54), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2076, 4, 26, 6, 40, 8), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2076, 5, 3, 4, 51, 4), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2076, 5, 11, 2, 49, 33), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2076, 5, 18, 17, 37, 39), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2076, 5, 25, 11, 50, 12), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2076, 6, 1, 17, 13, 15), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2076, 6, 9, 19, 57, 19), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2076, 6, 17, 2, 37, 20), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2076, 6, 23, 17, 20, 53), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2076, 7, 1, 7, 3, 49), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2076, 7, 9, 10, 48, 36), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2076, 7, 16, 10, 11, 19), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2076, 7, 23, 0, 39, 16), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2076, 7, 30, 22, 4, 46), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2076, 8, 7, 23, 20, 37), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2076, 8, 14, 17, 11, 7), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2076, 8, 21, 10, 59, 39), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2076, 8, 29, 13, 42, 54), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2076, 9, 6, 9, 45, 44), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2076, 9, 13, 0, 37, 37), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2076, 9, 20, 1, 5, 13), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2076, 9, 28, 5, 26, 27), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2076, 10, 5, 18, 25, 48), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2076, 10, 12, 9, 36, 58), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2076, 10, 19, 18, 50, 5), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2076, 10, 27, 20, 49, 50), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2076, 11, 4, 1, 56, 59), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2076, 11, 10, 21, 6, 52), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2076, 11, 18, 15, 14, 23), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2076, 11, 26, 11, 27, 44), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2076, 12, 3, 9, 14, 31), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2076, 12, 10, 11, 34, 19), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2076, 12, 18, 12, 38, 59), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2076, 12, 26, 0, 52, 36), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2077, 1, 1, 17, 26, 40), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2077, 1, 9, 4, 37, 3), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2077, 1, 17, 9, 8, 58), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2077, 1, 24, 12, 44, 43), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2077, 1, 31, 3, 34, 57), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2077, 2, 7, 23, 7, 46), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2077, 2, 16, 2, 57, 55), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2077, 2, 22, 23, 6, 11), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2077, 3, 1, 16, 10, 5), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2077, 3, 9, 17, 42, 7), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2077, 3, 17, 16, 55, 25), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2077, 3, 24, 8, 23, 48), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2077, 3, 31, 7, 1, 10), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2077, 4, 8, 11, 5, 7), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2077, 4, 16, 2, 49, 19), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2077, 4, 22, 17, 19, 28), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2077, 4, 29, 23, 27, 44), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2077, 5, 8, 2, 22, 25), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2077, 5, 15, 9, 25, 30), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2077, 5, 22, 2, 37, 6), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2077, 5, 29, 16, 42, 46), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2077, 6, 6, 15, 6, 6), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2077, 6, 13, 14, 7, 27), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2077, 6, 20, 12, 54, 12), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2077, 6, 28, 10, 4, 46), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2077, 7, 6, 1, 21, 7), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2077, 7, 12, 18, 32, 41), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2077, 7, 20, 0, 40, 13), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2077, 7, 28, 2, 54, 27), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2077, 8, 4, 9, 45, 53), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2077, 8, 11, 0, 15, 30), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2077, 8, 18, 14, 16, 50), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2077, 8, 26, 18, 30, 10), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2077, 9, 2, 17, 22, 30), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2077, 9, 9, 8, 34, 52), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2077, 9, 17, 5, 52, 12), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2077, 9, 25, 8, 14, 38), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2077, 10, 2, 1, 19, 57), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2077, 10, 8, 20, 25, 6), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2077, 10, 16, 23, 6, 20), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2077, 10, 24, 19, 50, 31), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2077, 10, 31, 10, 35, 44), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2077, 11, 7, 12, 8, 11), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2077, 11, 15, 16, 59, 27), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2077, 11, 23, 5, 30, 40), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2077, 11, 29, 21, 42, 9), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2077, 12, 7, 7, 25, 5), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2077, 12, 15, 10, 5, 38), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2077, 12, 22, 13, 55, 16), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2077, 12, 29, 10, 44, 41), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2078, 1, 6, 5, 4, 6), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2078, 1, 14, 1, 13, 30), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2078, 1, 20, 21, 58, 22), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2078, 1, 28, 1, 32, 58), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2078, 2, 5, 2, 59, 34), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2078, 2, 12, 13, 58, 15), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2078, 2, 19, 6, 30, 52), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2078, 2, 26, 17, 49, 43), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2078, 3, 6, 22, 46, 55), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2078, 3, 14, 0, 36, 58), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2078, 3, 20, 16, 9, 42), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2078, 3, 28, 11, 3, 43), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2078, 4, 5, 14, 44, 31), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2078, 4, 12, 9, 44, 14), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2078, 4, 19, 3, 18, 45), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2078, 4, 27, 4, 18, 26), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2078, 5, 5, 2, 28, 15), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2078, 5, 11, 17, 55, 48), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2078, 5, 18, 16, 15, 4), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2078, 5, 26, 20, 22, 13), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2078, 6, 3, 10, 37, 48), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2078, 6, 10, 1, 48, 34), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2078, 6, 17, 7, 7, 55), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2078, 6, 25, 10, 21, 17), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2078, 7, 2, 16, 24, 50), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2078, 7, 9, 10, 8, 4), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2078, 7, 16, 23, 47, 16), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2078, 7, 24, 22, 6, 16), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2078, 7, 31, 21, 10, 29), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2078, 8, 7, 19, 51, 29), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2078, 8, 15, 17, 33, 10), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2078, 8, 23, 8, 10, 58), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2078, 8, 30, 2, 15, 51), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2078, 9, 6, 7, 58, 11), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2078, 9, 14, 11, 21, 28), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2078, 9, 21, 17, 30, 12), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2078, 9, 28, 8, 59, 15), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2078, 10, 5, 23, 5, 8), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2078, 10, 14, 4, 6, 49), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2078, 10, 21, 2, 54, 1), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2078, 10, 27, 18, 33, 10), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2078, 11, 4, 16, 55, 30), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2078, 11, 12, 19, 3, 53), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2078, 11, 19, 12, 51, 47), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2078, 11, 26, 7, 53, 22), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2078, 12, 4, 12, 7, 37), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2078, 12, 12, 7, 51, 35), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2078, 12, 18, 23, 33, 32), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2078, 12, 26, 1, 15, 15), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2079, 1, 3, 6, 49, 28), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2079, 1, 10, 18, 28, 15), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2079, 1, 17, 11, 5, 58), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2079, 1, 24, 21, 45, 49), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2079, 2, 1, 23, 34, 55), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2079, 2, 9, 3, 10, 42), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2079, 2, 15, 23, 44, 26), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2079, 2, 23, 19, 22, 58), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2079, 3, 3, 13, 48, 8), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2079, 3, 10, 10, 36, 55), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2079, 3, 17, 13, 45, 2), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2079, 3, 25, 15, 45, 23), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2079, 4, 2, 1, 29, 21), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2079, 4, 8, 17, 43, 39), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2079, 4, 16, 5, 2, 7), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2079, 4, 24, 9, 14, 27), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2079, 5, 1, 10, 56, 19), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2079, 5, 8, 1, 36, 47), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2079, 5, 15, 20, 57, 42), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2079, 5, 23, 23, 14, 32), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2079, 5, 30, 18, 40, 27), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2079, 6, 6, 11, 17, 58), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2079, 6, 14, 12, 38, 11), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2079, 6, 22, 9, 55, 40), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2079, 6, 29, 1, 30, 34), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2079, 7, 5, 23, 30, 33), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2079, 7, 14, 3, 23, 9), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2079, 7, 21, 17, 53, 9), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2079, 7, 28, 8, 32, 43), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2079, 8, 4, 14, 27, 10), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2079, 8, 12, 16, 59, 34), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2079, 8, 19, 23, 59, 0), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2079, 8, 26, 17, 1, 53), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2079, 9, 3, 7, 45, 18), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2079, 9, 11, 5, 35, 3), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2079, 9, 18, 5, 21, 0), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2079, 9, 25, 4, 5, 13), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2079, 10, 3, 2, 34, 38), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2079, 10, 10, 17, 22, 55), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2079, 10, 17, 11, 20, 58), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2079, 10, 24, 18, 18, 53), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2079, 11, 1, 21, 49, 0), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2079, 11, 9, 4, 33, 22), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2079, 11, 15, 19, 25, 4), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2079, 11, 23, 11, 28, 41), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2079, 12, 1, 16, 12, 49), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2079, 12, 8, 15, 15, 35), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2079, 12, 15, 6, 42, 53), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2079, 12, 23, 6, 30, 48), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2079, 12, 31, 8, 28, 15), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2080, 1, 7, 1, 44, 10), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2080, 1, 13, 21, 39, 14), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2080, 1, 22, 1, 54, 55), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2080, 1, 29, 21, 36, 10), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2080, 2, 5, 12, 20, 57), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2080, 2, 12, 15, 36, 57), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2080, 2, 20, 20, 10, 41), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2080, 2, 28, 7, 23, 32), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2080, 3, 5, 23, 28, 57), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2080, 3, 13, 11, 11, 42), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2080, 3, 21, 12, 5, 24), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2080, 3, 28, 14, 31, 4), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2080, 4, 4, 11, 23, 36), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2080, 4, 12, 6, 48, 58), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2080, 4, 20, 0, 59, 12), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2080, 4, 26, 20, 15, 20), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2080, 5, 4, 0, 9, 27), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2080, 5, 12, 1, 10, 20), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2080, 5, 19, 10, 55, 53), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2080, 5, 26, 2, 2, 55), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2080, 6, 2, 13, 45, 3), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2080, 6, 10, 17, 19, 36), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2080, 6, 17, 18, 39, 50), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2080, 6, 24, 9, 11, 23), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2080, 7, 2, 4, 8, 12), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2080, 7, 10, 6, 44, 11), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2080, 7, 17, 1, 20, 18), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2080, 7, 23, 18, 39, 54), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2080, 7, 31, 19, 12, 58), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2080, 8, 8, 17, 20, 27), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2080, 8, 15, 8, 12, 47), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2080, 8, 22, 7, 6, 39), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2080, 8, 30, 10, 40, 5), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2080, 9, 7, 1, 36, 51), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2080, 9, 13, 16, 24, 15), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2080, 9, 20, 22, 47, 50), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2080, 9, 29, 1, 53, 18), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2080, 10, 6, 8, 29, 15), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2080, 10, 13, 2, 43, 17), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2080, 10, 20, 17, 30, 55), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2080, 10, 28, 16, 12, 0), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2080, 11, 4, 15, 9, 45), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2080, 11, 11, 15, 36, 26), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2080, 11, 19, 14, 18, 58), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2080, 11, 27, 5, 13, 22), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2080, 12, 3, 22, 51, 29), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2080, 12, 11, 7, 9, 0), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2080, 12, 19, 11, 22, 34), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2080, 12, 26, 17, 2, 37), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2081, 1, 2, 8, 31, 17), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2081, 1, 10, 1, 1, 24), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2081, 1, 18, 6, 28, 25), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2081, 1, 25, 4, 0, 59), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2081, 1, 31, 20, 36, 42), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2081, 2, 8, 20, 16, 25), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2081, 2, 16, 21, 58, 18), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2081, 2, 23, 14, 26, 36), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2081, 3, 2, 11, 5, 34), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2081, 3, 10, 15, 16, 3), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2081, 3, 18, 9, 26, 39), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2081, 3, 25, 0, 28, 46), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2081, 4, 1, 3, 34, 36), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2081, 4, 9, 8, 14, 10), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2081, 4, 16, 17, 30, 42), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2081, 4, 23, 10, 19, 11), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2081, 4, 30, 21, 23, 47), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2081, 5, 8, 22, 8, 6), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2081, 5, 15, 23, 20, 26), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2081, 5, 22, 20, 25, 33), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2081, 5, 30, 15, 34, 58), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2081, 6, 7, 9, 0, 57), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2081, 6, 14, 4, 15, 15), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2081, 6, 21, 7, 31, 47), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2081, 6, 29, 8, 59, 18), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2081, 7, 6, 17, 43, 36), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2081, 7, 13, 9, 33, 50), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2081, 7, 20, 20, 21, 50), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2081, 7, 29, 0, 39, 0), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2081, 8, 5, 1, 23, 19), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2081, 8, 11, 16, 32, 3), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2081, 8, 19, 11, 14, 36), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2081, 8, 27, 14, 7, 14), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2081, 9, 3, 9, 0, 57), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2081, 9, 10, 2, 21, 5), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2081, 9, 18, 3, 44, 59), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2081, 9, 26, 1, 29, 42), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2081, 10, 2, 17, 22, 38), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2081, 10, 9, 15, 57, 40), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2081, 10, 17, 20, 49, 25), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2081, 10, 25, 11, 12, 10), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2081, 11, 1, 3, 3, 26), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2081, 11, 8, 9, 40, 0), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2081, 11, 16, 13, 18, 46), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2081, 11, 23, 19, 48, 3), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2081, 11, 30, 14, 35, 33), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2081, 12, 8, 6, 37, 10), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2081, 12, 16, 4, 30, 16), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2081, 12, 23, 3, 54, 37), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2081, 12, 30, 4, 27, 41), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2082, 1, 7, 4, 44, 51), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2082, 1, 14, 18, 10, 18), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2082, 1, 21, 12, 14, 30), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2082, 1, 28, 20, 45, 56), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2082, 2, 6, 1, 33, 45), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2082, 2, 13, 6, 15, 53), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2082, 2, 19, 21, 34, 21), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2082, 2, 27, 14, 47, 53), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2082, 3, 7, 19, 14, 23), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2082, 3, 14, 16, 44, 37), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2082, 3, 21, 8, 36, 20), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2082, 3, 29, 9, 4, 15), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2082, 4, 6, 9, 1, 54), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2082, 4, 13, 1, 44, 45), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2082, 4, 19, 21, 45, 18), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2082, 4, 28, 2, 1, 28), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2082, 5, 5, 19, 3, 32), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2082, 5, 12, 9, 48, 54), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2082, 5, 19, 12, 57, 27), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2082, 5, 27, 16, 46, 31), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2082, 6, 4, 2, 0, 42), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2082, 6, 10, 17, 54, 29), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2082, 6, 18, 5, 38, 42), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2082, 6, 26, 5, 15, 18), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2082, 7, 3, 6, 58, 48), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2082, 7, 10, 3, 9, 39), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2082, 7, 17, 22, 58, 3), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2082, 7, 25, 15, 53, 41), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2082, 8, 1, 11, 21, 3), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2082, 8, 8, 14, 32, 11), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2082, 8, 16, 16, 6, 59), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2082, 8, 24, 1, 17, 21), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2082, 8, 30, 16, 41, 31), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2082, 9, 7, 4, 29, 12), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2082, 9, 15, 8, 28, 17), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2082, 9, 22, 10, 3, 13), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2082, 9, 29, 0, 34, 2), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2082, 10, 6, 20, 47, 2), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2082, 10, 14, 23, 31, 5), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2082, 10, 21, 18, 49, 52), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2082, 10, 28, 12, 13, 5), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2082, 11, 5, 14, 37, 46), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2082, 11, 13, 12, 45, 31), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2082, 11, 20, 4, 18, 54), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2082, 11, 27, 4, 6, 54), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2082, 12, 5, 8, 56, 3), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2082, 12, 12, 23, 51, 2), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2082, 12, 19, 15, 10, 8), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2082, 12, 26, 23, 36, 24), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2083, 1, 4, 2, 31, 54), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2083, 1, 11, 8, 54, 35), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2083, 1, 18, 3, 49, 18), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2083, 1, 25, 21, 2, 53), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2083, 2, 2, 18, 19, 46), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2083, 2, 9, 16, 38, 46), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2083, 2, 16, 18, 14, 46), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2083, 2, 24, 18, 23, 33), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2083, 3, 4, 7, 33, 57), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2083, 3, 11, 0, 9, 8), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2083, 3, 18, 9, 55, 59), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2083, 3, 26, 13, 46, 57), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2083, 4, 2, 18, 6, 14), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2083, 4, 9, 8, 30, 39), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2083, 4, 17, 2, 8, 52), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2083, 4, 25, 5, 53, 50), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2083, 5, 2, 2, 29, 2), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2083, 5, 8, 18, 29, 30), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2083, 5, 16, 18, 13, 7), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2083, 5, 24, 18, 10, 9), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2083, 5, 31, 9, 41, 26), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2083, 6, 7, 6, 28, 26), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2083, 6, 15, 9, 36, 57), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2083, 6, 23, 2, 51, 36), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2083, 6, 29, 16, 50, 59), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2083, 7, 6, 20, 32, 52), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2083, 7, 14, 23, 53, 52), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2083, 7, 22, 8, 55, 42), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2083, 7, 29, 1, 0, 6), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2083, 8, 5, 12, 37, 32), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2083, 8, 13, 12, 44, 31), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2083, 8, 20, 13, 45, 57), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2083, 8, 27, 10, 59, 10), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2083, 9, 4, 6, 23, 31), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2083, 9, 12, 0, 6, 39), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2083, 9, 18, 18, 55, 45), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2083, 9, 25, 23, 24, 22), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2083, 10, 4, 1, 6, 5), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2083, 10, 11, 10, 22, 40), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2083, 10, 18, 1, 53, 40), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2083, 10, 25, 14, 35, 3), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2083, 11, 2, 19, 29, 22), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2083, 11, 9, 20, 14, 16), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2083, 11, 16, 11, 47, 58), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2083, 11, 24, 8, 22, 31), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2083, 12, 2, 12, 5, 46), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2083, 12, 9, 6, 24, 6), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2083, 12, 16, 1, 12, 53), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2083, 12, 24, 3, 50, 52), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2084, 1, 1, 1, 53, 36), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2084, 1, 7, 17, 16, 28), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2084, 1, 14, 18, 1, 49), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2084, 1, 22, 23, 14, 5), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2084, 1, 30, 12, 41, 28), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2084, 2, 6, 4, 52, 9), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2084, 2, 13, 13, 27, 35), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2084, 2, 21, 16, 35, 44), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2084, 2, 28, 21, 3, 26), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2084, 3, 6, 17, 3, 7), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2084, 3, 14, 10, 5, 14), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2084, 3, 22, 6, 47, 49), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2084, 3, 29, 3, 57, 51), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2084, 4, 5, 5, 51, 35), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2084, 4, 13, 6, 4, 20), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2084, 4, 20, 17, 51, 58), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2084, 4, 27, 10, 28, 18), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2084, 5, 4, 19, 31, 53), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2084, 5, 12, 23, 42, 22), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2084, 5, 20, 2, 35, 46), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2084, 5, 26, 17, 35, 5), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2084, 6, 3, 10, 12, 40), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2084, 6, 11, 14, 2, 32), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2084, 6, 18, 10, 0, 0), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2084, 6, 25, 2, 15, 2), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2084, 7, 3, 1, 37, 28), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2084, 7, 11, 1, 5, 13), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2084, 7, 17, 17, 0, 59), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2084, 7, 24, 13, 21, 12), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2084, 8, 1, 17, 3, 34), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2084, 8, 9, 9, 31, 48), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2084, 8, 16, 0, 28, 59), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2084, 8, 23, 3, 33, 35), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2084, 8, 31, 7, 44, 14), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2084, 9, 7, 16, 22, 24), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2084, 9, 14, 9, 14, 35), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2084, 9, 21, 20, 58, 46), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2084, 9, 29, 21, 15, 54), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2084, 10, 6, 22, 41, 29), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2084, 10, 13, 20, 12, 12), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2084, 10, 21, 16, 48, 28), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2084, 10, 29, 9, 46, 21), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2084, 11, 5, 5, 32, 39), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2084, 11, 12, 10, 9, 28), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2084, 11, 20, 13, 22, 4), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2084, 11, 27, 21, 38, 30), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2084, 12, 4, 13, 56, 45), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2084, 12, 12, 3, 18, 0), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2084, 12, 20, 8, 43, 48), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2084, 12, 27, 9, 6, 21), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2085, 1, 3, 0, 46, 49), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2085, 1, 10, 22, 41, 41), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2085, 1, 19, 1, 25, 58), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2085, 1, 25, 20, 6, 8), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2085, 2, 1, 14, 35, 8), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2085, 2, 9, 18, 23, 39), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2085, 2, 17, 14, 46, 14), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2085, 2, 24, 6, 31, 41), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2085, 3, 3, 7, 15, 5), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2085, 3, 11, 12, 24, 57), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2085, 3, 19, 0, 44, 4), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2085, 3, 25, 16, 36, 33), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2085, 4, 2, 1, 50, 37), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2085, 4, 10, 3, 40, 20), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2085, 4, 17, 7, 53, 49), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2085, 4, 24, 2, 58, 29), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2085, 5, 1, 20, 51, 15), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2085, 5, 9, 16, 3, 23), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2085, 5, 16, 13, 18, 1), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2085, 5, 23, 14, 22, 33), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2085, 5, 31, 14, 50, 8), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2085, 6, 8, 2, 1, 16), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2085, 6, 14, 18, 18, 12), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2085, 6, 22, 3, 17, 28), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2085, 6, 30, 6, 54, 17), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2085, 7, 7, 10, 14, 2), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2085, 7, 14, 0, 23, 44), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2085, 7, 21, 17, 42, 1), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2085, 7, 29, 20, 46, 20), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2085, 8, 5, 17, 28, 39), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2085, 8, 12, 8, 59, 2), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2085, 8, 20, 9, 9, 53), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2085, 8, 28, 8, 29, 42), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2085, 9, 4, 0, 40, 33), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2085, 9, 10, 21, 6, 58), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2085, 9, 19, 1, 6, 7), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2085, 9, 26, 18, 17, 34), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2085, 10, 3, 8, 52, 53), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2085, 10, 10, 13, 8, 48), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2085, 10, 18, 16, 59, 9), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2085, 10, 26, 2, 34, 5), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2085, 11, 1, 19, 8, 1), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2085, 11, 9, 8, 30, 13), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2085, 11, 17, 8, 20, 2), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2085, 11, 24, 10, 1, 32), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2085, 12, 1, 8, 9, 10), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2085, 12, 9, 5, 46, 31), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2085, 12, 16, 22, 37, 32), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2085, 12, 23, 17, 41, 17), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2085, 12, 30, 23, 58, 14), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2086, 1, 8, 3, 4, 56), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2086, 1, 15, 11, 23, 12), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2086, 1, 22, 2, 40, 22), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2086, 1, 29, 17, 47, 55), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2086, 2, 6, 22, 29, 53), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2086, 2, 13, 22, 26, 21), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2086, 2, 20, 13, 47, 45), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2086, 2, 28, 12, 20, 20), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2086, 3, 8, 14, 29, 20), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2086, 3, 15, 8, 3, 21), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2086, 3, 22, 3, 15, 24), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2086, 3, 30, 6, 16, 43), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2086, 4, 7, 2, 21, 27), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2086, 4, 13, 16, 52, 28), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2086, 4, 20, 18, 39, 11), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2086, 4, 28, 22, 34, 41), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2086, 5, 6, 10, 25, 48), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2086, 5, 13, 1, 40, 39), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2086, 5, 20, 11, 18, 7), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2086, 5, 28, 12, 34, 10), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2086, 6, 4, 15, 50, 44), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2086, 6, 11, 11, 11, 42), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2086, 6, 19, 4, 32, 7), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2086, 6, 27, 0, 3, 17), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2086, 7, 3, 20, 9, 35), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2086, 7, 10, 22, 1, 20), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2086, 7, 18, 21, 44, 11), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2086, 7, 26, 9, 23, 18), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2086, 8, 2, 1, 0, 22), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2086, 8, 9, 10, 37, 11), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2086, 8, 17, 14, 13, 37), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2086, 8, 24, 17, 24, 50), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2086, 8, 31, 7, 51, 40), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2086, 9, 8, 1, 16, 38), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2086, 9, 16, 5, 16, 12), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2086, 9, 23, 1, 14, 17), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2086, 9, 29, 17, 51, 39), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2086, 10, 7, 17, 55, 43), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2086, 10, 15, 18, 17, 50), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2086, 10, 22, 9, 55, 15), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2086, 10, 29, 7, 39, 14), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2086, 11, 6, 11, 52, 48), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2086, 11, 14, 5, 10, 49), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2086, 11, 20, 20, 11, 24), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2086, 11, 28, 1, 16, 10), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2086, 12, 6, 5, 47, 12), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2086, 12, 13, 14, 19, 10), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2086, 12, 20, 8, 18, 18), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2086, 12, 27, 21, 57, 4), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2087, 1, 4, 22, 10, 19), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2087, 1, 11, 22, 29, 59), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2087, 1, 18, 22, 10, 9), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2087, 1, 26, 20, 0, 46), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2087, 2, 3, 12, 9, 50), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2087, 2, 10, 6, 37, 1), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2087, 2, 17, 13, 33, 25), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2087, 2, 25, 17, 5, 38), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2087, 3, 4, 23, 44, 35), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2087, 3, 11, 15, 25, 43), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2087, 3, 19, 6, 8, 27), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2087, 3, 27, 11, 3, 1), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2087, 4, 3, 9, 25, 12), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2087, 4, 10, 1, 28, 17), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2087, 4, 17, 23, 16, 20), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2087, 4, 26, 0, 50, 38), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2087, 5, 2, 17, 50, 19), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2087, 5, 9, 13, 8, 26), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2087, 5, 17, 15, 54, 23), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2087, 5, 25, 10, 40, 39), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2087, 6, 1, 1, 37, 56), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2087, 6, 8, 2, 44, 44), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2087, 6, 16, 6, 57, 56), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2087, 6, 23, 17, 32, 8), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2087, 6, 30, 9, 30, 9), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2087, 7, 7, 18, 22, 59), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2087, 7, 15, 19, 53, 30), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2087, 7, 22, 22, 42, 40), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2087, 7, 29, 18, 19, 37), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2087, 8, 6, 11, 42, 9), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2087, 8, 14, 6, 53, 9), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2087, 8, 21, 3, 33, 11), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2087, 8, 28, 5, 7, 26), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2087, 9, 5, 5, 48, 38), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2087, 9, 12, 16, 42, 5), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2087, 9, 19, 9, 22, 45), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2087, 9, 26, 18, 46, 1), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2087, 10, 4, 23, 31, 23), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2087, 10, 12, 2, 13, 8), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2087, 10, 18, 17, 26, 38), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2087, 10, 26, 11, 28, 38), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2087, 11, 3, 15, 47, 59), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2087, 11, 10, 12, 4, 18), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2087, 11, 17, 4, 50, 1), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2087, 11, 25, 6, 22, 56), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2087, 12, 3, 6, 0, 10), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2087, 12, 9, 22, 30, 57), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2087, 12, 16, 20, 10, 50), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2087, 12, 25, 1, 41, 57), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2088, 1, 1, 17, 53, 14), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2088, 1, 8, 9, 36, 54), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2088, 1, 15, 15, 12, 4), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2088, 1, 23, 19, 37, 46), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2088, 1, 31, 3, 32, 40), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2088, 2, 6, 21, 31, 59), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2088, 2, 14, 12, 23, 52), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2088, 2, 22, 11, 8, 24), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2088, 2, 29, 11, 25, 52), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2088, 3, 7, 10, 35, 36), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2088, 3, 15, 9, 28, 58), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2088, 3, 22, 23, 59, 44), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2088, 3, 29, 18, 22, 35), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2088, 4, 6, 0, 58, 53), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2088, 4, 14, 4, 25, 13), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2088, 4, 21, 10, 24, 9), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2088, 4, 28, 1, 27, 59), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2088, 5, 5, 16, 24, 25), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2088, 5, 13, 20, 8, 26), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2088, 5, 20, 18, 48, 7), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2088, 5, 27, 9, 50, 34), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2088, 6, 4, 8, 7, 43), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2088, 6, 12, 8, 29, 18), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2088, 6, 19, 1, 53, 5), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2088, 6, 25, 20, 28, 4), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2088, 7, 3, 23, 21, 29), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2088, 7, 11, 17, 51, 23), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2088, 7, 18, 8, 37, 52), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2088, 7, 25, 9, 53, 30), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2088, 8, 2, 13, 38, 52), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2088, 8, 10, 0, 56, 46), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2088, 8, 16, 16, 14, 43), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2088, 8, 24, 2, 4, 41), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2088, 9, 1, 2, 56, 51), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2088, 9, 8, 6, 42, 59), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2088, 9, 15, 1, 57, 0), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2088, 9, 22, 20, 24, 18), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2088, 9, 30, 15, 24, 19), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2088, 10, 7, 12, 23, 10), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2088, 10, 14, 14, 38, 42), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2088, 10, 22, 15, 50, 26), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2088, 10, 30, 3, 9, 30), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2088, 11, 5, 19, 21, 20), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2088, 11, 13, 6, 30, 56), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2088, 11, 21, 11, 7, 8), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2088, 11, 28, 14, 17, 27), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2088, 12, 5, 4, 57, 4), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2088, 12, 13, 0, 51, 4), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2088, 12, 21, 4, 51, 5), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2088, 12, 28, 0, 56, 35), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2089, 1, 3, 17, 59, 36), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2089, 1, 11, 20, 16, 57), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2089, 1, 19, 19, 46, 43), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2089, 1, 26, 11, 24, 12), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2089, 2, 2, 10, 22, 54), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2089, 2, 10, 15, 15, 10), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2089, 2, 18, 7, 14, 22), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2089, 2, 24, 22, 4, 21), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2089, 3, 4, 5, 4, 31), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2089, 3, 12, 8, 23, 19), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2089, 3, 19, 15, 29, 49), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2089, 3, 26, 9, 19, 12), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2089, 4, 3, 0, 34, 57), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2089, 4, 10, 22, 44, 56), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2089, 4, 17, 21, 36, 33), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2089, 4, 24, 21, 21, 56), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2089, 5, 2, 19, 30, 54), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2089, 5, 10, 10, 2, 57), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2089, 5, 17, 3, 0, 45), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2089, 5, 24, 10, 17, 7), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2089, 6, 1, 12, 48, 18), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2089, 6, 8, 18, 43, 45), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2089, 6, 15, 9, 8, 8), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2089, 6, 23, 0, 6, 16), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2089, 7, 1, 3, 43, 1), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2089, 7, 8, 1, 46, 58), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2089, 7, 14, 17, 10, 2), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2089, 7, 22, 14, 49, 0), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2089, 7, 30, 15, 54, 27), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2089, 8, 6, 8, 27, 15), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2089, 8, 13, 3, 57, 35), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2089, 8, 21, 6, 14, 57), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2089, 8, 29, 1, 31, 48), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2089, 9, 4, 15, 57, 8), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2089, 9, 11, 18, 0, 5), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2089, 9, 19, 21, 54, 42), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2089, 9, 27, 9, 14, 49), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2089, 10, 4, 1, 14, 15), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2089, 10, 11, 11, 21, 23), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2089, 10, 19, 13, 3, 27), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2089, 10, 26, 16, 5, 58), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2089, 11, 2, 12, 54, 19), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2089, 11, 10, 7, 27, 27), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2089, 11, 18, 3, 1, 49), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2089, 11, 24, 23, 17, 21), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2089, 12, 2, 3, 10, 51), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2089, 12, 10, 4, 51, 8), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2089, 12, 17, 15, 36, 52), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2089, 12, 24, 7, 54, 0), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2089, 12, 31, 19, 56, 8), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2090, 1, 9, 1, 20, 50), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2090, 1, 16, 3, 2, 2), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2090, 1, 22, 18, 37, 56), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2090, 1, 30, 14, 33, 30), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2090, 2, 7, 18, 51, 16), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2090, 2, 14, 13, 38, 36), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2090, 2, 21, 7, 41, 40), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2090, 3, 1, 9, 45, 41), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2090, 3, 9, 8, 19, 13), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2090, 3, 15, 23, 41, 18), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2090, 3, 22, 22, 54, 16), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2090, 3, 31, 3, 47, 26), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2090, 4, 7, 17, 55, 14), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2090, 4, 14, 9, 21, 18), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2090, 4, 21, 15, 48, 57), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2090, 4, 29, 19, 11, 21), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2090, 5, 7, 0, 37, 52), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2090, 5, 13, 19, 0, 9), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2090, 5, 21, 9, 42, 9), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2090, 5, 29, 7, 28, 50), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2090, 6, 5, 5, 44, 44), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2090, 6, 12, 5, 17, 17), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2090, 6, 20, 3, 32, 45), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2090, 6, 27, 17, 10, 27), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2090, 7, 4, 10, 36, 16), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2090, 7, 11, 17, 1, 52), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2090, 7, 19, 20, 15, 34), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2090, 7, 27, 1, 18, 24), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2090, 8, 2, 16, 30, 43), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2090, 8, 10, 6, 50, 50), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2090, 8, 18, 11, 4, 51), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2090, 8, 25, 8, 58, 7), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2090, 9, 1, 0, 43, 7), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2090, 9, 8, 22, 43, 54), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2090, 9, 16, 23, 47, 12), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2090, 9, 23, 17, 2, 19), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2090, 9, 30, 12, 20, 17), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2090, 10, 8, 15, 53, 23), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2090, 10, 16, 10, 35, 58), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2090, 10, 23, 2, 8, 47), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2090, 10, 30, 4, 3, 51), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2090, 11, 7, 9, 4, 24), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2090, 11, 14, 19, 57, 49), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2090, 11, 21, 12, 47, 50), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2090, 11, 28, 23, 39, 43), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2090, 12, 7, 1, 13, 31), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2090, 12, 14, 4, 24, 37), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2090, 12, 21, 1, 28, 32), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2090, 12, 28, 21, 35, 21), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2091, 1, 5, 15, 49, 57), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2091, 1, 12, 12, 33, 55), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2091, 1, 19, 16, 30, 13), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2091, 1, 27, 19, 23, 5), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2091, 2, 4, 4, 45, 42), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2091, 2, 10, 21, 10, 25), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2091, 2, 18, 9, 37, 41), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2091, 2, 26, 14, 46, 45), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2091, 3, 5, 15, 58, 12), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2091, 3, 12, 7, 1, 9), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2091, 3, 20, 3, 45, 9), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2091, 3, 28, 6, 30, 32), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2091, 4, 4, 1, 30, 38), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2091, 4, 10, 18, 44, 12), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2091, 4, 18, 21, 19, 5), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2091, 4, 26, 18, 19, 51), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2091, 5, 3, 9, 45, 14), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2091, 5, 10, 8, 35, 41), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2091, 5, 18, 13, 6, 7), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2091, 5, 26, 2, 41, 48), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2091, 6, 1, 17, 30, 25), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2091, 6, 9, 0, 21, 22), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2091, 6, 17, 2, 40, 16), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2091, 6, 24, 8, 30, 43), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2091, 7, 1, 1, 53, 6), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2091, 7, 8, 17, 20, 50), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2091, 7, 16, 14, 14, 53), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2091, 7, 23, 13, 1, 6), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2091, 7, 30, 12, 0, 30), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2091, 8, 7, 10, 44, 3), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2091, 8, 15, 0, 21, 11), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2091, 8, 21, 17, 42, 9), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2091, 8, 29, 0, 38, 40), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2091, 9, 6, 3, 46, 49), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2091, 9, 13, 9, 33, 53), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2091, 9, 20, 0, 9, 23), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2091, 9, 27, 15, 55, 21), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2091, 10, 5, 19, 52, 33), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2091, 10, 12, 18, 28, 6), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2091, 10, 19, 9, 49, 50), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2091, 10, 27, 9, 17, 4), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2091, 11, 4, 10, 25, 59), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2091, 11, 11, 3, 41, 30), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2091, 11, 17, 23, 37, 53), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2091, 11, 26, 3, 42, 23), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2091, 12, 3, 22, 54, 52), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2091, 12, 10, 13, 53, 20), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2091, 12, 17, 17, 28, 8), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2091, 12, 25, 21, 58, 48), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2092, 1, 2, 9, 6, 33), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2092, 1, 9, 1, 36, 5), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2092, 1, 16, 14, 7, 17), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2092, 1, 24, 14, 54, 4), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2092, 1, 31, 17, 24, 32), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2092, 2, 7, 15, 2, 5), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2092, 2, 15, 11, 39, 34), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2092, 2, 23, 5, 28, 41), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2092, 3, 1, 0, 45, 49), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2092, 3, 8, 5, 55, 37), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2092, 3, 16, 8, 6, 27), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2092, 3, 23, 17, 15, 2), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2092, 3, 30, 8, 20, 4), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2092, 4, 6, 21, 40, 36), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2092, 4, 15, 1, 53, 33), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2092, 4, 22, 2, 28, 25), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2092, 4, 28, 17, 7, 9), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2092, 5, 6, 13, 38, 9), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2092, 5, 14, 16, 5, 9), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2092, 5, 21, 9, 59, 20), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2092, 5, 28, 3, 45, 14), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2092, 6, 5, 5, 16, 44), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2092, 6, 13, 2, 32, 41), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2092, 6, 19, 16, 55, 8), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2092, 6, 26, 16, 31, 44), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2092, 7, 4, 20, 9, 54), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2092, 7, 12, 9, 53, 18), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2092, 7, 19, 0, 23, 23), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2092, 7, 26, 7, 30, 4), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2092, 8, 3, 9, 53, 39), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2092, 8, 10, 15, 17, 47), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2092, 8, 17, 9, 21, 34), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2092, 8, 25, 0, 31, 18), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2092, 9, 1, 22, 13, 15), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2092, 9, 8, 20, 14, 44), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2092, 9, 15, 20, 32, 54), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2092, 9, 23, 19, 4, 53), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2092, 10, 1, 9, 14, 41), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2092, 10, 8, 2, 15, 32), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2092, 10, 15, 10, 25, 0), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2092, 10, 23, 14, 6, 53), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2092, 10, 30, 19, 28, 4), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2092, 11, 6, 10, 39, 15), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2092, 11, 14, 3, 3, 58), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2092, 11, 22, 8, 6, 11), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2092, 11, 29, 5, 35, 23), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2092, 12, 5, 22, 17, 5), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2092, 12, 13, 21, 58, 6), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2092, 12, 21, 23, 38, 20), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2092, 12, 28, 16, 9, 42), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2093, 1, 4, 13, 21, 58), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2093, 1, 12, 17, 42, 42), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2093, 1, 20, 12, 2, 37), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2093, 1, 27, 3, 21, 34), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2093, 2, 3, 7, 26, 44), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2093, 2, 11, 12, 17, 57), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2093, 2, 18, 21, 31, 53), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2093, 2, 25, 15, 4, 58), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2093, 3, 5, 3, 26, 50), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2093, 3, 13, 4, 5, 48), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2093, 3, 20, 4, 56, 2), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2093, 3, 27, 3, 17, 25), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2093, 4, 3, 23, 45, 55), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2093, 4, 11, 16, 35, 3), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2093, 4, 18, 11, 19, 25), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2093, 4, 25, 16, 11, 50), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2093, 5, 3, 18, 36, 43), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2093, 5, 11, 2, 16, 17), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2093, 5, 17, 17, 46, 35), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2093, 5, 25, 6, 6, 38), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2093, 6, 2, 10, 38, 24), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2093, 6, 9, 10, 8, 38), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2093, 6, 16, 1, 18, 36), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2093, 6, 23, 21, 3, 47), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2093, 7, 1, 23, 23, 8), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2093, 7, 8, 17, 13, 2), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2093, 7, 15, 10, 53, 39), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2093, 7, 23, 12, 35, 16), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2093, 7, 31, 9, 12, 43), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2093, 8, 7, 0, 22, 50), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2093, 8, 13, 23, 22, 48), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2093, 8, 22, 3, 52, 51), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2093, 8, 29, 16, 57, 43), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2093, 9, 5, 8, 27, 11), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2093, 9, 12, 15, 14, 26), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2093, 9, 20, 18, 15, 51), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2093, 9, 27, 23, 38, 46), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2093, 10, 4, 18, 17, 26), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2093, 10, 12, 10, 9, 54), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2093, 10, 20, 7, 32, 34), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2093, 10, 27, 6, 17, 27), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2093, 11, 3, 6, 45, 19), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2093, 11, 11, 6, 49, 39), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2093, 11, 18, 19, 57, 3), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2093, 11, 25, 13, 53, 52), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2093, 12, 2, 22, 23, 17), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2093, 12, 11, 3, 14, 53), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2093, 12, 18, 7, 46, 55), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2093, 12, 24, 23, 24, 21), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2094, 1, 1, 16, 50, 30), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2094, 1, 9, 21, 35, 39), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2094, 1, 16, 19, 4, 51), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2094, 1, 23, 11, 33, 0), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2094, 1, 31, 12, 35, 57), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2094, 2, 8, 12, 44, 43), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2094, 2, 15, 5, 42, 46), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2094, 2, 22, 2, 35, 46), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2094, 3, 2, 7, 34, 10), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2094, 3, 10, 0, 21, 47), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2094, 3, 16, 15, 43, 47), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2094, 3, 23, 20, 4, 31), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2094, 4, 1, 0, 10, 48), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2094, 4, 8, 8, 45, 40), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2094, 4, 15, 1, 36, 50), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2094, 4, 22, 14, 47, 14), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2094, 4, 30, 13, 53, 40), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2094, 5, 7, 14, 47, 1), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2094, 5, 14, 12, 8, 56), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2094, 5, 22, 9, 15, 8), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2094, 5, 30, 0, 57, 29), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2094, 6, 5, 19, 40, 16), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2094, 6, 13, 0, 2, 44), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2094, 6, 21, 2, 18, 56), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2094, 6, 28, 9, 58, 1), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2094, 7, 5, 0, 53, 36), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2094, 7, 12, 13, 36, 1), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2094, 7, 20, 17, 24, 49), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2094, 7, 27, 17, 39, 2), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2094, 8, 3, 7, 57, 11), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2094, 8, 11, 4, 36, 5), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2094, 8, 19, 6, 25, 53), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2094, 8, 26, 0, 51, 4), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2094, 9, 1, 18, 8, 51), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2094, 9, 9, 20, 30, 37), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2094, 9, 17, 17, 27, 45), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2094, 9, 24, 8, 32, 49), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2094, 10, 1, 8, 14, 37), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2094, 10, 9, 12, 43, 38), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2094, 10, 17, 2, 44, 18), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2094, 10, 23, 17, 47, 0), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2094, 10, 31, 2, 8, 47), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2094, 11, 8, 4, 41, 32), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2094, 11, 15, 10, 44, 4), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2094, 11, 22, 5, 26, 54), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2094, 11, 29, 22, 47, 53), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2094, 12, 7, 19, 49, 44), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2094, 12, 14, 18, 16, 38), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2094, 12, 21, 19, 55, 12), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2094, 12, 29, 20, 26, 50), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2095, 1, 6, 9, 32, 55), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2095, 1, 13, 2, 27, 4), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2095, 1, 20, 12, 47, 21), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2095, 1, 28, 17, 6, 0), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2095, 2, 4, 21, 27, 59), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2095, 2, 11, 12, 16, 13), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2095, 2, 19, 6, 58, 18), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2095, 2, 27, 10, 57, 50), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2095, 3, 6, 7, 38, 23), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2095, 3, 13, 0, 17, 21), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2095, 3, 21, 1, 9, 42), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2095, 3, 29, 0, 53, 49), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2095, 4, 4, 16, 35, 9), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2095, 4, 11, 14, 26, 31), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2095, 4, 19, 18, 13, 33), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2095, 4, 27, 10, 44, 23), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2095, 5, 4, 1, 5, 10), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2095, 5, 11, 6, 13, 26), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2095, 5, 19, 9, 20, 38), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2095, 5, 26, 17, 16, 46), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2095, 6, 2, 9, 57, 18), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2095, 6, 9, 23, 1, 7), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2095, 6, 17, 22, 4, 58), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2095, 6, 24, 21, 54, 42), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2095, 7, 1, 19, 53, 44), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2095, 7, 9, 16, 14, 58), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2095, 7, 17, 8, 30, 20), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2095, 7, 24, 2, 16, 1), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2095, 7, 31, 7, 28, 0), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2095, 8, 8, 9, 18, 2), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2095, 8, 15, 17, 12, 24), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2095, 8, 22, 7, 55, 59), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2095, 8, 29, 21, 4, 49), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2095, 9, 7, 1, 25, 21), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2095, 9, 14, 1, 10, 18), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2095, 9, 20, 16, 14, 49), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2095, 9, 28, 12, 53, 26), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2095, 10, 6, 15, 51, 25), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2095, 10, 13, 9, 29, 48), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2095, 10, 20, 4, 7, 20), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2095, 10, 28, 6, 31, 43), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2095, 11, 5, 4, 8, 28), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2095, 11, 11, 19, 4, 12), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2095, 11, 18, 19, 54, 9), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2095, 11, 27, 0, 53, 30), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2095, 12, 4, 14, 20, 20), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2095, 12, 11, 6, 20, 28), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2095, 12, 18, 15, 13, 14), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2095, 12, 26, 18, 23, 50), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2096, 1, 2, 23, 1, 15), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2096, 1, 9, 19, 19, 19), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2096, 1, 17, 12, 50, 0), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2096, 1, 25, 9, 44, 10), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2096, 2, 1, 7, 2, 28), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2096, 2, 8, 9, 49, 7), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2096, 2, 16, 10, 38, 33), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2096, 2, 23, 22, 27, 42), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2096, 3, 1, 15, 15, 20), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2096, 3, 9, 1, 36, 16), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2096, 3, 17, 6, 17, 36), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2096, 3, 24, 8, 54, 5), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2096, 3, 31, 0, 20, 36), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2096, 4, 7, 18, 17, 28), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2096, 4, 15, 22, 9, 50), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2096, 4, 22, 17, 42, 46), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2096, 4, 29, 10, 48, 58), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2096, 5, 7, 11, 5, 58), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2096, 5, 15, 9, 53, 31), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2096, 5, 22, 1, 34, 46), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2096, 5, 28, 23, 6, 5), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2096, 6, 6, 2, 57, 50), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2096, 6, 13, 18, 8, 13), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2096, 6, 20, 9, 11, 11), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2096, 6, 27, 13, 30, 0), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2096, 7, 5, 17, 1, 27), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2096, 7, 13, 0, 4, 6), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2096, 7, 19, 17, 19, 58), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2096, 7, 27, 5, 58, 23), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2096, 8, 4, 5, 4, 24), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2096, 8, 11, 5, 0, 23), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2096, 8, 18, 2, 59, 26), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2096, 8, 25, 23, 55, 14), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2096, 9, 2, 15, 35, 20), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2096, 9, 9, 10, 16, 19), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2096, 9, 16, 15, 9, 38), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2096, 9, 24, 18, 13, 47), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2096, 10, 2, 1, 23, 58), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2096, 10, 8, 17, 8, 29), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2096, 10, 16, 6, 27, 42), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2096, 10, 24, 11, 39, 57), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2096, 10, 31, 11, 15, 22), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2096, 11, 7, 2, 47, 32), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2096, 11, 15, 0, 34, 59), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2096, 11, 23, 3, 17, 12), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2096, 11, 29, 21, 33, 17), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2096, 12, 6, 16, 6, 51), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2096, 12, 14, 20, 4, 56), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2096, 12, 22, 16, 35, 5), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2096, 12, 29, 8, 22, 28), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2097, 1, 5, 9, 19, 25), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2097, 1, 13, 14, 59, 24), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2097, 1, 21, 3, 26, 21), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2097, 1, 27, 19, 46, 31), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2097, 2, 4, 5, 31, 50), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2097, 2, 12, 7, 48, 23), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2097, 2, 19, 12, 6, 25), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2097, 2, 26, 8, 1, 26), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2097, 3, 6, 2, 46, 17), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2097, 3, 13, 21, 56, 0), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2097, 3, 20, 19, 15, 16), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2097, 3, 27, 21, 27, 48), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2097, 4, 4, 22, 49, 2), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2097, 4, 12, 9, 25, 35), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2097, 4, 19, 1, 53, 47), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2097, 4, 26, 12, 8, 19), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2097, 5, 4, 16, 8, 0), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2097, 5, 11, 18, 39, 24), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2097, 5, 18, 9, 13, 12), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2097, 5, 26, 3, 35, 11), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2097, 6, 3, 6, 10, 11), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2097, 6, 10, 2, 12, 56), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2097, 6, 16, 18, 21, 32), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2097, 6, 24, 19, 2, 54), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2097, 7, 2, 17, 4, 42), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2097, 7, 9, 8, 57, 21), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2097, 7, 16, 6, 9, 14), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2097, 7, 24, 9, 53, 43), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2097, 8, 1, 1, 23, 28), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2097, 8, 7, 15, 59, 13), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2097, 8, 14, 20, 55, 33), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2097, 8, 22, 23, 52, 0), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2097, 8, 30, 7, 53, 43), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2097, 9, 6, 0, 32, 59), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2097, 9, 13, 14, 21, 28), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2097, 9, 21, 12, 59, 33), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2097, 9, 28, 13, 38, 21), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2097, 10, 5, 11, 44, 31), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2097, 10, 13, 9, 34, 45), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2097, 10, 21, 1, 22, 14), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2097, 10, 27, 19, 55, 1), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2097, 11, 4, 2, 7, 53), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2097, 11, 12, 5, 22, 3), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2097, 11, 19, 13, 2, 33), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2097, 11, 26, 4, 6, 34), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2097, 12, 3, 19, 25, 50), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2097, 12, 12, 0, 18, 12), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2097, 12, 19, 0, 3, 13), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2097, 12, 25, 15, 19, 45), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2098, 1, 2, 14, 31, 34), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2098, 1, 10, 16, 56, 57), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2098, 1, 17, 10, 35, 10), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2098, 1, 24, 5, 57, 42), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2098, 2, 1, 9, 53, 21), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2098, 2, 9, 6, 15, 8), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2098, 2, 15, 20, 59, 41), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2098, 2, 22, 23, 25, 17), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2098, 3, 3, 4, 1, 39), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2098, 3, 10, 16, 0, 8), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2098, 3, 17, 7, 42, 14), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2098, 3, 24, 18, 25, 16), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2098, 4, 1, 19, 46, 28), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2098, 4, 8, 22, 55, 16), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2098, 4, 15, 19, 3, 31), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2098, 4, 23, 13, 32, 58), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2098, 5, 1, 8, 31, 12), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2098, 5, 8, 4, 20, 10), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2098, 5, 15, 7, 15, 24), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2098, 5, 23, 7, 38, 30), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2098, 5, 30, 18, 22, 15), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2098, 6, 6, 9, 44, 47), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2098, 6, 13, 20, 24, 14), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2098, 6, 21, 23, 49, 58), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2098, 6, 29, 2, 5, 22), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2098, 7, 5, 16, 30, 58), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2098, 7, 13, 10, 34, 21), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2098, 7, 21, 13, 33, 43), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2098, 7, 28, 8, 50, 14), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2098, 8, 4, 1, 42, 40), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2098, 8, 12, 1, 43, 35), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2098, 8, 20, 0, 40, 40), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2098, 8, 26, 15, 52, 2), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2098, 9, 2, 14, 2, 13), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2098, 9, 10, 17, 32, 27), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2098, 9, 18, 9, 31, 57), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2098, 9, 25, 0, 16, 16), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2098, 10, 2, 5, 47, 59), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2098, 10, 10, 9, 19, 23), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2098, 10, 17, 16, 56, 23), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2098, 10, 24, 10, 48, 20), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2098, 11, 1, 0, 46, 20), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2098, 11, 9, 0, 14, 23), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2098, 11, 16, 0, 0, 13), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2098, 11, 22, 23, 50, 21), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2098, 11, 30, 21, 55, 35), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2098, 12, 8, 13, 44, 58), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2098, 12, 15, 7, 51, 56), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2098, 12, 22, 15, 23, 32), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2098, 12, 30, 19, 19, 12), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2099, 1, 7, 1, 49, 52), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2099, 1, 13, 17, 25, 18), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2099, 1, 21, 9, 6, 49), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2099, 1, 29, 14, 37, 59), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2099, 2, 5, 12, 48, 2), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2099, 2, 12, 5, 7, 28), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2099, 2, 20, 4, 5, 8), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2099, 2, 28, 6, 12, 12), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2099, 3, 6, 22, 59, 4), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2099, 3, 13, 18, 59, 53), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2099, 3, 21, 22, 45, 57), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2099, 3, 29, 17, 38, 0), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2099, 4, 5, 8, 36, 35), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2099, 4, 12, 10, 47, 2), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2099, 4, 20, 15, 29, 14), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2099, 4, 28, 1, 35, 8), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2099, 5, 4, 17, 57, 59), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2099, 5, 12, 3, 59, 19), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2099, 5, 20, 5, 15, 54), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2099, 5, 27, 7, 15, 24), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2099, 6, 3, 3, 36, 21), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2099, 6, 10, 21, 48, 51), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2099, 6, 18, 16, 9, 34), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2099, 6, 25, 11, 59, 51), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2099, 7, 2, 14, 20, 27), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2099, 7, 10, 15, 13, 0), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2099, 7, 18, 1, 0, 11), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2099, 7, 24, 17, 8, 55), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2099, 8, 1, 2, 59, 2), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2099, 8, 9, 7, 13, 27), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2099, 8, 16, 8, 53, 55), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2099, 8, 23, 0, 0, 23), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2099, 8, 30, 17, 55, 10), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2099, 9, 7, 21, 17, 4), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2099, 9, 14, 16, 50, 4), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2099, 9, 21, 9, 47, 10), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2099, 9, 29, 10, 44, 52), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2099, 10, 7, 9, 20, 49), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2099, 10, 14, 1, 31, 39), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2099, 10, 20, 23, 26, 46), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2099, 10, 29, 4, 19, 52), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2099, 11, 5, 19, 41, 33), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2099, 11, 12, 11, 28, 52), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2099, 11, 19, 17, 16, 15), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2099, 11, 27, 21, 21, 38), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2099, 12, 5, 4, 44, 58), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2099, 12, 11, 23, 8, 28), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2099, 12, 19, 14, 21, 13), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2099, 12, 27, 12, 57, 53), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2100, 1, 3, 13, 2, 47), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2100, 1, 10, 12, 55, 7), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2100, 1, 18, 12, 33, 12), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2100, 1, 26, 2, 49, 3), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2100, 2, 1, 21, 15, 24), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2100, 2, 9, 4, 54, 17), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2100, 2, 17, 9, 21, 17), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2100, 2, 24, 14, 50, 54), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2100, 3, 3, 6, 10, 35), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2100, 3, 10, 22, 28, 2), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2100, 3, 19, 2, 57, 53), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2100, 3, 26, 1, 4, 16), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2100, 4, 1, 16, 34, 55), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2100, 4, 9, 16, 15, 53), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2100, 4, 17, 16, 41, 26), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2100, 4, 24, 9, 42, 57), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2100, 5, 1, 5, 0, 42), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2100, 5, 9, 8, 53, 0), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2100, 5, 17, 2, 41, 36), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2100, 5, 23, 17, 24, 43), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2100, 5, 30, 19, 34, 8), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2100, 6, 7, 23, 30, 55), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2100, 6, 15, 9, 40, 23), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2100, 6, 22, 1, 10, 43), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2100, 6, 29, 11, 51, 21), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2100, 7, 7, 12, 5, 44), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2100, 7, 14, 14, 42, 21), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2100, 7, 21, 10, 11, 56), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2100, 7, 29, 5, 7, 58), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2100, 8, 5, 23, 1, 3), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2100, 8, 12, 19, 9, 27), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2100, 8, 19, 21, 29, 4), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2100, 8, 27, 22, 35, 55), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2100, 9, 4, 8, 49, 2), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2100, 9, 11, 0, 34, 58), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2100, 9, 18, 11, 31, 30), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2100, 9, 26, 15, 32, 44), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2100, 10, 3, 18, 2, 8), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2100, 10, 10, 8, 32, 15), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2100, 10, 18, 4, 5, 19), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2100, 10, 26, 7, 18, 26), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2100, 11, 2, 3, 13, 45), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2100, 11, 8, 20, 14, 52), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2100, 11, 16, 22, 18, 38), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2100, 11, 24, 21, 12, 30), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2100, 12, 1, 13, 0, 3), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2100, 12, 8, 12, 8, 48), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2100, 12, 16, 16, 59, 17), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2100, 12, 24, 8, 45, 22), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2100, 12, 30, 23, 55, 55), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2101, 1, 7, 7, 32, 9), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2101, 1, 15, 10, 50, 21), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2101, 1, 22, 17, 59, 10), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2101, 1, 29, 12, 24, 2), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2101, 2, 6, 4, 45, 32), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2101, 2, 14, 2, 42, 10), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2101, 2, 21, 1, 35, 54), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2101, 2, 28, 2, 23, 38), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2101, 3, 8, 1, 49, 34), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2101, 3, 15, 15, 49, 26), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2101, 3, 22, 8, 43, 22), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2101, 3, 29, 17, 30, 10), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2101, 4, 6, 20, 58, 50), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2101, 4, 14, 2, 7, 53), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2101, 4, 20, 16, 30, 58), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2101, 4, 28, 9, 8, 56), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2101, 5, 6, 12, 59, 34), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2101, 5, 13, 10, 14, 46), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2101, 5, 20, 1, 51, 37), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2101, 5, 28, 0, 48, 52), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2101, 6, 5, 1, 19, 47), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2101, 6, 11, 17, 13, 4), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2101, 6, 18, 13, 16, 16), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2101, 6, 26, 16, 4, 24), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2101, 7, 4, 10, 13, 39), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2101, 7, 11, 0, 13, 1), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2101, 7, 18, 2, 58, 16), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2101, 7, 26, 6, 30, 45), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2101, 8, 2, 16, 35, 8), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2101, 8, 9, 8, 18, 39), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2101, 8, 16, 18, 57, 58), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2101, 8, 24, 19, 45, 35), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2101, 8, 31, 21, 43, 57), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2101, 9, 7, 18, 20, 42), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2101, 9, 15, 12, 58, 20), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2101, 9, 23, 7, 40, 3), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2101, 9, 30, 3, 10, 24), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2101, 10, 7, 6, 54, 20), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2101, 10, 15, 8, 11, 33), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2101, 10, 22, 18, 29, 5), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2101, 10, 29, 10, 20, 20), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2101, 11, 5, 22, 16, 43), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2101, 11, 14, 3, 13, 10), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2101, 11, 21, 4, 47, 47), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2101, 11, 27, 20, 19, 9), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2101, 12, 5, 16, 16, 4), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2101, 12, 13, 20, 24, 30), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2101, 12, 20, 15, 13, 33), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2101, 12, 27, 9, 38, 10), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2102, 1, 4, 11, 52, 59), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2102, 1, 12, 10, 35, 26), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2102, 1, 19, 2, 6, 52), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2102, 1, 26, 2, 9, 20), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2102, 2, 3, 7, 18, 38), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2102, 2, 10, 21, 31, 24), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2102, 2, 17, 13, 27, 35), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2102, 2, 24, 21, 7, 44), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2102, 3, 5, 0, 36, 11), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2102, 3, 12, 5, 47, 14), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2102, 3, 19, 1, 10, 35), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2102, 3, 26, 17, 15, 20), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2102, 4, 3, 14, 39, 34), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2102, 4, 10, 12, 23, 55), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2102, 4, 17, 13, 24, 2), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2102, 4, 25, 12, 51, 17), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2102, 5, 3, 1, 33, 29), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2102, 5, 9, 18, 28, 57), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2102, 5, 17, 2, 29, 55), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2102, 5, 25, 6, 20, 27), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2102, 6, 1, 10, 8, 29), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2102, 6, 8, 1, 7, 29), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2102, 6, 15, 16, 45, 9), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2102, 6, 23, 20, 47, 44), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2102, 6, 30, 17, 27, 59), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2102, 7, 7, 9, 21, 43), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2102, 7, 15, 8, 0, 36), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2102, 7, 23, 8, 10, 26), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2102, 7, 30, 0, 29, 54), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2102, 8, 5, 20, 9, 47), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2102, 8, 13, 23, 36, 47), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2102, 8, 21, 17, 4, 46), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2102, 8, 28, 8, 4, 26), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2102, 9, 4, 10, 16, 15), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2102, 9, 12, 14, 43, 57), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2102, 9, 20, 0, 25, 14), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2102, 9, 26, 17, 0, 18), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2102, 10, 4, 3, 50, 18), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2102, 10, 12, 4, 50, 48), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2102, 10, 19, 7, 10, 46), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2102, 10, 26, 4, 8, 44), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2102, 11, 3, 0, 1, 57), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2102, 11, 10, 17, 56, 8), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2102, 11, 17, 14, 19, 50), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2102, 11, 24, 18, 13, 52), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2102, 12, 2, 21, 3, 29), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2102, 12, 10, 6, 15, 7), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2102, 12, 16, 22, 49, 13), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2102, 12, 24, 11, 24, 15), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2103, 1, 1, 16, 49, 57), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2103, 1, 8, 17, 56, 10), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2103, 1, 15, 9, 29, 25), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2103, 1, 23, 6, 42, 24), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2103, 1, 31, 9, 47, 25), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2103, 2, 7, 4, 53, 15), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2103, 2, 13, 22, 52, 38), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2103, 2, 22, 2, 13, 1), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2103, 3, 1, 23, 12, 12), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2103, 3, 8, 15, 1, 50), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2103, 3, 15, 14, 56, 9), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2103, 3, 23, 20, 1, 8), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2103, 3, 31, 9, 5, 34), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2103, 4, 7, 0, 39, 48), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2103, 4, 14, 8, 52, 42), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2103, 4, 22, 11, 5, 13), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2103, 4, 29, 16, 4, 38), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2103, 5, 6, 10, 30, 11), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2103, 5, 14, 3, 23, 3), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2103, 5, 21, 23, 21, 26), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2103, 5, 28, 21, 14, 26), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2103, 6, 4, 21, 23, 54), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2103, 6, 12, 21, 8, 47), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2103, 6, 20, 9, 18, 25), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2103, 6, 27, 1, 58, 45), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2103, 7, 4, 9, 56, 22), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2103, 7, 12, 13, 19, 33), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2103, 7, 19, 17, 36, 47), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2103, 7, 26, 7, 49, 34), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2103, 8, 3, 0, 12, 41), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2103, 8, 11, 3, 35, 31), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2103, 8, 18, 1, 2, 59), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2103, 8, 24, 16, 14, 24), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2103, 9, 1, 15, 49, 42), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2103, 9, 9, 15, 54, 10), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2103, 9, 16, 8, 30, 32), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2103, 9, 23, 4, 19, 11), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2103, 10, 1, 8, 10, 29), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2103, 10, 9, 2, 20, 50), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2103, 10, 15, 16, 59, 30), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2103, 10, 22, 20, 26, 36), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2103, 10, 31, 0, 36, 55), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2103, 11, 7, 11, 11, 16), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2103, 11, 14, 3, 28, 1), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2103, 11, 21, 16, 0, 17), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2103, 11, 29, 16, 30, 56), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2103, 12, 6, 19, 0, 54), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2103, 12, 13, 16, 34, 31), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2103, 12, 21, 13, 30, 25), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2103, 12, 29, 7, 12, 20), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2104, 1, 5, 2, 46, 45), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2104, 1, 12, 8, 17, 17), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2104, 1, 20, 10, 58, 41), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2104, 1, 27, 20, 7, 5), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2104, 2, 3, 11, 34, 1), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2104, 2, 11, 1, 49, 6), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2104, 2, 19, 6, 27, 9), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2104, 2, 26, 7, 4, 6), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2104, 3, 3, 22, 12, 45), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2104, 3, 11, 19, 57, 5), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2104, 3, 19, 22, 25, 9), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2104, 3, 26, 16, 23, 21), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2104, 4, 2, 11, 0, 4), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2104, 4, 10, 13, 30, 2), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2104, 4, 18, 10, 13, 53), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2104, 4, 25, 0, 48, 13), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2104, 5, 2, 1, 41, 5), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2104, 5, 10, 5, 32, 18), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2104, 5, 17, 18, 15, 0), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2104, 5, 24, 9, 10, 41), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2104, 5, 31, 17, 45, 27), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2104, 6, 8, 19, 27, 30), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2104, 6, 15, 23, 37, 17), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2104, 6, 22, 18, 19, 17), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2104, 6, 30, 10, 41, 48), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2104, 7, 8, 7, 3, 48), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2104, 7, 15, 3, 53, 55), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2104, 7, 22, 4, 53, 54), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2104, 7, 30, 3, 57, 44), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2104, 8, 6, 16, 39, 55), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2104, 8, 13, 8, 43, 14), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2104, 8, 20, 17, 25, 26), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2104, 8, 28, 20, 51, 51), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2104, 9, 5, 1, 3, 3), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2104, 9, 11, 15, 34, 38), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2104, 9, 19, 8, 12, 58), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2104, 9, 27, 12, 33, 31), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2104, 10, 4, 9, 16, 3), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2104, 10, 11, 1, 36, 49), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2104, 10, 19, 1, 11, 46), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2104, 10, 27, 2, 18, 20), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2104, 11, 2, 18, 18, 33), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2104, 11, 9, 15, 27, 51), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2104, 11, 17, 19, 35, 44), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2104, 11, 25, 13, 48, 18), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2104, 12, 2, 4, 49, 7), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2104, 12, 9, 9, 6, 58), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2104, 12, 17, 13, 56, 2), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2104, 12, 24, 23, 20, 3), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2104, 12, 31, 16, 58, 3), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2105, 1, 8, 5, 45, 42), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2105, 1, 16, 6, 35, 55), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2105, 1, 23, 7, 36, 45), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2105, 1, 30, 6, 36, 43), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2105, 2, 7, 3, 41, 51), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2105, 2, 14, 20, 39, 6), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2105, 2, 21, 15, 31, 29), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2105, 2, 28, 21, 33, 29), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2105, 3, 9, 0, 36, 29), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2105, 3, 16, 8, 5, 32), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2105, 3, 22, 23, 52, 15), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2105, 3, 30, 13, 35, 9), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2105, 4, 7, 18, 25, 51), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2105, 4, 14, 17, 30, 3), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2105, 4, 21, 9, 16, 57), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2105, 4, 29, 6, 12, 20), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2105, 5, 7, 8, 11, 16), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2105, 5, 14, 1, 36, 31), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2105, 5, 20, 20, 17, 11), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2105, 5, 28, 22, 31, 34), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2105, 6, 5, 18, 5, 33), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2105, 6, 12, 9, 7, 32), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2105, 6, 19, 9, 19, 59), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2105, 6, 27, 13, 33, 5), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2105, 7, 5, 1, 6, 20), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2105, 7, 11, 16, 48, 7), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2105, 7, 19, 0, 39, 12), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2105, 7, 27, 2, 42, 17), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2105, 8, 3, 6, 29, 0), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2105, 8, 10, 1, 32, 11), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2105, 8, 17, 17, 59, 32), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2105, 8, 25, 14, 6, 18), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2105, 9, 1, 11, 32, 8), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2105, 9, 8, 12, 21, 10), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2105, 9, 16, 12, 28, 2), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2105, 9, 24, 0, 24, 43), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2105, 9, 30, 17, 32, 42), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2105, 10, 8, 2, 7, 36), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2105, 10, 16, 6, 47, 21), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2105, 10, 23, 10, 25, 5), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2105, 10, 30, 1, 43, 54), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2105, 11, 6, 19, 3, 47), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2105, 11, 14, 23, 44, 30), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2105, 11, 21, 20, 39, 54), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2105, 11, 28, 13, 8, 38), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2105, 12, 6, 14, 14, 3), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2105, 12, 14, 14, 30, 51), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2105, 12, 21, 7, 19, 7), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2105, 12, 28, 4, 22, 22), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2106, 1, 5, 9, 46, 8), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2106, 1, 13, 2, 44, 40), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2106, 1, 19, 18, 22, 40), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2106, 1, 26, 23, 6, 58), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2106, 2, 4, 3, 47, 48), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2106, 2, 11, 12, 28, 38), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2106, 2, 18, 5, 59, 52), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2106, 2, 25, 19, 55, 18), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2106, 3, 5, 19, 15, 50), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2106, 3, 12, 20, 11, 0), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2106, 3, 19, 18, 33, 7), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2106, 3, 27, 16, 37, 13), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2106, 4, 4, 7, 57, 57), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2106, 4, 11, 2, 44, 46), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2106, 4, 18, 8, 20, 5), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2106, 4, 26, 11, 18, 16), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2106, 5, 3, 18, 10, 25), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2106, 5, 10, 9, 19, 47), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2106, 5, 17, 23, 12, 47), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2106, 5, 26, 2, 58, 35), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2106, 6, 2, 2, 23, 48), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2106, 6, 8, 17, 10, 1), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2106, 6, 16, 14, 36, 3), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2106, 6, 24, 15, 29, 10), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2106, 7, 1, 9, 22, 24), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2106, 7, 8, 3, 19, 40), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2106, 7, 16, 5, 47, 49), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2106, 7, 24, 1, 10, 54), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2106, 7, 30, 16, 6, 2), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2106, 8, 6, 16, 28, 23), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2106, 8, 14, 20, 20, 48), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2106, 8, 22, 8, 41, 18), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2106, 8, 28, 23, 46, 36), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2106, 9, 5, 8, 38, 59), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2106, 9, 13, 10, 7, 23), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2106, 9, 20, 14, 52, 41), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2106, 9, 27, 9, 36, 19), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2106, 10, 5, 3, 14, 58), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2106, 10, 12, 23, 9, 31), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2106, 10, 19, 20, 53, 29), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2106, 10, 26, 22, 27, 17), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2106, 11, 3, 23, 9, 46), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2106, 11, 11, 11, 27, 34), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2106, 11, 18, 4, 3, 54), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2106, 11, 25, 14, 27, 53), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2106, 12, 3, 18, 58, 44), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2106, 12, 10, 22, 59, 18), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2106, 12, 17, 13, 40, 24), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2106, 12, 25, 8, 52, 20), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2107, 1, 2, 13, 9, 24), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2107, 1, 9, 9, 48, 8), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2107, 1, 16, 2, 29, 55), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2107, 1, 24, 4, 16, 38), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2107, 2, 1, 4, 20, 17), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2107, 2, 7, 20, 9, 45), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2107, 2, 14, 18, 26, 41), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2107, 2, 22, 23, 7, 51), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2107, 3, 2, 15, 50, 37), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2107, 3, 9, 6, 29, 40), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2107, 3, 16, 12, 33, 25), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2107, 3, 24, 16, 5, 57), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2107, 3, 31, 23, 58, 13), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2107, 4, 7, 17, 14, 13), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2107, 4, 15, 7, 29, 53), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2107, 4, 23, 6, 17, 45), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2107, 4, 30, 5, 49, 20), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2107, 5, 7, 4, 42, 51), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2107, 5, 15, 2, 2, 22), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2107, 5, 22, 17, 28, 59), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2107, 5, 29, 10, 53, 6), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2107, 6, 5, 17, 7, 41), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2107, 6, 13, 19, 13, 20), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2107, 6, 21, 2, 7, 45), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2107, 6, 27, 16, 38, 46), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2107, 7, 5, 6, 37, 19), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2107, 7, 13, 10, 20, 10), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2107, 7, 20, 9, 14, 13), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2107, 7, 27, 0, 22, 4), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2107, 8, 3, 21, 16, 28), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2107, 8, 11, 22, 58, 35), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2107, 8, 18, 16, 2, 53), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2107, 8, 25, 10, 58, 28), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2107, 9, 2, 12, 56, 36), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2107, 9, 10, 9, 10, 47), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2107, 9, 16, 23, 45, 12), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2107, 9, 24, 1, 0, 16), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2107, 10, 2, 5, 5, 25), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2107, 10, 9, 17, 28, 47), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2107, 10, 16, 9, 16, 15), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2107, 10, 23, 18, 31, 37), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2107, 10, 31, 20, 50, 13), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2107, 11, 8, 0, 48, 20), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2107, 11, 14, 21, 7, 30), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2107, 11, 22, 14, 55, 26), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2107, 11, 30, 11, 21, 27), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2107, 12, 7, 8, 16, 11), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2107, 12, 14, 11, 27, 49), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2107, 12, 22, 12, 38, 28), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2107, 12, 30, 0, 17, 57), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2108, 1, 5, 16, 53, 33), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2108, 1, 13, 4, 6, 45), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2108, 1, 21, 9, 22, 41), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2108, 1, 28, 11, 49, 8), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2108, 2, 4, 3, 20, 50), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2108, 2, 11, 22, 28, 23), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2108, 2, 20, 2, 59, 42), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2108, 2, 26, 22, 16, 31), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2108, 3, 4, 15, 52, 32), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2108, 3, 12, 17, 20, 14), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2108, 3, 20, 16, 27, 19), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2108, 3, 27, 7, 58, 18), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2108, 4, 3, 6, 24, 1), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2108, 4, 11, 11, 3, 47), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2108, 4, 19, 1, 58, 36), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2108, 4, 25, 17, 11, 9), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2108, 5, 2, 22, 38, 2), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2108, 5, 11, 2, 16, 59), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2108, 5, 18, 8, 34, 6), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2108, 5, 25, 2, 22, 10), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2108, 6, 1, 16, 1, 45), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2108, 6, 9, 14, 32, 52), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2108, 6, 16, 13, 32, 50), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2108, 6, 23, 12, 15, 35), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2108, 7, 1, 9, 42, 32), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2108, 7, 9, 0, 21, 9), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2108, 7, 15, 18, 16, 33), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2108, 7, 22, 23, 44, 54), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2108, 7, 31, 2, 37, 57), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2108, 8, 7, 8, 42, 32), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2108, 8, 14, 0, 4, 59), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2108, 8, 21, 13, 31, 20), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2108, 8, 29, 17, 58, 2), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2108, 9, 5, 16, 40, 48), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2108, 9, 12, 8, 14, 38), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2108, 9, 20, 5, 37, 12), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2108, 9, 28, 7, 21, 1), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2108, 10, 5, 1, 5, 58), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2108, 10, 11, 19, 53, 13), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2108, 10, 19, 23, 12, 42), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2108, 10, 27, 18, 50, 49), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2108, 11, 3, 10, 31, 22), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2108, 11, 10, 11, 41, 40), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2108, 11, 18, 16, 55, 15), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2108, 11, 26, 4, 45, 27), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2108, 12, 2, 21, 21, 44), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2108, 12, 10, 7, 23, 19), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2108, 12, 18, 9, 31, 36), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2108, 12, 25, 13, 30, 17), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2109, 1, 1, 10, 1, 23), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2109, 1, 9, 5, 21, 54), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2109, 1, 17, 0, 23, 37), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2109, 1, 23, 21, 39, 25), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2109, 1, 31, 0, 47, 42), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2109, 2, 8, 3, 7, 48), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2109, 2, 15, 13, 20, 27), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2109, 2, 22, 5, 57, 40), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2109, 3, 1, 17, 28, 7), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2109, 3, 9, 22, 26, 19), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2109, 3, 17, 0, 21, 24), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2109, 3, 23, 15, 15, 23), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2109, 3, 31, 11, 4, 37), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2109, 4, 8, 14, 5, 7), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2109, 4, 15, 9, 34, 42), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2109, 4, 22, 2, 16, 56), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2109, 4, 30, 4, 13, 28), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2109, 5, 8, 1, 52, 33), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2109, 5, 14, 17, 28, 8), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2109, 5, 21, 15, 27, 19), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2109, 5, 29, 19, 48, 1), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2109, 6, 6, 10, 16, 43), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2109, 6, 13, 0, 54, 8), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2109, 6, 20, 6, 42, 28), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2109, 6, 28, 9, 24, 0), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2109, 7, 5, 16, 11, 7), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2109, 7, 12, 9, 2, 16), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2109, 7, 19, 23, 30, 13), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2109, 7, 27, 21, 12, 55), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2109, 8, 3, 20, 48, 37), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2109, 8, 10, 19, 2, 6), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2109, 8, 18, 17, 3, 44), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2109, 8, 26, 7, 42, 30), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2109, 9, 2, 1, 36, 53), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2109, 9, 9, 7, 41, 54), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2109, 9, 17, 10, 35, 49), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2109, 9, 24, 17, 23, 1), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2109, 10, 1, 8, 10, 30), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2109, 10, 8, 23, 10, 9), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2109, 10, 17, 3, 22, 10), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2109, 10, 24, 2, 44, 21), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2109, 10, 30, 17, 55, 34), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2109, 11, 7, 16, 51, 0), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2109, 11, 15, 18, 37, 8), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2109, 11, 22, 12, 18, 40), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2109, 11, 29, 7, 44, 36), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2109, 12, 7, 11, 37, 28), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2109, 12, 15, 7, 38, 28), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2109, 12, 21, 22, 40, 6), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2109, 12, 29, 1, 29, 30), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2110, 1, 6, 6, 10, 30), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2110, 1, 13, 18, 7, 6), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2110, 1, 20, 10, 17, 29), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2110, 1, 27, 21, 55, 40), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2110, 2, 4, 23, 12, 58), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2110, 2, 12, 2, 24, 39), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2110, 2, 18, 23, 22, 35), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2110, 2, 26, 19, 9, 52), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2110, 3, 6, 13, 44, 16), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2110, 3, 13, 9, 29, 32), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2110, 3, 20, 13, 43, 47), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2110, 3, 28, 15, 19, 25), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2110, 4, 5, 1, 19, 31), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2110, 4, 11, 16, 34, 56), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2110, 4, 19, 4, 53, 14), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2110, 4, 27, 8, 56, 14), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2110, 5, 4, 10, 18, 31), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2110, 5, 11, 0, 46, 14), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2110, 5, 18, 20, 21, 39), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2110, 5, 26, 23, 8, 13), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2110, 6, 2, 17, 35, 51), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2110, 6, 9, 10, 48, 54), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2110, 6, 17, 11, 45, 2), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2110, 6, 25, 9, 46, 22), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2110, 7, 2, 0, 21, 44), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2110, 7, 8, 23, 8, 14), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2110, 7, 17, 2, 40, 36), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2110, 7, 24, 17, 24, 28), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2110, 7, 31, 7, 45, 26), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2110, 8, 7, 13, 54, 1), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2110, 8, 15, 16, 43, 20), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2110, 8, 22, 23, 9, 9), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2110, 8, 29, 16, 45, 2), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2110, 9, 6, 7, 0, 52), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2110, 9, 14, 5, 33, 7), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2110, 9, 21, 4, 25, 17), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2110, 9, 28, 4, 3, 4), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2110, 10, 6, 1, 57, 37), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2110, 10, 13, 17, 8, 24), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2110, 10, 20, 10, 41, 12), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2110, 10, 27, 18, 5, 16), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2110, 11, 4, 21, 34, 22), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2110, 11, 12, 3, 52, 0), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2110, 11, 18, 19, 13, 2), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2110, 11, 26, 10, 55, 0), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2110, 12, 4, 16, 9, 47), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2110, 12, 11, 14, 20, 4), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2110, 12, 18, 6, 49, 3), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2110, 12, 26, 5, 57, 24), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2111, 1, 3, 8, 9, 29), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2111, 1, 10, 1, 1, 17), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2111, 1, 16, 21, 39, 52), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2111, 1, 25, 1, 45, 0), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2111, 2, 1, 20, 47, 30), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2111, 2, 8, 12, 4, 7), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2111, 2, 15, 15, 19, 6), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2111, 2, 23, 20, 17, 4), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2111, 3, 3, 6, 16, 15), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2111, 3, 9, 23, 24, 9), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2111, 3, 17, 10, 47, 32), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2111, 3, 25, 11, 57, 4), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2111, 4, 1, 13, 27, 46), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2111, 4, 8, 11, 3, 53), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2111, 4, 16, 6, 38, 1), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2111, 4, 24, 0, 16, 37), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2111, 4, 30, 19, 29, 40), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2111, 5, 7, 23, 23, 3), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2111, 5, 16, 1, 12, 30), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2111, 5, 23, 9, 49, 6), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2111, 5, 30, 1, 30, 44), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2111, 6, 6, 12, 47, 58), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2111, 6, 14, 17, 14, 41), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2111, 6, 21, 17, 36, 10), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2111, 6, 28, 8, 36, 59), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2111, 7, 6, 3, 28, 26), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2111, 7, 14, 6, 15, 25), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2111, 7, 21, 0, 40, 40), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2111, 7, 27, 17, 51, 35), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2111, 8, 4, 19, 1, 57), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2111, 8, 12, 16, 31, 42), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2111, 8, 19, 7, 56, 32), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2111, 8, 26, 6, 10, 13), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2111, 9, 3, 10, 40, 14), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2111, 9, 11, 0, 48, 11), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2111, 9, 17, 16, 11, 30), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2111, 9, 24, 22, 4, 39), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2111, 10, 3, 1, 36, 25), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2111, 10, 10, 7, 59, 28), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2111, 10, 17, 2, 13, 51), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2111, 10, 24, 17, 16, 27), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2111, 11, 1, 15, 29, 35), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2111, 11, 8, 15, 1, 26), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2111, 11, 15, 14, 51, 32), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2111, 11, 23, 14, 21, 18), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2111, 12, 1, 4, 25, 7), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2111, 12, 7, 22, 49, 21), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2111, 12, 15, 6, 33, 23), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2111, 12, 23, 11, 11, 43), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2111, 12, 30, 16, 33, 57), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2112, 1, 6, 8, 16, 14), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2112, 1, 14, 0, 56, 19), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2112, 1, 22, 5, 50, 11), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2112, 1, 29, 3, 54, 59), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2112, 2, 4, 20, 5, 2), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2112, 2, 12, 20, 29, 54), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2112, 2, 20, 21, 6, 33), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2112, 2, 27, 14, 20, 32), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2112, 3, 5, 10, 33, 53), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2112, 3, 13, 15, 12, 42), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2112, 3, 21, 8, 41, 42), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2112, 3, 27, 23, 57, 35), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2112, 4, 4, 3, 21, 41), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2112, 4, 12, 7, 35, 14), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2112, 4, 19, 16, 57, 16), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2112, 4, 26, 9, 20, 19), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2112, 5, 3, 21, 27, 31), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2112, 5, 11, 21, 8, 43), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2112, 5, 18, 22, 46, 29), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2112, 5, 25, 19, 21, 15), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2112, 6, 2, 15, 33, 13), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2112, 6, 10, 8, 9, 39), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2112, 6, 17, 3, 25, 53), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2112, 6, 24, 6, 49, 10), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2112, 7, 2, 8, 35, 2), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2112, 7, 9, 17, 14, 30), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2112, 7, 16, 8, 25, 41), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2112, 7, 23, 20, 8, 22), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2112, 7, 31, 23, 58, 44), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2112, 8, 8, 1, 6, 40), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2112, 8, 14, 15, 18, 40), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2112, 8, 22, 11, 10, 50), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2112, 8, 30, 13, 32, 29), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2112, 9, 6, 8, 34, 59), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2112, 9, 13, 1, 25, 27), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2112, 9, 21, 3, 24, 28), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2112, 9, 29, 1, 14, 18), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2112, 10, 5, 16, 35, 8), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2112, 10, 12, 15, 33, 54), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2112, 10, 20, 20, 8, 17), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2112, 10, 28, 11, 9, 8), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2112, 11, 4, 2, 5, 38), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2112, 11, 11, 9, 37, 29), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2112, 11, 19, 12, 40, 15), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2112, 11, 26, 19, 37, 23), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2112, 12, 3, 13, 54, 51), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2112, 12, 11, 6, 28, 36), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2112, 12, 19, 4, 16, 18), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2112, 12, 26, 3, 23, 9), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2113, 1, 2, 4, 20, 57), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2113, 1, 10, 4, 16, 52), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2113, 1, 17, 18, 14, 1), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2113, 1, 24, 11, 28, 55), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2113, 1, 31, 20, 57, 59), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2113, 2, 9, 0, 59, 37), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2113, 2, 16, 6, 8, 6), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2113, 2, 22, 20, 55, 34), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2113, 3, 2, 14, 44, 23), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2113, 3, 10, 18, 49, 59), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2113, 3, 17, 16, 4, 38), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2113, 3, 24, 8, 20, 1), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2113, 4, 1, 8, 29, 23), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2113, 4, 9, 8, 42, 38), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2113, 4, 16, 0, 39, 34), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2113, 4, 22, 21, 46, 4), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2113, 5, 1, 1, 12, 51), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2113, 5, 8, 18, 30, 37), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2113, 5, 15, 8, 44, 59), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2113, 5, 22, 12, 53, 52), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2113, 5, 30, 16, 10, 51), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2113, 6, 7, 1, 1, 54), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2113, 6, 13, 17, 14, 21), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2113, 6, 21, 5, 16, 5), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2113, 6, 29, 4, 58, 48), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2113, 7, 6, 5, 39, 44), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2113, 7, 13, 2, 53, 52), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2113, 7, 20, 22, 24, 36), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2113, 7, 28, 15, 38, 32), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2113, 8, 4, 10, 1, 35), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2113, 8, 11, 14, 20, 21), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2113, 8, 19, 15, 44, 6), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2113, 8, 27, 0, 42, 0), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2113, 9, 2, 15, 42, 55), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2113, 9, 10, 4, 0, 36), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2113, 9, 18, 8, 25, 38), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2113, 9, 25, 9, 4, 26), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2113, 10, 2, 0, 4, 24), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2113, 10, 9, 20, 4, 3), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2113, 10, 17, 23, 34, 36), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2113, 10, 24, 17, 47, 37), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2113, 10, 31, 12, 0, 18), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2113, 11, 8, 14, 5, 49), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2113, 11, 16, 12, 32, 14), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2113, 11, 23, 3, 40, 6), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2113, 11, 30, 3, 49, 8), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2113, 12, 8, 8, 53, 12), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2113, 12, 15, 23, 13, 27), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2113, 12, 22, 15, 3, 25), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2113, 12, 29, 23, 5, 55), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2114, 1, 7, 2, 43, 1), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2114, 1, 14, 8, 7, 20), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2114, 1, 21, 3, 54, 27), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2114, 1, 28, 20, 34, 30), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2114, 2, 5, 18, 11, 14), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2114, 2, 12, 16, 3, 30), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2114, 2, 19, 18, 1, 44), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2114, 2, 27, 18, 10, 55), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2114, 3, 7, 6, 50, 29), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2114, 3, 13, 23, 54, 41), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2114, 3, 21, 9, 16, 33), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2114, 3, 29, 13, 38, 57), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2114, 4, 5, 17, 3, 39), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2114, 4, 12, 8, 26, 6), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2114, 4, 20, 1, 24, 6), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2114, 4, 28, 5, 25, 58), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2114, 5, 5, 1, 35, 0), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2114, 5, 11, 18, 15, 21), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2114, 5, 19, 17, 47, 25), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2114, 5, 27, 17, 12, 0), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2114, 6, 3, 9, 10, 23), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2114, 6, 10, 5, 56, 4), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2114, 6, 18, 9, 29, 53), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2114, 6, 26, 1, 35, 34), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2114, 7, 2, 16, 34, 20), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2114, 7, 9, 19, 54, 22), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2114, 7, 17, 23, 41, 21), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2114, 7, 25, 7, 44, 28), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2114, 8, 1, 0, 36, 26), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2114, 8, 8, 12, 14, 42), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2114, 8, 16, 12, 5, 36), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2114, 8, 23, 12, 55, 18), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2114, 8, 30, 10, 15, 22), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2114, 9, 7, 6, 24, 23), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2114, 9, 14, 23, 5, 27), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2114, 9, 21, 18, 24, 46), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2114, 9, 28, 22, 31, 6), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2114, 10, 7, 1, 13, 43), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2114, 10, 14, 9, 24, 58), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2114, 10, 21, 1, 27, 13), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2114, 10, 28, 14, 0, 17), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2114, 11, 5, 19, 19, 35), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2114, 11, 12, 19, 43, 41), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2114, 11, 19, 11, 10, 57), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2114, 11, 27, 8, 22, 8), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2114, 12, 5, 11, 34, 26), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2114, 12, 12, 6, 19, 41), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2114, 12, 19, 0, 26, 49), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2114, 12, 27, 4, 5, 35), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2115, 1, 4, 1, 19, 0), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2115, 1, 10, 17, 12, 56), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2115, 1, 17, 17, 25, 44), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2115, 1, 25, 23, 7, 44), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2115, 2, 2, 12, 21, 32), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2115, 2, 9, 4, 25, 11), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2115, 2, 16, 13, 15, 17), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2115, 2, 24, 15, 56, 25), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2115, 3, 3, 20, 57, 5), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2115, 3, 10, 16, 14, 10), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2115, 3, 18, 10, 3, 32), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2115, 3, 26, 5, 56, 41), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2115, 4, 2, 3, 48, 9), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2115, 4, 9, 5, 5, 32), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2115, 4, 17, 5, 45, 13), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2115, 4, 24, 17, 14, 58), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2115, 5, 1, 9, 59, 39), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2115, 5, 8, 19, 10, 9), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2115, 5, 16, 22, 54, 41), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2115, 5, 24, 2, 17, 37), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2115, 5, 30, 16, 47, 44), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2115, 6, 7, 10, 9, 57), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2115, 6, 15, 13, 1, 2), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2115, 6, 22, 9, 43, 31), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2115, 6, 29, 1, 26, 12), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2115, 7, 7, 1, 27, 0), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2115, 7, 15, 0, 11, 53), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2115, 7, 21, 16, 25, 30), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2115, 7, 28, 12, 51, 59), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2115, 8, 5, 16, 25, 52), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2115, 8, 13, 8, 55, 6), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2115, 8, 19, 23, 30, 13), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2115, 8, 27, 3, 30, 15), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2115, 9, 4, 6, 48, 3), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2115, 9, 11, 15, 52, 35), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2115, 9, 18, 8, 11, 1), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2115, 9, 25, 21, 4, 56), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2115, 10, 3, 20, 29, 4), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2115, 10, 10, 22, 1, 46), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2115, 10, 17, 19, 31, 58), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2115, 10, 25, 16, 41, 37), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2115, 11, 2, 9, 26, 56), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2115, 11, 9, 4, 35, 47), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2115, 11, 16, 10, 4, 27), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2115, 11, 24, 12, 59, 48), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2115, 12, 1, 21, 36, 3), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2115, 12, 8, 12, 53, 54), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2115, 12, 16, 3, 27, 59), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2115, 12, 24, 8, 24, 59), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2115, 12, 31, 8, 52, 50), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2116, 1, 7, 0, 0, 3), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2116, 1, 14, 22, 33, 15), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2116, 1, 23, 1, 23, 22), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2116, 1, 29, 19, 25, 10), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2116, 2, 5, 14, 16, 15), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2116, 2, 13, 17, 48, 21), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2116, 2, 21, 14, 48, 52), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2116, 2, 28, 5, 34, 51), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2116, 3, 6, 7, 10, 24), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2116, 3, 14, 11, 46, 0), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2116, 3, 22, 0, 30, 7), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2116, 3, 28, 15, 50, 40), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2116, 4, 5, 1, 33, 23), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2116, 4, 13, 3, 19, 43), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2116, 4, 20, 7, 13, 17), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2116, 4, 27, 2, 38, 51), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2116, 5, 4, 20, 10, 51), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2116, 5, 12, 15, 56, 17), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2116, 5, 19, 12, 20, 41), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2116, 5, 26, 14, 18, 21), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2116, 6, 3, 14, 1, 25), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2116, 6, 11, 1, 44, 6), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2116, 6, 17, 17, 25, 18), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2116, 6, 25, 3, 2, 49), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2116, 7, 3, 6, 17, 8), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2116, 7, 10, 9, 29, 39), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2116, 7, 16, 23, 52, 43), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2116, 7, 24, 17, 2, 29), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2116, 8, 1, 20, 22, 53), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2116, 8, 8, 16, 22, 25), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2116, 8, 15, 8, 51, 3), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2116, 8, 23, 8, 18, 34), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2116, 8, 31, 8, 3, 33), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2116, 9, 6, 23, 36, 43), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2116, 9, 13, 21, 6, 5), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2116, 9, 22, 0, 30, 55), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2116, 9, 29, 17, 32, 4), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2116, 10, 6, 8, 15, 53), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2116, 10, 13, 12, 57, 26), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2116, 10, 21, 16, 52, 2), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2116, 10, 29, 1, 29, 31), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2116, 11, 4, 19, 1, 22), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2116, 11, 12, 8, 9, 37), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2116, 11, 20, 8, 22, 2), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2116, 11, 27, 8, 55, 54), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2116, 12, 4, 8, 10, 18), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2116, 12, 12, 5, 36, 9), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2116, 12, 19, 22, 18, 49), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2116, 12, 26, 16, 55, 10), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2117, 1, 2, 23, 40, 7), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2117, 1, 11, 3, 14, 21), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2117, 1, 18, 10, 35, 27), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2117, 1, 25, 2, 18, 35), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2117, 2, 1, 17, 9, 13), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2117, 2, 9, 22, 40, 58), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2117, 2, 16, 21, 29, 44), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2117, 2, 23, 13, 33, 56), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2117, 3, 3, 11, 46, 16), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2117, 3, 11, 14, 16, 19), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2117, 3, 18, 7, 23, 52), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2117, 3, 25, 2, 47, 28), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2117, 4, 2, 6, 5, 37), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2117, 4, 10, 1, 39, 2), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2117, 4, 16, 16, 36, 35), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2117, 4, 23, 17, 52, 9), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2117, 5, 1, 22, 34, 7), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2117, 5, 9, 9, 31, 0), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2117, 5, 16, 1, 30, 56), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2117, 5, 23, 10, 28, 49), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2117, 5, 31, 12, 16, 0), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2117, 6, 7, 15, 5, 20), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2117, 6, 14, 10, 44, 49), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2117, 6, 22, 3, 59, 22), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2117, 6, 29, 23, 14, 25), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2117, 7, 6, 19, 43, 53), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2117, 7, 13, 21, 10, 58), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2117, 7, 21, 21, 26, 41), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2117, 7, 29, 8, 17, 25), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2117, 8, 5, 0, 48, 4), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2117, 8, 12, 9, 42, 0), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2117, 8, 20, 13, 51, 11), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2117, 8, 27, 16, 29, 13), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2117, 9, 3, 7, 36, 49), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2117, 9, 11, 0, 44, 21), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2117, 9, 19, 4, 32, 42), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2117, 9, 26, 0, 46, 47), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2117, 10, 2, 17, 23, 56), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2117, 10, 10, 17, 54, 18), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2117, 10, 18, 17, 18, 41), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2117, 10, 25, 9, 49, 15), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2117, 11, 1, 7, 6, 44), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2117, 11, 9, 11, 58, 22), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2117, 11, 17, 4, 16, 41), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2117, 11, 23, 20, 1, 30), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2117, 12, 1, 1, 0, 28), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2117, 12, 9, 5, 28, 50), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2117, 12, 16, 13, 44, 51), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2117, 12, 23, 7, 45, 3), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2117, 12, 30, 22, 7, 36), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2118, 1, 7, 21, 24, 59), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2118, 1, 14, 22, 10, 16), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2118, 1, 21, 21, 21, 27), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2118, 1, 29, 20, 17, 25), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2118, 2, 6, 11, 22, 43), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2118, 2, 13, 6, 12, 11), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2118, 2, 20, 12, 57, 2), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2118, 2, 28, 16, 59, 50), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2118, 3, 7, 23, 17, 57), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2118, 3, 14, 14, 40, 36), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2118, 3, 22, 5, 59, 44), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2118, 3, 30, 10, 30, 29), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2118, 4, 6, 9, 15, 29), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2118, 4, 13, 0, 27, 4), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2118, 4, 20, 23, 18, 36), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2118, 4, 29, 0, 11, 9), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2118, 5, 5, 17, 34, 32), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2118, 5, 12, 12, 11, 26), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2118, 5, 20, 15, 37, 25), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2118, 5, 28, 10, 13, 3), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2118, 6, 4, 0, 57, 28), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2118, 6, 11, 2, 9, 20), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2118, 6, 19, 6, 11, 31), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2118, 6, 26, 17, 17, 46), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2118, 7, 3, 8, 28, 11), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2118, 7, 10, 18, 6, 3), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2118, 7, 18, 18, 56, 17), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2118, 7, 25, 22, 28, 4), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2118, 8, 1, 17, 19, 42), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2118, 8, 9, 11, 22, 49), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2118, 8, 17, 6, 12, 7), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2118, 8, 24, 3, 3, 56), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2118, 8, 31, 4, 34, 39), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2118, 9, 8, 5, 11, 29), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2118, 9, 15, 16, 26, 58), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2118, 9, 22, 8, 37, 9), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2118, 9, 29, 18, 43, 49), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2118, 10, 7, 22, 44, 0), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2118, 10, 15, 2, 8, 14), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2118, 10, 21, 16, 40, 6), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2118, 10, 29, 11, 32, 34), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2118, 11, 6, 15, 11, 6), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2118, 11, 13, 11, 43, 51), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2118, 11, 20, 4, 24, 55), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2118, 11, 28, 6, 5, 5), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2118, 12, 6, 5, 41, 49), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2118, 12, 12, 21, 44, 39), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2118, 12, 19, 20, 15, 8), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2118, 12, 28, 1, 3, 10), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2119, 1, 4, 17, 38, 20), phase: "LastQuarter" },
    { time: createMomentFromDateTimeData(2119, 1, 11, 8, 41, 14), phase: "NewMoon" },
    { time: createMomentFromDateTimeData(2119, 1, 18, 15, 26, 54), phase: "FirstQuarter" },
    { time: createMomentFromDateTimeData(2119, 1, 26, 19, 4, 25), phase: "FullMoon" },
    { time: createMomentFromDateTimeData(2119, 2, 3, 2, 59, 2), phase: "LastQuarter" }
];

export default GetMoonPhaseByDay;